import React from "react";
import { Jumbotron } from "reactstrap";
import MainHeader from "../MainHeader";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { Col, Container, Row, Button } from "reactstrap";
import { ReactstrapInput } from "reactstrap-formik";
import withNotification from "./../withNotification";
import queryString from "query-string";
import DataService from "./../DataService";
import { withRouter } from "react-router-dom";
import { withTranslation, Trans } from "react-i18next";
import ReCaptcha from "../Auth/Recaptcha";
import i18n from "i18next";

Yup.setLocale({
  string: {
    matches: ({ path, type, value, originalValue }) => {
      return i18n.t(`resetPassword.${path}.matches`);
    },
    required: ({ path, type, value, originalValue }) => {
      return i18n.t(`resetPassword.${path}.required`);
    },
    min: params => i18n.t("string.min", params.min),
  },
});

const ResetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .matches(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,}$/)
    .required(),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref("password")])
    .required(),
});

class ResetPasswordForm extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = { isPending: true, success: false };
  }

  post = pwd => {
    const qs = queryString.parse(this.props.location.search);
    return new DataService().verifyResetPasswordToken(pwd, qs.token);
  };

  componentDidMount() {
    const qs = queryString.parse(this.props.location.search);
    new DataService()
      .resetPassword(qs.token)
      .then(r => {
        this.setState({ isPending: false });
        return r;
      })
      .then(this.processNotifications)
      .catch(this.onError);
  }

  submit = (values, callback) => {
    this.setState({ isPending: true });
    this.post(values)
      .then(result => {
        this.setState({
          isPending: false,
        });

        if (result.data && result.data.error) {
          if (!callback(result.data.error)) {
            this.onError(result.data.error);
          }
          return result;
        }
        this.setState({
          success: true,
        });
        setTimeout(() => {
          this.props.history.push("/prihlasit");
        }, 2000);
        return result;
      })
      .then(this.processNotifications)
      .catch(this.onError);
  };

  onError = error => {
    this.setState({
      error,
      isPending: false,
    });
    this.props.notify(error);
  };

  processNotifications = data => {
    if (data) {
      (data.notifications || []).forEach(notification => {
        this.props.notify(notification);
      });
    }
  };

  render() {
    const { isPending } = this.state;
    const { t } = this.props;
    return (
      <div>
        <Formik
          initialValues={{
            password: "",
            passwordConfirmation: "",
            recaptcha: "",
          }}
          validationSchema={ResetPasswordSchema}
          onSubmit={(values, actions) => {
            this.submit(values, error => {
              this.recaptcha.reset();
              if (error.form) {
                actions.setErrors(error.form);
                return true;
              }
              return false;
            });
          }}
          render={({ submitForm, isSubmitting, values, setFieldValue }) => (
            <Form>
              <Container style={{ paddingTop: "5px" }}>
                <Row>
                  <Col>
                    <Field
                      type="password"
                      label={t("password", "Heslo")}
                      name="password"
                      id="password"
                      component={ReactstrapInput}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Field
                      type="password"
                      label={t("passwordConfirmation", "Potvrzení hesla")}
                      name="passwordConfirmation"
                      id="passwordConfirmation"
                      component={ReactstrapInput}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col style={{ display: "flex", justifyContent: "center" }}>
                    <ReCaptcha
                      ref={ref => (this.recaptcha = ref)}
                      onVerificationCallback={key =>
                        setFieldValue("recaptcha", key)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col style={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      type="submit"
                      className="mt-1"
                      disabled={
                        isPending ||
                        values.recaptcha === "" ||
                        this.state.success
                      }
                    >
                      <Trans i18nKey="resetPassword.resetPassword">
                        Nastavit nové heslo
                      </Trans>
                    </Button>
                  </Col>
                </Row>
              </Container>
            </Form>
          )}
        />
      </div>
    );
  }
}

const ResetPassword = props => (
  <div>
    <MainHeader />
    <Jumbotron className="text-center">
      <h1 className="display-3">
        <Trans i18nKey="resetPassword.header">Obnova hesla</Trans>
      </h1>
      <div className="text-left">
        <ResetPasswordForm {...props} />
      </div>
    </Jumbotron>
  </div>
);

export default withNotification(withRouter(withTranslation()(ResetPassword)));
