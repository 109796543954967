import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ExerciseIcon = ({ type, size }) => {
  const icon = () => {
    switch (type) {
      case "user": // individual
        return "user";
      case "class":
        return "users";
      case "group":
        return "user-friends";
      default:
        return type;
    }
  };
  return <FontAwesomeIcon size={size} icon={icon()} />;
};

export default ExerciseIcon;
