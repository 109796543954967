import React, { useState } from "react";
import {useDropzone} from 'react-dropzone'
import { withTranslation } from "react-i18next";
import withNotification from "../withNotification";
import { FormGroup } from "reactstrap";
import readFileAsDataURL from "../helpers/SyncReader";

function Dropzone({
  t,
  field,
  form: { touched, errors, setFieldError, setFieldValue, ...fromTools },
  disabled = false,
  ...props
}) {
  const [files, setFiles] = useState([]);

  const {getRootProps, getInputProps, open} = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    accept: '.jpg,.png,.jpeg,.gif',
    multiple: true,
    getFilesFromEvent: event => myFileGetter(event)
  });

  const getFileType = (fileName) => {
    const regex = '[a-zA-Z]{3,4}$';
    const fileType = fileName.match(regex);
    return fileType[0];
  }

  /* need warning for try to add same file  */
  const fileIsAlreadyIn = (fileArray, file) => {
    for (let i = 0; i < fileArray.length; i += 1) {
      if (file.name === fileArray[i].name) {
        return true;
      }
    }

    return false;
  }

  const isrightFormat = (fileType) => {
    const accepted = ['jpg','png','jpeg','gif']; 
    
    return accepted.includes(fileType.toLowerCase());
  }

  const myFileGetter = async (event) => {
    const addfiles = [];
    const fileList = event.dataTransfer ? event.dataTransfer.files : event.target.files;
  
    files.forEach((file) => {
      addfiles.push(file);
    });
  
    

    for (let i = 0; i < fileList.length; i += 1) {
      const file = fileList.item(i);
      if (!fileIsAlreadyIn(addfiles, file)) {
        const fileType = getFileType(file.name);
  
        // check format, because dropzone lib push other formats to this method also
        if (isrightFormat(fileType)) {

          file.base64 = await readFileAsDataURL(file);
          addfiles.push(file);
        } else {
          console.log('chyba', field.name, t("helpdesk.dropzone.fileError"));
          // show error notification
          props.notify({
            type: "danger",
            text: t("helpdesk.dropzone.fileError")
          });
        }
      }
    }
  
    // set inter value
    setFiles(addfiles);
    // set formik value
    setFieldValue(field.name, addfiles);
    return addfiles;
  }

  const removeFile = (fileIndex) => {
    const newFilesArray = files;
    newFilesArray.splice(fileIndex, 1);

    // set inter value
    setFiles(newFilesArray);
    // set formik value
    setFieldValue(field.name, newFilesArray);
  }

  return (
    <FormGroup className="form-control d-flex justify-content-center mb-4 p-2" style={{height: 'unset'}}>
      <div {...getRootProps({className: 'dropzone d-flex flex-column justify-content-center text-center'})}>
        <input {...getInputProps()} />
        <p>{t("helpdesk.dropzone.instruction")}</p>
        <div><button type="button" onClick={open} className="btn btn-outline-danger btn-sm">{t("helpdesk.dropzone.button")}</button></div>
        <ul className="showPictures">
            {files.map((file, index) => (
              <li key={file.name}>
                <img src={file.base64} width="100" height="100" alt={file.name} style={{objectFit: "contain"}} />
                <button type="button" className="remove" onClick={() => removeFile(index)}>×</button>
              </li>
            ))}
          </ul>
      </div>
    </FormGroup>
  );
}

export default withTranslation()(withNotification(Dropzone));