import React, { Component } from "react";
import DataService from "./DataService";
import i18n from "i18next";

const ApplicationContext = React.createContext();

export class ApplicationStateProvider extends Component {
  constructor(props) {
    super();

    let user = null;
    try {
      user =
        (props && props.value && props.value.user) ||
        JSON.parse(sessionStorage.getItem("user"));
    } catch (error) {}

    if (user) {
      const now = Date.now();
      if (user.time && user.time - now < 0) {
        user = null;
      }
    }

    this.state = {
      authenticationError: null,
      login: this.login,
      user
    };

    i18n.on("languageChanged", lng => {
      if (this.state.user) {
        new DataService().setUserLanguage(lng);
      }
    });
  }

  login = async ({ userName, password, redirect }, notify) => {
    try {
      const data = await new DataService().login({
        username: userName,
        password
      });
      return this.onAuthSuccess(data, redirect, notify);
    } catch (error) {
      return this.onAuthFailure(error, notify);
    }
  };

  onAuthSuccess = (data, redirect, notify) => {
    i18n.changeLanguage(data.data.language);

    const user = data.data;
    user.time = Date.now() + user.expiration;

    this.setState({
      user,
      authenticationRequired: false,
      authenticationError: null
    });

    sessionStorage.setItem("user", JSON.stringify(data.data));

    if (redirect) {
      redirect();
      return;
    }
    data.notifications.forEach(notification => {
      notify(notification);
    });
  };

  onAuthFailure = (error, notify) => {
    if (!error.response) {
      error.response = {};
    }

    this.setState({
      authenticationError: {
        status: error.response.status,
        statusText: error.response.statusText,
        message: error.response.data
      },
      authenticated: false,
      authenticationRequired: true
    });

    if (error.response && error.response.status === 401) {
      notify({
        type: "danger",
        text: i18n.t("authenticationFailed"),
        component: Notification
      });
      return;
    }

    notify(error);
  };

  logout = async () => {
    await new DataService().logout();
    sessionStorage.removeItem("user");
    localStorage.removeItem("user");
    this.setState({
      authenticated: false,
      authenticationRequired: true,
      user: null
    });
  };
  /**
   * Change language in i18n (uncomment to unlock Polish language).
   * @param {String} lng - language code (cs, en, etc.)
   * */
  changeLanguage = lng => {
    switch (lng) {
      case "en":
        lng = "en-US"
        break;
      case "en-US":
        lng = "en-US"
        break;
      case "cs":
        lng = "cs";
        break;
      case "pl":
        lng = "pl";
        break;
      case "sk":
        lng = "sk";
        break;
      default:
        lng = "cs"
    }

    if (i18n.language === lng) {
      return;
    }
    i18n.changeLanguage(lng);
  };

  render() {
    const { children } = this.props;
    const { user, authenticationRequired, authenticationError } = this.state;

    return (
      <ApplicationContext.Provider
        value={{
          login: this.login,
          onAuthSuccess: this.onAuthSuccess,
          onAuthFailure: this.onAuthFailure,
          user,
          authenticated: user !== null,
          authenticationRequired,
          authenticationError,
          logout: this.logout,
          changeLanguage: this.changeLanguage
        }}
      >
        {children}
      </ApplicationContext.Provider>
    );
  }
}

export const ApplicationStateConsumer = ApplicationContext.Consumer;
