import React from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { Col, Container, Row, Button } from "reactstrap";
import { NavLink } from "react-router-dom";
import { withTranslation, Trans } from "react-i18next";
import GoogleSignin from "./GoogleSignin";
import AuthService from "./../AuthService";
import withNotification from "./../withNotification";
import withTour from "./../withTour";
import setYupLocale from "./../yup";
import { ReactstrapInput } from "reactstrap-formik";
import "./LoginForm.scss";
import env from "@beam-australia/react-env";

setYupLocale("login");

const LoginSchema = Yup.object().shape({
  userName: Yup.string().min(5).max(100).required(),
  password: Yup.string().max(100).required(),
});

class LoginForm extends React.Component {
  onSuccessGoogle = response => {
    new AuthService()
      .onGoogleAuthSuccess(response)
      .then(data => {
        this.props.onAuthSuccess(data, null, this.props.notify);
      })
      .catch(error => this.props.notify(error));
  };

  onSubmit = loginData => {
    this.props.login(
      {
        userName: loginData.userName,
        password: loginData.password,
        redirect: this.props.redirect,
      },
      this.props.notify
    );
  };

  render() {
    const { t } = this.props;
    return (
      <Formik
        initialValues={{
          userName: "",
          password: "",
        }}
        validationSchema={LoginSchema}
        onSubmit={(values, actions) => {
          this.onSubmit(values, error => {
            if (error.form) {
              actions.setErrors(error.form);
              return true;
            }
            return false;
          });
        }}
        render={form => {
          return (
            <Form className="LoginForm">
              <Container>
                <Row>
                  <Col>
                    <Field
                      label={t("login.userName.label")}
                      name="userName"
                      placeholder={t("login.userName.placeholder")}
                      id="userName"
                      component={ReactstrapInput}
                      data-cy="login-username"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Field
                      label={t("login.password.label")}
                      name="password"
                      type="password"
                      placeholder={t("login.password.placeholder")}
                      id="password"
                      component={ReactstrapInput}
                      data-cy="login-password"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button
                      color="primary"
                      block={true}
                      type="submit"
                      className="mb-2"
                      data-cy="login-submit"
                    >
                      <Trans i18nKey="login.signIn"></Trans>
                    </Button>
                  </Col>
                </Row>
                <Row className="text-center">
                  <Col>
                    <NavLink to="/zapomenute-heslo">
                      <Trans i18nKey="login.forgottenPassword"></Trans>
                    </NavLink>
                    &nbsp;<Trans i18nKey="login.notRegistered"></Trans>
                    &nbsp;
                    <NavLink to="/registrace">
                      <Trans i18nKey="login.register"></Trans>
                    </NavLink>
                  </Col>
                </Row>
                <Row className="text-center">
                  <Col>
                    <hr />
                    <GoogleSignin
                      clientId={env("GOOGLE_CONSUMER_KEY")}
                      scope="profile email"
                      buttonText={t("login.google")}
                      onSuccess={this.onSuccessGoogle}
                      onFailure={error => this.props.notify(error)}
                      cookiePolicy={"single_host_origin"}
                      className="google-login"
                    />
                  </Col>
                </Row>
              </Container>
            </Form>
          );
        }}
      />
    );
  }
}

export default withNotification(withTranslation()(withTour(LoginForm)));
