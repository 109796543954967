import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Trans } from "react-i18next";

const DeleteEmailDialog = ({ isOpen, emailAddress, toggle, onConfirmed }) => (
  <Modal isOpen={isOpen} toggle={toggle} backdrop>
    <ModalHeader toggle={toggle}>
      <Trans i18nKey="deleteEmailDialog.header" />
    </ModalHeader>
    <ModalBody>
      <Trans i18nKey="deleteEmailDialog.confirmation" />
      <br />
      <b>{emailAddress.emailAddress}</b>
      <br />
      <Trans i18nKey="deleteEmailDialog.note" />
    </ModalBody>
    <ModalFooter>
      <Button color="primary" onClick={e => onConfirmed(e, emailAddress)}>
        <Trans i18nKey="deleteEmailDialog.yes" />
      </Button>
      <Button color="secondary" onClick={toggle}>
        <Trans i18nKey="no" />
      </Button>
    </ModalFooter>
  </Modal>
);

export default DeleteEmailDialog;
