const theme = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: "hsla(6, 22%, 9%)",
    primary75: "hsla(2, 22%, 24%)",
    primary50: "hsla(2, 21%, 40%)",
    primary25: "hsla(2, 21%, 56%)",
    // primary: 'hsl(241, 34%, 49%)',
    // primary75: 'hsl(241, 34%, 60%)',
    // primary50: 'hsl(241, 34%, 71%)',
    // primary25: 'hsl(241, 34%, 82%)',
    neutral0: "hsl(0, 33%, 97%)", // #faf5f5
    neutral20: "hsl(0, 33%, 77%)", // #faf5f5

    // All possible overrides
    // primary: '#2684FF',
    // primary75: '#4C9AFF',
    // primary50: '#B2D4FF',
    // primary25: '#DEEBFF',

    // danger: '#DE350B',
    // dangerLight: '#FFBDAD',

    // neutral0: 'hsl(0, 0%, 100%)',
    // neutral5: 'hsl(0, 0%, 95%)',
    // neutral10: 'hsl(0, 0%, 90%)',
    // neutral20: 'hsl(0, 0%, 80%)',
    // neutral30: 'hsl(0, 0%, 70%)',
    // neutral40: 'hsl(0, 0%, 60%)',
    // neutral50: 'hsl(0, 0%, 50%)',
    // neutral60: 'hsl(0, 0%, 40%)',
    // neutral70: 'hsl(0, 0%, 30%)',
    // neutral80: 'hsl(0, 0%, 20%)',
    // neutral90: 'hsl(0, 0%, 10%)',
  },
  // Other options you can use
  borderRadius: 0,
  // baseUnit: 4,
  // controlHeight: 38
  // menuGutter: baseUnit * 2
});

export { theme };
