import React from "react";
import { Table, Badge, Button } from "reactstrap";
import Moment from "moment";
import "moment/min/locales";
import { extendMoment } from "moment-range";
import { If } from "./../../../If";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withTranslation } from "react-i18next";
import { Trans } from "react-i18next";

class Submissions extends React.Component {
  constructor(props) {
    super(props);

    this.dismissCallback = null;

    this.state = {
      modal: false,
      modalNote: false,
      submission: {},
    };
  }

  render() {
    const { enrolment } = this.props;
    if (enrolment === null || enrolment === undefined) {
      return <></>;
    }

    const { submissions } = enrolment;

    if (submissions.entries.length === 0) {
      return (
        <div>
          <h2>
            <Trans i18nKey="submissions.noSubmissions">
              Nikdo ještě nic neodevzdal.
            </Trans>
          </h2>
        </div>
      );
    }

    const moment = extendMoment(Moment);

    const dateFunc = s => {
      const start = moment(s);
      const end = moment();
      const r = moment.range(start, end);
      const days = r.diff("days");
      if (days <= 7) {
        return start.locale("cs").calendar();
      }
      return start.format("DD.MM.YYYY HH:mm");
    };

    const padStart = (s, count, char) => {
      return ("000" + s).slice(-count);
    };

    const toTimeString = ts => {
      let timeSpent = Math.floor(ts / 1000); // seconds
      const sd = 60 * 60 * 24;
      const sh = 60 * 60;
      const days = Math.floor(timeSpent / sd);
      timeSpent -= sd * days;
      const hours = Math.floor(timeSpent / sh);
      timeSpent -= sh * hours;
      const minutes = Math.floor(timeSpent / 60);
      let seconds = timeSpent - 60 * minutes;

      let r = `${padStart(hours, 2, "0")}:${padStart(
        minutes,
        2,
        "0"
      )}:${padStart(seconds, 2, "0")}`;
      if (days > 0) {
        return `${days}d ${r}`;
      }
      return r;
    };

    return (
      <Table striped borderless>
        <thead>
          <tr>
            <th colSpan={4}>
              <FontAwesomeIcon
                className="mr-1"
                icon={enrolment.isLocked ? "lock" : "lock-open"}
              />
              Cvičení {enrolment.name} - skupina {enrolment.group.name}
            </th>
          </tr>
          <tr>
            <th>#</th>
            <th>
              <Trans i18nKey="submissions.student">Student</Trans>
            </th>
            <th data-tut="viewUrl" />
            <th data-tut="lastUpdated">
              <Trans i18nKey="submissions.submittedOn">Odevzdáno</Trans>
            </th>
            <th data-tut="timeSpent">
              <Trans i18nKey="submissions.timeSpent">Strávený čas</Trans>
            </th>
          </tr>
        </thead>
        <tbody>
          {submissions.entries.map((submission, index) => {
            submission.isValid = submission.entryId > 0;
            return (
              <tr key={index}>
                <th scope="row">{index + 1}</th>
                <td>
                  {submission.emailAddresses.map((e, i) => {
                    return e.name ? (
                      <span key={`{index}_{i}`}>
                        {i > 0 ? ", " : ""}
                        {e.name} ({e.emailAddress})
                      </span>
                    ) : (
                      <span key={`{index}_{i}`}>
                        {i > 0 ? ", " : ""}
                        {e.emailAddress}
                      </span>
                    );
                  })}
                </td>
                <td>
                  <If condition={submission.isValid}>
                    <a
                      target="_blank"
                      href={submission.viewUrl}
                      rel="noopener noreferrer"
                    >
                      <Button outline color="primary" size="sm">
                        <FontAwesomeIcon icon="eye" />{" "}
                        <Trans i18nKey="groupSubmission.view" />
                      </Button>
                    </a>
                  </If>
                </td>

                <td className="text-truncate">
                  {(function () {
                    if (submission.isValid > 0) {
                      moment().locale("cs");
                      return dateFunc(submission.lastUpdated);
                    } else {
                      return (
                        <Badge color="warning">
                          <Trans i18nKey="submissions.notSubmitted" />
                        </Badge>
                      );
                    }
                  })()}
                </td>
                <td className="text-truncate">
                  {(function () {
                    if (submission.timeSpent) {
                      return toTimeString(submission.timeSpent);
                    } else {
                      return null;
                    }
                  })()}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  }
}

export default withTranslation()(Submissions);

/*
        <DeleteEmailDialog
          isOpen={this.state.modal}
          emailAddress={this.state.emailAddress}
          toggle={this.toggle}
          deleteSubmission={this.deleteSubmission}
        />*/
