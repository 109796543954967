import React, { useEffect, useState } from "react";
import { Form, Formik, FieldArray } from "formik";
import { Col, Container, Row } from "reactstrap";
import DataService from "../../DataService";

export default function NotificationForm({
  notifications,
  processNotifications,
  onError,
}) {
  const [notificationState, setNotificationState] = useState({
    notifications,
  });

  const [onState, setOn] = useState({
    on: [],
  });

  function handleChange(name, value) {
    new DataService()
      .setNotificationSetting(name, value)
      .then(processNotifications)
      .catch(onError);
  }

  useEffect(() => {
    setNotificationState(notifications);
    setOn({ on: Object.keys(notifications).filter(n => notifications[n].on) });
  }, [notifications]);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={onState}
      render={f => {
        const { values } = f;
        return (
          <Form>
            <Container style={{ paddingTop: "5px" }}>
              <FieldArray
                name="on"
                render={arrayHelpers => (
                  <div>
                    {Object.keys(notificationState).map(n => (
                      <Row
                        className="text-center"
                        key={notificationState[n].code}
                      >
                        <Col
                          style={{ display: "flex", justifyContent: "start" }}
                        >
                          <div class="form-control-lg custom-switch custom-control">
                            <input
                              type="checkbox"
                              id={n}
                              name="on"
                              class="custom-control-input"
                              checked={values.on.includes(n)}
                              onChange={e => {
                                if (e.target.checked) {
                                  arrayHelpers.push(n);
                                  handleChange(n, true);
                                } else {
                                  const idx = values.on.indexOf(n);
                                  arrayHelpers.remove(idx);
                                  handleChange(n, false);
                                }
                              }}
                            />
                            <label class="custom-control-label" htmlFor={n}>
                              <div>{notificationState[n].description}</div>
                            </label>
                          </div>
                        </Col>
                      </Row>
                    ))}
                  </div>
                )}
              />
            </Container>
          </Form>
        );
      }}
    />
  );
}
