import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { theme } from "../select/React-select-theme";
import "./SortOrderButton.scss";

// Component for sort by order.
// NOTE: Replace DropdownIndicator icon? https://react-select.com/components, https://fontawesome.com/icons?d=gallery&q=sort&m=free
const SortOrderButton = ({ options, defaultValue, placeholder, onChange }) => (
  <div className="SortOrderButton" data-cy="sort-order-bt">
    <Select
      // className="SortOrderButton"
      defaultValue={defaultValue}
      classNamePrefix="SortOrderButton"
      closeMenuOnSelect
      isClearable={false}
      isMulti={false}
      isSearchable={false}
      options={options}
      placeholder={placeholder}
      theme={theme}
      onChange={option => onChange(option)}
      // menuIsOpen
    />
  </div>
);

SortOrderButton.propTypes = {
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.object,
  placeholder: PropTypes.string,
};

SortOrderButton.defaultProps = {
  defaultValue: false,
  placeholder: "Řazení",
};

export default SortOrderButton;
