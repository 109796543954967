import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Column extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sort: null
    };
  }

  onClick = () => {
    let sort = this.state.sort;
    if (sort === "desc" || sort === "" || sort === null) {
      sort = "asc";
    } else if (sort === "asc") {
      sort = "desc";
    }
    this.setState({ sort }, () => {
      this.props.onSortChanged({
        field: this.props.field,
        sort: this.state.sort
      });
    });
  };

  componentWillReceiveProps = nextProps => {
    if (nextProps.sortedBy && nextProps.sortedBy.field !== this.props.field) {
      this.setState({ sort: null });
    }
  };

  render() {
    const { name, sortedBy, field } = this.props;
    let { sort } = this.state || sortedBy.sort;
    let icon = null;
    if (sortedBy.field === field) {
      if (sort === "asc") {
        icon = "sort-up";
      }
      if (sort === "desc") {
        icon = "sort-down";
      }
    }
    return (
      <th>
        <div style={{ cursor: "pointer" }} onClick={this.onClick}>
          {name} {icon && <FontAwesomeIcon icon={icon} />}
        </div>
      </th>
    );
  }
}

export default Column;
