import React, { useState } from "react";
import { withTranslation, Trans } from "react-i18next";
import { Button } from "reactstrap";

const Message = props => {
  const storageName = "agreedWithTerms";
  const [agreedWithTerms, setValue] = useState(
    localStorage.getItem(storageName) === "true" || false
  );

  const onDismiss = () => {
    localStorage.setItem(storageName, true);
    setValue(true);
  };

  const { t } = props;

  return (
    !agreedWithTerms && (
      <div className="cookie-consent">
        <div className="cookie-consent__text">
          <p>
            <Trans i18nKey="cookies.text" />{" "}
            <a
              target="_blank"
              href={t("cookies.url")}
              rel="noopener noreferrer"
            >
              <Trans i18nKey="cookies.urlText" />
            </a>
            .
          </p>
        </div>
        <Button color="link" onClick={onDismiss}>
          Ok
        </Button>
      </div>
    )
  );
};

export default withTranslation()(Message);
