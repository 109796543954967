import React from "react";
import { useEffect, useState } from "react";

export default function GoogleSignin({ clientId, onSuccess }) {
  const [gsiScriptLoaded, setGsiScriptLoaded] = useState(false);
  useEffect(() => {
    const initializeGsi = () => {
      if (!window.google || gsiScriptLoaded) {
        return;
      }

      setGsiScriptLoaded(true);

      window.google.accounts.id.initialize({
        client_id: clientId,
        callback: onSuccess,
      });

      window.google.accounts.id.renderButton(
        document.getElementById("signUpDiv"),
        {
          type: "standard",
          theme: "outline",
          size: "medium",
          text: "signin_with",
          shape: "pill",
        }
      );
    };

    const script = document.createElement("script");
    script.src = "https://accounts.google.com/gsi/client";
    script.onload = initializeGsi;
    script.async = true;
    script.id = "google-client-script";
    document.querySelector("body").appendChild(script);

    return () => {
      // Cleanup function that runs when component unmounts
      window.google.accounts.id.cancel();
      document.getElementById("google-client-script").remove();
    };
  }, [onSuccess, clientId, gsiScriptLoaded]);

  return (
    <div
      id="signUpDiv"
      style={{ display: "flex", justifyContent: "center" }}
    ></div>
  );
}
