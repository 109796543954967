import React from "react";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import {
  Col,
  Container,
  FormGroup,
  Label,
  Row,
  FormFeedback,
  Input,
  Button,
} from "reactstrap";
import { ReactstrapInput } from "reactstrap-formik";
import { withTranslation, Trans } from "react-i18next";
import ReCaptcha from "../Auth/Recaptcha";
import setYupLocale from "../yup";

setYupLocale("signUp");

const RegistrationSchema = Yup.object().shape({
  firstName: Yup.string().max(50),
  password: Yup.string()
    .matches(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,}$/)
    .max(50)
    .required(),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref("password")])
    .required(),
  institution: Yup.string().max(255),
  lastName: Yup.string().max(50),
  emailAddress: Yup.string().email().required(),
});

const ReactstrapSelectInput = ({
  field,
  form: { touched, errors },
  disabled = false,
  ...props
}) => {
  const error = errors[field.name];
  const touch = touched[field.name];
  return (
    <FormGroup>
      <Label for={props.inputprops.id} className="label-color">
        {props.label}
      </Label>
      <Input
        id={props.inputprops.id}
        {...field}
        {...props}
        type="select"
        invalid={Boolean(touched[field.name] && errors[field.name])}
        placeholder="Test"
      >
        {props.inputprops.options.map((option, index) => {
          if (option.name)
            return (
              <option value={option.id} key={index}>
                {option.name}
              </option>
            );
          return (
            <option value={option} key={index}>
              {option}
            </option>
          );
        })}
      </Input>
      {touch && error && <FormFeedback>{error}</FormFeedback>}
    </FormGroup>
  );
};

class RegistrationForm extends React.Component {
  render() {
    const { onSubmit, t } = this.props;
    return (
      <div>
        <Formik
          initialValues={{
            firstName: "",
            lastName: "",
            emailAddress: "",
            institution: "",
            password: "",
            passwordConfirmation: "",
            recaptcha: "",
            teacher: "",
          }}
          validationSchema={RegistrationSchema}
          onSubmit={(values, actions) => {
            onSubmit(values, error => {
              this.recaptcha.reset();
              actions.setFieldValue("recaptcha", "", false);
              if (error.form) {
                actions.setErrors(error.form);
                return true;
              }
              return false;
            });
          }}
          render={aa => {
            const { values, setFieldValue } = aa;
            return (
              <Form>
                <Container style={{ paddingTop: "5px" }}>
                  <Row>
                    <Col>
                      <Field
                        label={t("firstName", "Jméno")}
                        name="firstName"
                        id="firstName"
                        component={ReactstrapInput}
                      />
                    </Col>
                    <Col>
                      <Field
                        label={t("lastName", "Přijmení")}
                        name="lastName"
                        id="lastName"
                        component={ReactstrapInput}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Field
                        type="email"
                        label={t("emailAddress", "E-mailová adresa")}
                        name="emailAddress"
                        id="emailAddress"
                        component={ReactstrapInput}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Field
                        label={t("institution", "Instituce, kde působím")}
                        name="institution"
                        id="institution"
                        component={ReactstrapInput}
                      />
                    </Col>
                    <Col sm={3}>
                      <Field
                        name="teacher"
                        label={t("teacher", "Jsem učitel")}
                        id="teacher"
                        inputprops={{
                          name: "teacher",
                          label: "",
                          id: "teacher",
                          options: [t("no", "Ne"), t("yes", "Ano")],
                          defaultOption: t("no", "Ne"),
                        }}
                        component={ReactstrapSelectInput}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Field
                        type="password"
                        label={t("password", "Heslo")}
                        name="password"
                        id="password"
                        component={ReactstrapInput}
                      />
                    </Col>
                    <Col>
                      <Field
                        type="password"
                        label={t("passwordConfirmation", "Potvrzení hesla")}
                        name="passwordConfirmation"
                        id="passwordConfirmation"
                        component={ReactstrapInput}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col style={{ display: "flex", justifyContent: "center" }}>
                      <ReCaptcha
                        ref={ref => (this.recaptcha = ref)}
                        onVerificationCallback={key =>
                          setFieldValue("recaptcha", key)
                        }
                      />
                    </Col>
                  </Row>
                  <Row className="mt-2 text-center">
                    <Col style={{ display: "flex", justifyContent: "center" }}>
                      <Button
                        type="submit"
                        className="mt-1"
                        disabled={values.recaptcha === ""}
                      >
                        <Trans i18nKey="signUp.signUp">Zaregistrovat se</Trans>
                      </Button>
                    </Col>
                  </Row>
                  <Row className="mt-2 text-center">
                    <Col style={{ display: "flex", justifyContent: "center" }}>
                      <p>
                        <Trans i18nKey="signUp.terms.text" />
                        <a
                          target="_blank"
                          href={t("signUp.terms.url")}
                          rel="noopener noreferrer"
                        >
                          <Trans i18nKey="signUp.here" />
                        </a>
                        .
                      </p>
                    </Col>
                  </Row>
                </Container>
              </Form>
            );
          }}
        />
      </div>
    );
  }
}

export default withTranslation()(RegistrationForm);
