import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Trans } from "react-i18next";
import {
  Card,
  CardHeader,
  CardBody,
  CardSubtitle,
  CardTitle,
} from "reactstrap";
import classnames from "classnames";
import "./GroupTile.scss";

const GroupTile = ({
  group,
  location,
  className,
  toggleFavourite,
  dataTut,
}) => {
  const color = group.color || "";
  const count = group.exerciseCount;

  return (
    <Card
      className={classnames({
        "hl-card--class": true,
        className,
        "is-archived": group.isArchived,
        "is-empty": count === 0,
      })}
      style={{ backgroundColor: color }}
      data-tut={dataTut || false}
      data-cy={"group-card"}
    >
      <CardHeader className="hl-card__controls">
        {!group.isArchived && (
          <>
            <FontAwesomeIcon
              data-tut="GroupTile_isFavourite"
              icon={[group.isFavourite ? "fas" : "far", "star"]}
              onClick={() => {
                handleIsFavouriteEvent(group);
              }}
            />
            {group.projects && group.projects["d-plus"]&& <div>D+</div>}
          </>
        )}
        <Link
          className="hl-show-on-hover"
          to={{
            pathname: `/tridy/${group.id}/detail/`,
            state: {
              group,
              returnTo: location.pathname,
            },
          }}
        >
          <FontAwesomeIcon icon="cog" data-tut="GroupTile_detail" />
        </Link>
      </CardHeader>

      <CardBody>
        <Link
          to={{
            pathname: `/tridy/${group.id}`,
            state: {
              group,
              returnTo: location.pathname,
            },
          }}
        >
          <CardTitle tag="h5">{group.name}</CardTitle>
          <CardSubtitle className="hl-card__exerciseCount">
            {count > 0 && `${count} `}
            {count > 4 && (
              <Trans i18nKey="grouptile.exerciseCount_5">zadaných</Trans>
            )}
            {count > 1 && count <= 4 && (
              <Trans i18nKey="grouptile.exerciseCount_4">zadaná</Trans>
            )}
            {count === 1 && (
              <Trans i18nKey="grouptile.exerciseCount_1">zadané</Trans>
            )}
            {count === 0 && (
              <Trans i18nKey="grouptile.exerciseCount_0">žádná</Trans>
            )}{" "}
            <Trans i18nKey="grouptile.activity" />
          </CardSubtitle>
        </Link>
      </CardBody>
    </Card>
  );

  function handleIsFavouriteEvent(group) {
    toggleFavourite(group);
  }
};

export default GroupTile;
