import React from "react";
import i18n from "i18next";
import { withTranslation } from "react-i18next";
import DataService from "./../DataService";
import withNotification from "../withNotification";
import withTour from "../withTour";
import Layout from "../Layout";
import Time from "./Time";
import Exercises from "./Exercises";
import "./scss/Timeline.scss";

class Timeline extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      exerciseIsLoading: true,
      timelineIsLoading: true,
      loadingDoneTimelineSetup: false,
      loadingDoneTimelineExercises: false,
      pending: false,
      error: null,
      data: {
        exercises: [],
        warnings: []
      },
      timelineData: {
        timeline: [],
        times: []
      },
      currentDecadeExercises: [],
      currentEpoch: {
        active: false,
        name: ""
      },
      timeSetup: {
        height: 62,
        start: 1910,
        end: 2010,
        step: 10
      }
    };
  }

  refresh = lng => {
    if (!lng) {
      lng = i18n.language;
    }
    // Fetch individually cviceni from server with fragment for casova-osa attached
    new DataService().fetchCatalog("/katalog", lng).then(
      result => {
        let data = result.data || { exercises: [] };
        this.setState({
          error: null,
          data: data,
          exerciseIsLoading: false,
          language: lng,
          loadingDoneTimelineExercises: true
        });
      },
      error =>
        this.setState({
          error,
          exerciseIsLoading: false
        })
    );
    // Fetch data for setup left side of component Timeline - that means setup and epochs
    new DataService().fetchTimelineSetup(lng).then(
      result => {
        let data = result.data || { timeline: [], times: [] };
        this.setState({
          error: null,
          timelineData: data,
          timelineIsLoading: false,
          loadingDoneTimelineSetup: true
        });
      },
      error =>
        this.setState({
          error,
          timelineIsLoading: false
        })
    );
  };

  componentDidMount() {
    this.setState({
      loadingDoneTimelineSetup: false,
      loadingDoneTimelineExercises: false
    });
    this.refresh();
    this.setState({ mounted: true });
    const that = this;
    i18n.on("languageChanged", function (lng) {
      that.refresh(lng);
    });
  }

  loadingMessages() {
    const {
      timelineIsLoading,
      exerciseIsLoading,
      loadingDoneTimelineSetup,
      loadingDoneTimelineExercises
    } = this.state;
    const { t } = this.props;
    if (timelineIsLoading && exerciseIsLoading) {
      return <h3>{t("pleaseWait")}</h3>;
    } else {
      if (!(loadingDoneTimelineSetup && loadingDoneTimelineExercises)) {
        return <h3>{t("cantLoad")}</h3>;
      }
    }
  }

  render() {
    const {
      loadingDoneTimelineSetup,
      loadingDoneTimelineExercises
    } = this.state;

    const { t, history } = this.props;

    const getColorVars = () => {
      const styleObj = {};

      this.state.timelineData.timelineData.forEach(history => {
        styleObj[`--color-${history.refId}`] = history.color;
      });

      return styleObj;
    };

    return (
      <Layout
        title={t("timeline.header")}
        isTitleCentered={true}
        isFullWidth={true}
        // className="timeline"
      >
        <Layout.Content>
          {this.loadingMessages()}
          {loadingDoneTimelineSetup && loadingDoneTimelineExercises && (
            <div className="timeline" style={{ ...getColorVars() }}>
              <Time
                timelineDetailData={this.state.timelineData}
                timelineLanguage={i18n.language}
                timelineSetup={this.state.timeSetup}
                clickEpochEvent={this.epochClickEventTimeline}
                handleObserverDecade={this.handleObserverDecadeParent}
                clickDetailEpochEvent={this.clickDetailEpochEventTimeline}
              />
              <Exercises
                loadedExercises={this.state.data.exercises}
                currentDecadeExercises={this.state.currentDecadeExercises}
                currentEpoch={this.state.currentEpoch}
                timeSetup={this.state.timeSetup}
                timelineDetailData={this.state.timelineData}
                location={this.props.location}
                history={history}
              />
            </div>
          )}
        </Layout.Content>
      </Layout>
    );
  }

  /**
   * Event for show Detail of clicked epoch
   *
   * @param {string} epochName
   */
  epochClickEventTimeline = epochName => {
    const currentEpoch = {};
    currentEpoch.active = true;
    currentEpoch.name = epochName;
    this.setState({ currentEpoch });
  };
  /**
   * Event for hide Detail of clicked epoch
   */
  clickDetailEpochEventTimeline = () => {
    const currentEpoch = {};
    currentEpoch.active = false;
    currentEpoch.name = "";
    this.setState({ currentEpoch });
  };
  /**
   * Observer for current visible cviceni by curent visible decade
   *
   * @param {boolean} entering If the element is visible: entering=true, If the element is hidden: entering=false
   * @param {int} index index of detailExercise
   */
  handleObserverDecadeParent = (entering, index) => {
    let currentDecadeExercises = [...this.state.currentDecadeExercises];
    if (entering) {
      currentDecadeExercises.push(index);
      this.setState({ currentDecadeExercises });
    } else {
      for (let i = 0; i < currentDecadeExercises.length; i++) {
        if (currentDecadeExercises[i] === index) {
          currentDecadeExercises.splice(i, 1);
          this.setState({ currentDecadeExercises });
          return;
        }
      }
    }
  };
}

export default withTranslation()(withNotification(withTour(Timeline)));
