import React from "react";
import DataService from "../../DataService";
import { Modal, ModalBody } from "reactstrap";
import Logo from "../../icons/logo";
import { Trans, withTranslation } from "react-i18next";
import LoginForm from "../../Auth/LoginForm";
import ChooseGroupLoader from "./ChooseGroupLoader";
import { ApplicationStateConsumer } from "../../ApplicationStateProvider";
import withNotification from "../../withNotification";

//https://stackoverflow.com/questions/37949981/call-child-method-from-parent  - 2. reseni
/**
 * Component to render modals which check login status and show modal choose or create group if user is login it.
 *
 *
 * */
class EnrolmentModal extends React.Component {
  constructor() {
    super();
    this.state = {
      type: "",
      authModal: false,
      classModal: false,
      notification: null
    };
  }

  componentDidMount() {
    this.props.setButtonZadatClick(this.onSelected);
  }

  /**
   * Enroll logged in user to exercise.
   * @param {int} exerciseId - id of chosen exercise
   * @param {id} groupId -id of chosen group
   * @param {String} type - type of chosen enrolment (class, individual, group)
   * @param {boolean} showNotification - show notification
   *
   * */
  enrolUserToExercise = (
    exerciseId,
    groupId,
    type,
    showNotification = true
  ) => {
    return new DataService()
      .enrolUserToExercise(exerciseId, groupId, type)
      .then(response => {
        const { url, code } = response.data;
        return { success: true, code, url };
      })
      .catch(error => {
        this.props.notifyError(error);
        return { success: false, code: null };
      });
  };

  /**
   * Component which handle form submit event (click on button "Zadat tride...").
   * @param {String} type - type of selected button (individual, group, class)
   *
   * */
  onSelected = type => {
    const { authenticated } = this.props;
    this.setState({
      type,
      authModal: !authenticated,
      classModal: authenticated
    });
  };

  /**
   * Method which select if the group is selected (from list of created groups) or make new group.
   * @param {Object} group - information of group
   *
   * */
  onAssignActivity = group => {
    if (group.id < 0) {
      this.onGroupCreating(group);
    } else {
      this.onGroupSelected(group);
    }
  };

  /**
   * Enrol log in user to by select created group.
   * @param {Object} group - object of selected group
   *
   * */
  onGroupSelected = group => {
    const { type } = this.state;
    const { data } = this.props;
    const { exercise } = data;
    const { history } = this.props;

    this.enrolUserToExercise(exercise.exerciseId, group, type, false)
      .then(({ url, success }) => {
        this.setState({ pending: false });
        if (success) {
          history.push(url);
        }
      })
      .catch(error => {
        this.setState({
          error,
          pending: false
        });
      });
  };

  /**
   * Method which call method to create new group
   * @param {Object} group - object of selected group
   *
   * */
  onGroupCreating = group => {
    this.onGroupSelected({ id: -1, name: group.name });
  };

  /**
   * Toggle state for authorization
   * */
  toggleAuth = () => {
    this.setState(prevState => ({
      authModal: !prevState.authModal
    }));
  };

  /**
   * Toggle class for modal widnow
   * */
  toggleClass = () => {
    this.setState(prevState => ({
      classModal: !prevState.classModal
    }));
  };

  /**
   * Set and show new notification
   *
   * @param {Object} notification - object with notification parameters
   * */
  notify = notification => {
    this.setState({ notification });
  };

  /**
   * Method which is called after login status changed
   *
   * @param {Object} credentials - informations about user (which he filled in)
   * */
  login = credentials => {
    this.setState({ notification: null });
    this.props
      .login(credentials, this.props.notify)
      .then(_ => {
        this.onSelected(this.state.type);
      })
      .catch(error => {
        this.setState({
          error,
          pending: false //TODO neni pot5eba
        });
      });
  };

  /**
   * Method which is called after authorization is successful + notification is sent
   * @param {object} data
   *
   * */
  onAuthSuccess = data => {
    this.props.processNotifications(data);
    this.props.onAuthSuccess(data, null, this.notify);
    this.onSelected(this.state.type);
  };

  /**
   * Method which is called after authorization is unsuccessful
   * @param {object} e
   *
   * */
  onAuthFailure = e => {
    this.props.onAuthFailure(e);
  };

  render() {
    const { authenticated } = this.props;
    return (
      <>
        <Modal
          // TODO: create separate component for the login modal
          isOpen={this.state.authModal}
          toggle={this.toggleAuth}
          className="modal--login"
          backdrop={true}
          role="authenticate"
        >
          <Logo />
          <ModalBody>
            <p className="text-center">
              <Trans i18nKey="catalogExerciseDetail.signInFirst">
                Cvičení si můžete zadat až po přihlášení.
              </Trans>
            </p>
            <LoginForm
              login={this.login}
              notification={this.state.notification}
              onAuthSuccess={this.onAuthSuccess}
              onAuthFailure={this.onAuthFailure}
            />
          </ModalBody>
        </Modal>

        {authenticated && (
          <ChooseGroupLoader
            isOpen={this.state.classModal}
            toggle={this.toggleClass}
            onAssignActivity={this.onAssignActivity}
          />
        )}
      </>
    );
  }
}

/**
 * Wrapper to EnrolmentModal component to provide him App state parameters
 *
 * */
class Wrapper extends React.Component {
  render() {
    return (
      <ApplicationStateConsumer>
        {auth => <EnrolmentModal {...this.props} {...auth} />}
      </ApplicationStateConsumer>
    );
  }
}

export default withTranslation()(withNotification(Wrapper));
