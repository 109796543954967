import React, { useEffect, useRef } from "react";
import HLCard from "../../components/Card/HLCard";
import TimelineExerciseDetail from "../../timeline/TimelineExerciseDetail";
import d3 from "../../helpers/d3";
import "./VCGridVisual.scss";

const VCGridVisual = ({
  activities,
  onClickActivity,
  activityDetail,
  translation,
  parameters = null,
  history = null,
}) => {
  const { gridBoxSize, cvHeight, cvWidth } = parameters;
  const $vcWrapper = useRef(null);
  const detailIsOpen = useRef(false);
  const delta = useRef({
    dx: window.innerWidth / 2,
    dy: window.innerHeight / 2,
  });

  const getTranslation = (current, total, wSize) =>
    ((total / wSize) * current - total / 2) * -1;

  useEffect(() => {
    const headerHeight = document.querySelector("#header").offsetHeight;
    const wWidth = document.body.clientWidth;
    const wHeight = document.body.clientHeight - headerHeight;

    const movement = event => {
      if (!detailIsOpen.current) {
        const coords = d3.pointer(event);

        delta.current = {
          dx: coords[0],
          dy: coords[1],
        };

        d3.select($vcWrapper.current)
          .transition()
          .duration(750)
          .ease(d3.easeExpOut)
          .attr(
            "style",
            `transform: translate3D(${getTranslation(delta.current.dx, cvWidth, wWidth) - gridBoxSize // kvůli středu os - vlevo nahoře
            }px, ${getTranslation(delta.current.dy, cvHeight, wHeight) +
            (cvHeight / wHeight) * (headerHeight / 2) +
            gridBoxSize // kvůli středu os - vlevo nahoře
            }px, 0);`
          ); // Kurzorem nelze jít úplně k horní straně obrazovky => transformace o výšku headeru dolu (cvHeight / wHeight * headerHeight)
      }
    };

    d3.select($vcWrapper.current.parentNode).on("mousemove", event =>
      movement(event)
    );

    d3.selectAll(".vc-grid-item").on("mouseover", function (event) {
      d3.select(this).raise();
    });
  }, [cvHeight, cvWidth, gridBoxSize]);

  const gridItems = activities.map(
    (activity, index) =>
      activity !== null && (
        <div
          key={`vc-card-${index}`}
          className="vc-grid-item"
          style={{
            width: `${gridBoxSize}px`,
            height: `${gridBoxSize}px`,
            left: `${activity.pos.x}px`,
            top: `${activity.pos.y}px`,
          }}
        >
          <div
            className="vc-wrapper-hl-card"
            onClick={() => {
              detailIsOpen.current = true;
              onClickActivity(activity);
            }}
          >
            <HLCard
              image={activity.thumb_image_url}
              name={activity.name}
              color={`hsl(${activity.metadata.color.hsv.h}, ${activity.metadata.color.hsv.s
                }%, ${activity.metadata.color.hsv.v - 20}%)`}
              variant="visualCloud"
            />
          </div>
        </div>
      )
  );

  return (
    <div className="vc-grid-wrapper">
      <div ref={$vcWrapper} className="vc-grid-items">
        {gridItems}
      </div>
      <TimelineExerciseDetail
        visibility={activityDetail.visibility}
        exercise={activityDetail.data}
        translation={translation}
        handleActivityDetailEvent={() => {
          detailIsOpen.current = false;
          activityDetail.handleActivityDetailEvent();
        }}
        history={history}
        variant="gallery"
      />
    </div>
  );
};
VCGridVisual.defaultProps = {};

export default VCGridVisual;
