import React, { useState } from "react";
import Tour from "reactour";
import { Button } from "reactstrap";
import { Trans } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./withTour.scss";

export default function withTour(Component, stepsFunc = null) {
  return function Inner(props) {
    const [isTourOpen, setIsTourOpen] = useState(false);

    const steps = stepsFunc && stepsFunc(props.t);

    return (
      <>
        <div className="Help">
          <div className="Help__icon">
            <Button color="success">
              <FontAwesomeIcon icon="question" />
            </Button>
          </div>
          <div className="Help__items">
            {steps && steps.length > 0 && (
              <div className="Help__item Help__tour">
                <Button color="success" onClick={() => setIsTourOpen(true)}>
                  <FontAwesomeIcon icon="lightbulb" />
                  <span className="Help__label">
                    <Trans i18nKey="help.tour">Průvodce</Trans>
                  </span>
                </Button>
              </div>
            )}

            <div className="Help__item Help__pdf">
              <Button
                color="success"
                tag="a"
                target="_blank"
                rel="noopener noreferrer"
                href="/podpora"
              >
                <FontAwesomeIcon icon="info" />
                <span className="Help__label">
                  <Trans i18nKey="help.helpdesk">Podpora</Trans>
                </span>
              </Button>
            </div>

            <div className="Help__item Help__helpdesk">
              <Button
                color="success"
                tag="a"
                target="_blank"
                rel="noopener noreferrer"
                href="/podpora/formular/chyba"
              >
                <FontAwesomeIcon icon="envelope" />
                <span className="Help__label">
                  <Trans i18nKey="help.bugForm">Nahlásit problém</Trans>
                </span>
              </Button>
            </div>
          </div>
        </div>

        <Component {...props} />

        <Tour
          startAt={0}
          isOpen={isTourOpen}
          steps={steps}
          onRequestClose={() => setIsTourOpen(false)}
        />
      </>
    );
  };
}
