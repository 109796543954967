import React, { Component } from "react";
import PropTypes from "prop-types";
import { Trans } from "react-i18next";
import { Button, ButtonGroup } from "reactstrap";
import ExerciseIcon from "./ExerciseIcon";
import EnrolmentModal from "./helpers/modals/EnrolmentModal";
import "./EnrolmentButtons.scss";

class EnrolmentButtons extends Component {
  static propTypes = {
    size: PropTypes.string,
    vertical: PropTypes.bool,
    url: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  static defaultProps = {
    size: "lg",
    vertical: false
  };

  render() {
    return (
      <div className="EnrolmentButtons">
        <div className="EnrolmentButtons__heading">
          <h5>
            <Trans i18nKey="catalogExerciseDetail.enrol">
              Zadat cvičení třídě
            </Trans>
          </h5>
        </div>

        <ButtonGroup
          className="EnrolmentButtons__buttons"
          size={this.props.size}
          vertical={this.props.vertical}
          data-tut="enrolment"
        >
          <Button
            color="primary"
            onClick={() => this.onSelectedModal("individual")}
            data-cy="enroll-bt-individual"
          >
            <ExerciseIcon type="user" />
            <Trans i18nKey="catalogExerciseDetail.individual">
              Individuální
            </Trans>
          </Button>
          <Button
            color="primary"
            onClick={() => this.onSelectedModal("group")}
            data-cy="enroll-bt-group"
          >
            <ExerciseIcon type="group" />
            <Trans i18nKey="catalogExerciseDetail.group">Skupinové</Trans>
          </Button>
          <Button
            color="primary"
            onClick={() => this.onSelectedModal("class")}
            data-cy="enroll-bt-class"
          >
            <ExerciseIcon type="class" />{" "}
            <Trans i18nKey="catalogExerciseDetail.class">S celou třídou</Trans>
          </Button>
        </ButtonGroup>

        <div className="EnrolmentButtons__or">
          <Trans i18nKey="catalogExerciseDetail.or">nebo</Trans>
        </div>

        <div className="EnrolmentButtons__try">
          <Button
            tag="a"
            size={this.props.size}
            target="_blank"
            rel="noopener noreferrer"
            href={this.props.url}
            block
            data-tut="try"
          >
            <Trans i18nKey="catalogExerciseDetail.try">Vyzkoušet cvičení</Trans>
          </Button>
        </div>

        <EnrolmentModal
          setButtonZadatClick={clickEvent =>
            (this.onSelectedModal = clickEvent)
          }
          data={this.props.data}
          history={this.props.history}
        />
      </div>
    );
  }
}

export default EnrolmentButtons;
