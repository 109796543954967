import React from "react";
import DataService from "../../DataService";
import Layout from "../../Layout";
import AccountForm from "./AccountForm";
import NotificationForm from "./../notification/Notification";
import withEverything from "../../withEverything";
import classnames from "classnames";
import {
  TabContent,
  TabPane,
  Nav,
  Row,
  Col,
  NavItem,
  NavLink,
} from "reactstrap";
import { Trans } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Account extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPending: false,
      user: this.props.user,
      activeTab: "1",
      notifications: [],
    };
  }

  onSubmit = (updateData, callback) => {
    this.setState({ error: null, isPending: true });
    new DataService()
      .updateUser(updateData)
      .then(result => {
        const data = result.data;
        this.setState({
          isPending: false,
        });

        if (data && data.error) {
          if (!callback(data.error)) {
            this.onError(data.error);
          }
          return result;
        }
        let currentUser = JSON.parse(sessionStorage.getItem("user"));
        const updateUser = { ...currentUser, ...result.data };
        sessionStorage.setItem("user", JSON.stringify(updateUser));
        window.location.reload(); //TODO better, now Application context of user login didn't update, must hard reload page
        // this.props.history.push("/ucet");
        return result;
      })
      .then(this.props.processNotifications)
      .catch(this.onError);
  };

  componentDidMount() {
    new DataService()
      .fetchNotifications()
      .then(result => {
        this.setState({
          notifications: result.data || {},
          error: null,
          hasData: true,
          isLoading: false,
        });
      })
      .then(this.props.processNotifications)
      .catch(this.onError);
  }

  onError = error => {
    this.setState({
      error,
      isPending: false,
    });

    this.props.notify(error);
  };

  toggleTab(tab) {
    this.setState({
      activeTab: tab.toString(),
    });
  }

  render() {
    const { isPending } = this.state;
    return (
      <Layout
        title={this.props.t("account.header", "Můj účet")}
        isTitleCentered={true}
        isFullWidth={false}
      >
        <Layout.Content>
          <Row className={"mb-3"}>
            <Col align="center">
              <h4>{this.state.user.emailAddress}</h4>
            </Col>
          </Row>
          <Row>
            <Col xs="auto" className="menu--vertical">
              <Nav pills vertical>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === "1",
                    })}
                    onClick={() => {
                      this.toggleTab(1);
                    }}
                  >
                    <Trans i18nKey="account.personalData">Osobní údaje</Trans>
                    {this.state.activeTab === "1" && (
                      <FontAwesomeIcon className="ml-2" icon="angle-right" />
                    )}
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === "2",
                    })}
                    onClick={() => {
                      this.toggleTab(2);
                    }}
                  >
                    <Trans i18nKey="account.notifications">
                      Nastavení oznámení
                    </Trans>
                    {this.state.activeTab === "2" && (
                      <FontAwesomeIcon className="ml-2" icon="angle-right" />
                    )}
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
            <Col>
              <section>
                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="1">
                    <AccountForm
                      onSubmit={this.onSubmit}
                      isPending={isPending}
                      user={this.state.user}
                    />
                  </TabPane>
                  <TabPane tabId="2">
                    {this.state.notifications && (
                      <NotificationForm
                        onSubmit={this.onSubmit}
                        isPending={isPending}
                        user={this.state.user}
                        processNotifications={this.props.processNotifications}
                        onError={this.onError}
                        notifications={this.state.notifications}
                      />
                    )}
                  </TabPane>
                </TabContent>
              </section>
            </Col>
          </Row>
        </Layout.Content>
      </Layout>
    );
  }
}

export default withEverything(Account);
