import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faBookmark,
  faEnvelope,
  faTrash,
  faUsers,
  faEdit,
  faUser,
  faUserFriends,
  faEye,
  faShare,
  faClipboard,
  faLink,
  faPlayCircle,
  faExternalLinkAlt,
  faQuestionCircle,
  faTags,
  faFilter,
  faTimes,
  faSortUp,
  faSortDown,
  faBoxOpen,
  faClock,
  faPlusCircle,
  faSpinner,
  faExclamationTriangle,
  faExchangeAlt,
  faStar,
  faEyeSlash,
  faSquare,
  faCog,
  faLightbulb,
  faCheckCircle,
  faTimesCircle,
  faLock,
  faLockOpen,
  faClipboardList,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";

import {
  faFileAlt as farFileAlt,
  faQuestionCircle as farQuestionCircle,
  faStar as farStar,
  faSquare as farSquare,
} from "@fortawesome/free-regular-svg-icons";

library.add(
  faEnvelope,
  faTrash,
  faUsers,
  faEdit,
  faUser,
  faUserFriends,
  faBookmark,
  faEye,
  faEyeSlash,
  faShare,
  faClipboard,
  faLink,
  faPlayCircle,
  faExternalLinkAlt,
  faQuestionCircle,
  farFileAlt,
  faTags,
  farQuestionCircle,
  faFilter,
  faTimes,
  faSortUp,
  faSortDown,
  faBoxOpen,
  faClock,
  faPlusCircle,
  faSpinner,
  faExclamationTriangle,
  faExchangeAlt,
  faStar,
  farStar,
  faSquare,
  farSquare,
  faCog,
  faLightbulb,
  faCheckCircle,
  faTimesCircle,
  faLock,
  faLockOpen,
  faClipboardList,
  faInfoCircle
);

export default function () { }
