import React, { useState } from "react";
import { InputGroupAddon, InputGroupText, InputGroup, Input } from "reactstrap";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import "./FilterText.scss";

/**
 * Sort exercises by text (input text)
 */
const FilterText = ({ placeholder, onChange }) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <InputGroup
      className={classnames("FilterText", {
        "FilterText--is-focused": isFocused,
      })}
    >
      <InputGroupAddon addonType="prepend">
        <InputGroupText>
          <FontAwesomeIcon id="basic-addon1" icon={faSearch} />
        </InputGroupText>
      </InputGroupAddon>
      <Input
        type="text"
        data-cy="filter-text"
        placeholder={placeholder}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        onChange={e => {
          onChange(e.target.value);
        }}
      />
    </InputGroup>
  );
};

FilterText.defaultProps = {
  placeholder: "Vyhledejte...",
};

export default FilterText;
