import React, { Component } from "react";
import PropTypes from "prop-types";
import { Trans, withTranslation } from "react-i18next";
import { Badge, Progress } from "reactstrap";
import classnames from "classnames";
import { If } from "../../If";
import MobileFriendlyIcon from '../MobileFriendlyIcon/MobileFriendlyIcon';
import "./HLCard.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class HLCard extends Component {
  static propTypes = {
    image: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,

    handleOnClickExercise: PropTypes.func,
    exercise: PropTypes.object,
    authors: PropTypes.array,
    color: PropTypes.string,
    difficulty: PropTypes.string,
    description: PropTypes.string,
    keywords: PropTypes.object,
    epochs: PropTypes.array,
    mobileData: PropTypes.shape({
      mobileFriendly: PropTypes.number,
      touch: PropTypes.number,
      smallScreen: PropTypes.number,
      tabletScreen: PropTypes.number
    }),
    years: PropTypes.array,
    variant: PropTypes.string,

    workType: PropTypes.object,
    isPackage: PropTypes.bool,
    submitted: PropTypes.number,
    total: PropTypes.number,
    isLocked: PropTypes.number
  };

  makeKeywords(keywords) {
    const mergedKeywords = [];

    for (const key in keywords) {
      if (Object.hasOwnProperty.call(keywords, key)) {
        const parsedKeywords = keywords[key].map(keyword => ({
          name: keyword,
          category: key
        }));

        mergedKeywords.push(...parsedKeywords);
      }
    }

    return mergedKeywords.map((keyword, index) => (
      <div
        key={index}
        className="badge--keyword"
        style={{ transform: `translateY(-${38 * index}%)` }}
      >
        <Badge
          color="light"
          pill
          title={this.props.t(`filter.${keyword.category}`)}
        >
          {keyword.name}
        </Badge>
      </div>
    ));
  }

  makeDifficulty(difficulty) {
    if (difficulty === "nízká") {
      return (
        <span>
          <Trans i18nKey="timelineExercise.easy">
            Jednoduché
            <br />
            cvičení
          </Trans>
        </span>
      );
    }
  }

  render() {
    return (
      <div
        className={classnames("card", "card--activity", {
          [`card--${this.props.variant}`]: this.props.variant
        }, { 'card-locked': this.props.isLocked })}
        onClick={
          this.props.handleOnClickExercise
            ? () => this.props.handleOnClickExercise(this.props.exercise)
            : () => { }
        }
        data-cy="exercise-item"
        style={{
          background: this.props.color
        }}
      >
        {this.props.image != null && (
          <div className="card__image">
            <img src={this.props.image} alt={this.props.name} loading="lazy" />
          </div>
        )}

        <div className="card__header">
          {!this.props.isPackage && this.props.workType && (
            <div className="card__workType">
              <Badge className="badge--workType" color="light" pill>
                <Trans i18nKey={"exercise." + this.props.workType.type}>
                  {this.props.workType.name}
                </Trans>
              </Badge>
            </div>
          )}
          {this.props.isLocked === 1 && <FontAwesomeIcon icon="lock" className="mr-1" />}
          {this.props.difficulty && (
            <div className="card__difficulty">
              {this.makeDifficulty(this.props.difficulty)}
            </div>
          )}

          {this.props.time > 0 && (
            <div className="card__time">{this.props.time}&thinsp;min</div>
          )}

          {this.props.mobileData && (
            <div className={classnames("card__mobileFriendly", {
              "card__mobileFriendly--visible": this.props.mobileData.mobileFriendly === 2
            })}>
              <MobileFriendlyIcon mobileData={this.props.mobileData} />
            </div>
          )}

          {this.props.keywords && (
            <div className="card__keywords">
              {this.makeKeywords(this.props.keywords)}
            </div>
          )}

          {this.props.epochs && (
            <div className="card__histories">
              {this.props.epochs
                .map((history, index) => {
                  if (
                    history.obdobi.length > 0 &&
                    history.obdobi[0].length > 0
                  ) {
                    return (
                      <span
                        className="badge--history"
                        style={{
                          backgroundColor: `var(--color-${history.refId})`
                        }}
                        key={index}
                      ></span>
                    );
                  }

                  return "";
                })
                .reverse()}
            </div>
          )}

          {this.props.years && (
            <div className="card__years">
              <Badge className="badge--year" color="light" pill>
                {this.props.years.join(", ")}
              </Badge>
            </div>
          )}
        </div>

        {this.props.name && (
          <div className="card__title">
            <h4 data-cy="card-name">{this.props.name}</h4>
          </div>
        )}

        {this.props.description && (
          <div className="card__description">{this.props.description}</div>
        )}

        {!this.props.isPackage && this.props.total !== undefined && (
          <If
            condition={
              this.props.total > 0 && this.props.workType.type !== "class"
            }
          >
            <div className="card__progress">
              <div className="card__progressText">
                <If condition={this.props.total === 0}>
                  <Badge color="warning">
                    <Trans i18nKey="exercise.noEmailAddresses">
                      Bez zadaných e-mailových adres
                    </Trans>
                  </Badge>
                </If>

                <If
                  condition={
                    this.props.total > 0 &&
                    this.props.submitted === this.props.total
                  }
                >
                  <span>
                    <Trans i18nKey="exercise.allSubmitted">Vše odevzdáno</Trans>
                  </span>
                </If>

                <If condition={this.props.submitted !== this.props.total}>
                  <span>
                    {this.props.submitted} / {this.props.total}
                  </span>
                </If>
              </div>

              <Progress
                className="card__progressBar"
                color="success"
                value={this.props.submitted}
                max={this.props.total}
              />
            </div>
          </If>
        )}
      </div>
    );
  }
}

export default withTranslation()(HLCard);
