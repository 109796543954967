import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route } from "react-router-dom";
import App from "./App";
import { ApplicationStateProvider } from "./ApplicationStateProvider";
import { NotificationProvider } from "./NotificationProvider";
import "./icons";
import { I18nextProvider } from "react-i18next";

import { i18ninit } from "./i18n";
import ScrollToTop from "./helpers/ScrollToTop";

i18ninit().then(i18n => {
  ReactDOM.render(
    <Suspense fallback="loading">
      <div className="App">
        <I18nextProvider i18n={i18n}>
          <BrowserRouter>
            <NotificationProvider>
              <ApplicationStateProvider i18n={i18n}>
                <Route>
                  <ScrollToTop />
                  <App />
                </Route>
              </ApplicationStateProvider>
            </NotificationProvider>
          </BrowserRouter>
        </I18nextProvider>
      </div>
    </Suspense>,
    document.getElementById("root")
  );
});
