import React from "react";
import i18n from "i18next";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";
import {
  Badge,
  Breadcrumb,
  BreadcrumbItem,
  TabContent,
  TabPane,
  Nav,
  Row,
  Col,
  Button,
  ButtonGroup,
  NavItem,
  NavLink,
  Input
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import parse from "url-parse";
import DataService from "./../DataService";
import Login from "./../Auth/Login";
import { ApplicationStateConsumer } from "./../ApplicationStateProvider";
import withEverything from "../withEverything";
import withTour from "../withTour";
import Submissions from "./../submissions/Submissions";
import { If } from "./../If";
import EmailAddressesDialog from "./EmailAddressesDialog";
import EmailAddresses from "./EmailAddresses";
import DeleteExerciseDialog from "./DeleteExerciseDialog";
import Layout from "./../Layout";
import env from "@beam-australia/react-env";
import "./ExerciseDetail.scss";

class MyExerciseDetail extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoading: false,
      emailAddresses: "",
      error: null,
      pending: false,
      modal: false,
      exercise: {},
      activeTab: "1",
      deleteExercise: {
        isOpen: false
      },
      addEmailAddresses: {
        isOpen: false
      }
    };
  }

  refresh = () => {
    if (this.props.location && this.props.location.state) {
      const { exercise } = this.props.location.state;
      if (exercise != null) {
        this.setState({
          exercise,
          isLoading: false
        });
        return;
      }
    }

    this.setState({ isLoading: true });
    const id = this.props.match.params.id;
    new DataService()
      .fetchMyExercise(id)
      .then(result => {
        this.setState({
          exercise: result.data || result,
          isLoading: false
        });
        return result;
      })
      .then(this.props.processNotifications)
      .catch(this.onError);
  };

  onError = error => {
    this.setState({
      error,
      isLoading: false
    });
    this.emailAddressesFormRef && this.emailAddressesFormRef.toggle();
    this.props.notify(error);
  };

  addEmailAddresses = (exercise, emailAddresses) => {
    this.setState({ pending: true, error: null });
    new DataService()
      .addEmailAddressesToGroup(exercise.group.id, emailAddresses)
      .then(result => {
        this.setState({ exercise: result.data });
        return result;
      })
      .then(this.props.processNotifications)
      .catch(this.onError)
      .finally(_ => {
        this.toggleAddEmailAddresses();
      });
  };

  onEmailAddressesChanged = e => {
    this.setState({ emailAddresses: e.target.value });
  };

  onSelected = (type, enrol) => {
    const { data } = this.state;
    const { exercise } = data;
    this.setState({ pending: true });
    enrol(exercise.id, type).then(() => {
      this.setState({ pending: false });
    });
  };

  sendExerciseToReceipients = () => {
    const { exercise } = this.state;
    new DataService()
      .sendExerciseToReceipients(exercise.id)
      .then(data => {
        this.props.processNotifications({
          notifications: data.notifications.concat(data.data.notifications)
        });
      })
      .catch(error => {
        this.onError(error);
      });
  };

  deleteEnrolment = () => {
    const { exercise } = this.state;
    const { history } = this.props;
    this.setState({ pending: true, error: null });
    new DataService()
      .deleteEnrolment(exercise.id)
      .then(this.props.processNotifications)
      .then(() => {
        history.push("/moje");
      })
      .catch(error => {
        this.onError(error);
      });
  };

  onGroupSelected = group => {
    const { exercise } = this.state;
    this.setState({ pending: true, error: null });
    new DataService()
      .changeGroup(exercise.id, group.id)
      .then(result => {
        this.setState({
          exercise: result.data || result
        });
        return result;
      })
      .then(this.props.processNotifications)
      .catch(error => {
        this.onError(error);
      });
  };

  evaluate = evaluation => {
    const { exercise } = this.state;
    this.setState({ pending: true, error: null });
    return new DataService()
      .evaluate({ enrolmentId: exercise.id, ...evaluation })
      .then(result => {
        this.setState({
          exercise: result.data || result,
          isLoading: false,
          pending: false
        });
        return result;
      })
      .then(result => {
        return new Promise((resolve, reject) => {
          resolve(exercise.submissions);
        });
      })
      .catch(error => {
        return new Promise((resolve, reject) => {
          reject(error);
        });
      });
  };

  deleteEmailAddressFromGroup = emailAddress => {
    const { exercise } = this.state;
    this.setState({ pending: true, error: null });
    new DataService()
      .deleteEmailAddressFromGroup(exercise.group.id, emailAddress)
      .then(result => {
        this.setState({
          exercise: result.data || result,
          modal: false,
          emailAddress: {}
        });
        this.emailAddressesFormRef && this.emailAddressesFormRef.toggle();
      })
      .then(this.props.processNotifications)
      .catch(this.onError);
  };

  addEmailAddressToGroup = emailAddress => {
    const { exercise } = this.state;
    this.setState({ pending: true, error: null });
    new DataService()
      .addEmailAddressesToGroup(exercise.group.id, emailAddress.emailAddress)
      .then(result => {
        this.setState({ exercise: result.data || result });
        return result;
      })
      .then(this.props.processNotifications)
      .catch(this.onError);
  };

  shareWithOthers = submission => {
    const { exercise } = this.state;
    return new DataService()
      .shareWithOthers(exercise.id, submission.entryId)
      .then(this.props.processNotifications);
  };

  componentDidMount() {
    this.refresh();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.authenticated && this.props.authenticated) {
      this.refresh();
    }
  }

  toggleTab(tab) {
    this.setState({
      activeTab: tab.toString()
    });
  }

  copyToClipboard = async text => {
    const notification = {
      type: "info",
      text: i18n.t(
        "exerciseDetail.linkCopied",
        "Odkaz na cvičení byl zkopírován do schránky."
      )
    };

    if (window.navigator.clipboard) {
      await window.navigator.clipboard.writeText(text);
      this.props.notify(notification);
      return;
    }

    try {
      document.querySelector("[data-clipboard='exerciseLink']").select();
      document.execCommand("copy");
      this.props.notify(notification);
    } catch (e) {
      this.props.notify({
        type: "warning",
        text: i18n.t(
          "exerciseDetail.linkNotCopied",
          "Odkaz na cvičení nebyl zkopírován do schránky."
        )
      });
      return;
    }

    this.props.notify(notification);
  };

  toggleDeleteConfirmation = () => {
    this.setState({
      deleteExercise: {
        isOpen: !this.state.deleteExercise.isOpen
      }
    });
  };

  toggleAddEmailAddresses = () => {
    this.setState({
      addEmailAddresses: {
        isOpen: !this.state.addEmailAddresses.isOpen
      }
    });
  };

  changeEnrolmentLockState = (isLocked) => {
    const { exercise } = this.state;
    return new DataService()
      .changeEnrolmentLockState(exercise.id, isLocked)
      .then((result) => {
        this.props.processNotifications(result);
        this.setState(prevState => ({
          exercise: {
            ...prevState.exercise,
            isLocked: result.data.isLocked
          }
        }));
      });
  }

  render() {
    const { exercise, isLoading } = this.state;
    const { t } = this.props;
    const title = isLoading ? "..." : exercise.name;

    const exerciseUrl = parse(exercise.url, true);
    let query = exerciseUrl.query;
    query["lang"] = i18n.language;
    exerciseUrl.set("query", query);

    const tryItUrl = parse(exercise.tryItUrl, true);
    query = tryItUrl.query;
    query["lang"] = i18n.language;
    tryItUrl.set("query", query);

    const questionnaireUrl = env("QUESTIONNAIRE_URL") || "";
    const showQuestionnaireHint =
      questionnaireUrl !== null && questionnaireUrl.trim().length > 0;

    const breadcrumb = (
      <>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/tridy">
              <Trans i18nKey="mainHeader.myClasses">Moje třídy</Trans>
            </Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link
              to={
                "/tridy/" +
                (!isLoading && exercise.group ? exercise.group.id : "")
              }
            >
              {t("exerciseList.class", "Třída") +
                (!isLoading && exercise.group
                  ? ": " + exercise.group.name
                  : "")}
            </Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>
            <Trans i18nKey="exerciseDetail.assignedExercise">
              Zadané cvičení
            </Trans>
          </BreadcrumbItem>
        </Breadcrumb>
      </>
    );
    //
    const subHeader = (authenticated, isLoading, exercise) => {
      if (authenticated && !isLoading && exercise && exercise.id) {
        return (
          <div className="ExerciseDetail__info">
            <Badge pill color="light">
              {exercise.group.name}
            </Badge>
            <Badge pill color="info">
              <Trans i18nKey={"catalogExerciseDetail." + exercise.type}></Trans>
            </Badge>
          </div>
        );
      }
    };

    const isLocked = exercise.isLocked === 1;
    const titleLock = exercise.isLocked ? t("exerciseDetail.lockEnrolmentTitle"," - ZAMČENÉ") : '';

    return (
      <ApplicationStateConsumer>
        {({ authenticated, login }) => (
          <Layout
            className="ExerciseDetail"
            title={`${title}${titleLock}`}
            subHeader={subHeader(authenticated, isLoading, exercise)}
            breadcrumb={breadcrumb}
          >
            {!authenticated && (
              <Layout.Content>
                <Row>
                  <Col>
                    <h1>
                      <Trans i18nKey="signIn">Přihlašte se</Trans>
                    </h1>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Login login={login} />
                  </Col>
                </Row>
              </Layout.Content>
            )}

            {authenticated && isLoading && (
              <Layout.Content>
                <Row>
                  <Col>
                    <h1>
                      <Trans i18nKey="exerciseDetail.pleaseWait">
                        Počkejte, cvičení se načítá ...
                      </Trans>
                    </h1>
                  </Col>
                </Row>
              </Layout.Content>
            )}

            {authenticated && !isLoading && exercise && exercise.id && (
              <Layout.Content>
                <Row>
                  <Col xs="auto" className="menu--vertical">
                    <Nav pills vertical>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: this.state.activeTab === "1"
                          })}
                          onClick={() => {
                            this.toggleTab(1);
                          }}
                        >
                          <Trans i18nKey="exerciseDetail.submitted">
                            Odevzdaná cvičení
                          </Trans>

                          {this.state.activeTab === "1" && (
                            <FontAwesomeIcon
                              className="ml-2"
                              icon="angle-right"
                            />
                          )}
                        </NavLink>
                      </NavItem>

                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: this.state.activeTab === "2"
                          })}
                          onClick={() => {
                            this.toggleTab(2);
                          }}
                          data-tut="classMembers"
                        >
                          <Trans i18nKey="exerciseDetail.classMembers">
                            Členové třídy
                          </Trans>

                          {this.state.activeTab === "2" && (
                            <FontAwesomeIcon
                              className="ml-2"
                              icon="angle-right"
                            />
                          )}
                        </NavLink>
                      </NavItem>
                    </Nav>

                    <hr />

                    <ButtonGroup vertical>
                      <Button
                        color="link"
                        href={tryItUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FontAwesomeIcon className="mr-1" icon="play-circle" />
                        <Trans i18nKey="exerciseDetail.startExerciseAsTeacher">
                          Spustit jako učitel
                        </Trans>
                      </Button>

                      {isLocked &&
                        <Button
                          onClick={() => this.changeEnrolmentLockState(0)}
                          color="link"
                          data-tut="unlockEnrolment"
                        >
                          <FontAwesomeIcon className="mr-1" icon="lock-open" />
                          <Trans i18nKey="exerciseDetail.unlockActivity">
                            Odemknout cvičení
                          </Trans>
                        </Button>
                      }

                      {!isLocked &&
                        <Button
                          onClick={() => this.changeEnrolmentLockState(1)}
                          color="link"
                          data-tut="lockEnrolment"
                        >
                          <FontAwesomeIcon className="mr-1" icon="lock" />
                          <Trans i18nKey="exerciseDetail.lockActivity">
                            Zamknout cvičení
                          </Trans>
                        </Button>
                      }

                      <Button
                        color="link"
                        onClick={e => this.copyToClipboard(exerciseUrl)}
                        title={t(
                          "exerciseDetail.copyLinkTooltip",
                          "Zkopírovat do schránky"
                        )}
                        data-tut="copyLink"
                      >
                        <FontAwesomeIcon className="mr-1" icon="link" />
                        <Trans i18nKey="exerciseDetail.copyLink">
                          Zkopírovat odkaz cvičení
                        </Trans>
                        <Input
                          type="hidden"
                          value={exerciseUrl}
                          data-clipboard="exerciseLink"
                          readOnly
                        />
                      </Button>

                      <Button
                        onClick={this.toggleAddEmailAddresses}
                        color="link"
                        cy-data="addEmailAddresses"
                        data-tut="addEmailAddresses"
                      >
                        <FontAwesomeIcon className="mr-1" icon="user-plus" />
                        <Trans i18nKey="exerciseDetail.addEmailAddresses">
                          Přidat žáky
                        </Trans>
                        <EmailAddressesDialog
                          exercise={exercise}
                          onConfirmed={this.addEmailAddresses}
                          isOpen={this.state.addEmailAddresses.isOpen}
                          toggle={this.toggleAddEmailAddresses}
                        />
                      </Button>

                      <If condition={exercise.emailAddresses.length > 0}>
                        <Button
                          onClick={this.sendExerciseToReceipients}
                          color="link"
                          data-tut="sendExerciseToReceipients"
                        >
                          <FontAwesomeIcon
                            icon="envelope"
                            // icon={["far", "envelope"]}
                            className="mr-1"
                          />
                          <Trans i18nKey="exerciseDetail.sendEmail">
                            Odeslat e-mail se zadáním
                          </Trans>
                        </Button>
                      </If>

                      <Button
                        color="link"
                        href={"/katalog/cviceni/" + exercise.slug}
                      >
                        <FontAwesomeIcon
                          icon="info-circle"
                          // icon={["far", "envelope"]}
                          className="mr-1"
                        />
                        <Trans i18nKey="exerciseDetail.linkToActivityDetail">
                          Informace o cvičení
                        </Trans>
                      </Button>

                      <hr />

                      <Button
                        color="link"
                        className="text-danger"
                        onClick={this.toggleDeleteConfirmation}
                      >
                        <Trans i18nKey="exerciseDetail.removeExercise">
                          Smazat cvičení
                        </Trans>

                        <DeleteExerciseDialog
                          isOpen={this.state.deleteExercise.isOpen}
                          toggle={this.toggleDeleteConfirmation}
                          onConfirmed={this.deleteEnrolment}
                        />
                      </Button>
                    </ButtonGroup>
                  </Col>

                  <Col>
                    {showQuestionnaireHint && (
                      <section className="QuestionnaireHint">
                        <div className="label">
                          <Trans i18nKey="exerciseDetail.instructions">
                            Instrukce k testování:
                          </Trans>
                        </div>
                        <div>
                          <a
                            href={questionnaireUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Trans i18nKey="exerciseDetail.questionnaire">
                              Po dokončení hodiny prosíme o vyplnění dotazníku.
                              Děkujeme.
                            </Trans>
                            <FontAwesomeIcon
                              className="ml-1"
                              size="xs"
                              icon="external-link-alt"
                            />
                          </a>
                        </div>
                      </section>
                    )}

                    <section>
                      <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="1">
                          <Submissions
                            submissions={exercise.submissions}
                            evaluate={this.evaluate}
                            shareWithOthers={this.shareWithOthers}
                            enableShare={exercise.type === "class"}
                          />
                        </TabPane>
                        <TabPane tabId="2">
                          <EmailAddresses
                            emailAddresses={exercise.emailAddresses}
                            addEmailAddress={this.addEmailAddressToGroup}
                            deleteEmailAddress={
                              this.deleteEmailAddressFromGroup
                            }
                          />
                        </TabPane>
                      </TabContent>
                    </section>
                  </Col>
                </Row>
              </Layout.Content>
            )}
          </Layout>
        )}
      </ApplicationStateConsumer>
    );
  }
}

const tourSteps = t => {
  const step = name => t("exerciseDetail.tutorial." + name);
  const steps = [
    { selector: "[data-tut='copyLink']", content: step("copyLink") },
    {
      selector: "[data-tut='addEmailAddresses']",
      content: step("addEmailAddresses")
    },
    { selector: "[data-tut='classMembers']", content: step("classMembers") },
    {
      selector: "[data-tut='sendExerciseToReceipients']",
      content: step("sendExerciseToReceipients")
    },
    { selector: "[data-tut='viewUrl']", content: step("viewUrl") },
    { selector: "[data-tut='lastUpdated']", content: step("lastUpdated") },
    { selector: "[data-tut='timeSpent']", content: step("timeSpent") },
    { selector: "[data-tut='evaluation']", content: step("evaluation") }
  ];
  return steps;
};

export default withEverything(withTour(MyExerciseDetail, tourSteps));
