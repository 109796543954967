import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import classnames from "classnames";

import MainHeader from "./MainHeader";
import MainFooter from "./MainFooter";
import Header from "./Header";
import "./Layout.scss";

export default class Layout extends Component {
  static Content = ({ children }) => <>{children}</>;
  static SubHeader = ({ children }) => <>{children}</>;

  render() {
  // TODO: vyfiltrovat children dle typu (např. Layout.content, Layout.breadcrumb, …) 

  return (
      <>
        <MainHeader 
          isHeaderAbsolutePositioned= { this.props.isHeaderAbsolutePositioned }
          isHeaderInverted= { this.props.isHeaderInverted }
        />
        <main className={this.props.className}>
          <Container
            fluid={this.props.isFullWidth}
            style={this.props.style}
          >
            
            {this.props.breadcrumb && (this.props.breadcrumb)}

            {this.props.title && (
              <Row 
                className={ classnames({
                  "layout__header": true,
                  "text-center": this.props.isTitleCentered || !this.props.subHeader,
                })}
              >
                
                <Col>
                  <Header title={this.props.title} />
                </Col>
                
                {this.props.subHeader && (
                  <Col className="subHeader">{this.props.subHeader}</Col>
                )}

              </Row>
            )}

            {this.props.children}

          </Container>
        </main>
        <MainFooter />
      </>
    );
  }
}
