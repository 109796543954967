import { select, pointer, selectAll } from "d3-selection";
import { transition } from "d3-transition";
import { easeExpOut } from "d3-ease";

export default {
    select: select,
    pointer: pointer,
    selectAll: selectAll,
    transition: transition,
    easeExpOut: easeExpOut
};