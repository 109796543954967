import React from "react";
import classnames from "classnames";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { Col, Row, Button, FormGroup, Label, CustomInput } from "reactstrap";
import { ReactstrapInput } from "reactstrap-formik";
import { withTranslation, Trans } from "react-i18next";
import { CirclePicker } from "react-color";
import { faArchive, faBook } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import setYupLocale from "../../yup";
import "./ClassForm.scss";
import ReactstrapSelectInput from "../../components/ReactstrapSelectInput";
import typeOfClass from "./typeOfClass.json";

setYupLocale("classForm");

const ClassSchema = Yup.object().shape({
  name: Yup.string().max(50).required(),
  note: Yup.string().max(500),
  color: Yup.string().max(20),
  yearStart: Yup.number().moreThan(1900).lessThan(2500).required(),
  yearEnd: Yup.number()
    .moreThan(1901)
    .lessThan(2500)
    .when("yearStart", (yearStart, schema) =>
      yearStart ? schema.moreThan(yearStart - 1) : schema.moreThan(1901)
    )
    .required(),
  isArchived: Yup.bool(),
  isFavourite: Yup.bool(),
  "projects.d-plus": Yup.bool(),
  typeOfClass: Yup.string().when("projects.d-plus", {
    is: true,
    then: Yup.string().required("Musíte zvolit typ třídy."),
  }),
});

export class ClassForm extends React.Component {
  render() {
    const { onSubmit, t, group, isLoading, user } = this.props;
    const defaultGroup = {
      name: "",
      note: "",
      color: "#f44336",
      yearStart: new Date().getFullYear(),
      yearEnd: new Date().getFullYear() + 1,
      isArchived: false,
      isFavourite: false,
      projects: {
        "d-plus": false,
      },
      typeOfClass: "",
    };

    const classOptions = typeOfClass.map(type => ({
      id: type.id,
      name: t(type.name),
    }));

    const getLabelWithIcon = (text, icon) => (
      <div>
        {icon && <FontAwesomeIcon icon={icon} className="mr-2" />}
        {t(text)}
      </div>
    );

    return (
      <Formik
        initialValues={group || defaultGroup}
        enableReinitialize
        validationSchema={ClassSchema}
        onSubmit={(values, actions) => {
          onSubmit(values, error => {
            if (error.form) {
              actions.setErrors(error.form);
              return true;
            }
            return false;
          });
        }}
        render={form => {
          const { values, setFieldValue, handleChange, errors } = form;
          return (
            <Form className="ClassForm">
              <Row form>
                <Col>
                  <FormGroup>
                    <Field
                      label={t("classForm.name.label")}
                      name="name"
                      id="name"
                      placeholder={t("classForm.name.placeholder")}
                      component={ReactstrapInput}
                      data-tut="className"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row form data-tut="schoolYear">
                <Col>
                  <FormGroup>
                    <Label for="yearStart">
                      {t("classForm.yearStart.label")}
                    </Label>
                    <Field
                      type="number"
                      label={t("classForm.yearStart.label")}
                      name="yearStart"
                      id="yearStart"
                      className={classnames({
                        "form-control": true,
                        "is-invalid": !!errors.yearStart,
                      })}
                      placeholder={t("classForm.yearStart.placeholder")}
                      onChange={e => {
                        handleChange(e);
                        setFieldValue("yearEnd", e.target.value * 1 + 1);
                      }}
                    />
                    {errors.yearStart && (
                      <div className="invalid-feedback">{errors.yearStart}</div>
                    )}
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Field
                      type="number"
                      label={t("classForm.yearEnd.label")}
                      name="yearEnd"
                      id="yearEnd"
                      placeholder={t("classForm.yearEnd.placeholder")}
                      component={ReactstrapInput}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row form>
                <Col>
                  <CustomInput
                    type="switch"
                    id="isFavourite"
                    className="form-control-lg"
                    name="isFavourite"
                    label={getLabelWithIcon(
                      "classForm.isFavourite.label",
                      "star"
                    )}
                    checked={values.isFavourite}
                    disabled={values.isArchived}
                    onChange={() =>
                      setFieldValue("isFavourite", !values.isFavourite)
                    }
                    data-tut="isFavourite"
                  />
                  <CustomInput
                    type="switch"
                    id="isArchived"
                    name="isArchived"
                    className="form-control-lg"
                    label={getLabelWithIcon(
                      "classForm.isArchived.label",
                      faArchive
                    )}
                    checked={values.isArchived}
                    onChange={() => {
                      setFieldValue("isArchived", !values.isArchived);

                      if (!values.isArchived) {
                        setFieldValue("isFavourite", false);
                      }
                    }}
                    data-tut="isArchived"
                  />
                </Col>

                <Col>
                  <FormGroup data-tut="classColor">
                    <Label className="label-color" for="color">
                      {t("classForm.color.label")}
                    </Label>
                    <CirclePicker
                      color={values.color}
                      onChange={e => setFieldValue("color", e.hex)}
                    />
                  </FormGroup>
                </Col>
              </Row>
              {user.projects.some(p => p === "d-plus") && (
                <Row form>
                  <Col>
                    <CustomInput
                      type="switch"
                      id="d-plus"
                      className="form-control-lg"
                      name="projects.d-plus"
                      label={getLabelWithIcon(
                        "classForm.isDPlus.label",
                        faBook
                      )}
                      checked={values.projects["d-plus"]}
                      onChange={() => {
                        setFieldValue(
                          "projects.d-plus",
                          !values.projects["d-plus"]
                        );
                        if (values.isArchived) {
                          setFieldValue("projects.d-plus", false);
                        }
                      }}
                      data-tut="DPlus"
                    />
                  </Col>
                  <Col>
                    <Field
                      name="typeOfClass"
                      label={t("classForm.typeOfClass.label")}
                      id="typeOfClass"
                      inputprops={{
                        name: "typeOfClass",
                        label: "",
                        id: "typeOfClass",
                        options: classOptions,
                        defaultOption: {
                          name: t("classForm.typeOfClass.options.default"),
                          id: "",
                        },
                      }}
                      component={ReactstrapSelectInput}
                    />
                  </Col>
                </Row>
              )}
              <Row form>
                <Col>
                  <FormGroup>
                    <Field
                      type="textarea"
                      rows={7}
                      label={t("classForm.note.label")}
                      name="note"
                      id="note"
                      placeholder={t("classForm.note.placeholder")}
                      component={ReactstrapInput}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row form>
                <Col style={{ display: "flex", justifyContent: "center" }}>
                  <FormGroup className="mt-2 text-center">
                    <Button
                      type="submit"
                      className="mt-1"
                      color="primary"
                      disabled={isLoading}
                      data-cy={"group-form-submit"}
                    >
                      <Trans i18nKey="classForm.save.label">Uložit</Trans>
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          );
        }}
      />
    );
  }
}

export default withTranslation()(ClassForm);
