import React from "react";
import classnames from "classnames";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Container,
  Row,
  Col,
} from "reactstrap";

import { withTranslation, Trans } from "react-i18next";
import "./ChooseOrCreateGroup.scss";
import { Field, Form, Formik } from "formik";
import setYupLocale from "../../yup";
import GroupSelector from "./GroupSelector";

setYupLocale("tinyClassForm");

const ChooseOrCreateGroupModal = props => {
  const { isOpen, loading, groups, onAssignActivity, toggle, t } = props;
  const type = props.type || "exercise";
  return (
    <Modal
      isOpen={isOpen}
      className="ChooseOrCreateGroupModal"
      backdrop
      toggle={toggle}
      fade={false}
      role="class"
      size="lg"
    >
      <Formik
        initialValues={{
          id: -1,
          name: "",
          groupname: "",
        }}
        validate={({ id, name }, props) => {
          const errors = {};
          if (id === -1) {
            if (name === "") {
              errors.name = "tinyClassForm.name.required";
            } else if (name.length < 3) {
              errors.name = "tinyClassForm.name.min";
            }
          }
          return errors;
        }}
        onSubmit={f => {
          if (f.id > 0) {
            onAssignActivity({ id: f.id, name: f.groupname });
            return false;
          }
          onAssignActivity({ id: f.id, name: f.name });
          return false;
        }}
        isInitialValid={false}
      >
        {form => (
          <Form>
            <ModalHeader>
              <Trans i18nKey={`catalogExerciseDetail.assignActivity.${type}`} />
            </ModalHeader>
            <ModalBody>
              <Container className="ChooseOrCreateGroup">
                <Row>
                  <Col xs="5">
                    <h3>
                      <Trans i18nKey="chooseOrCreateGroup.choose">
                        Existující třídě
                      </Trans>
                    </h3>
                    <GroupSelector
                      loading={loading}
                      groups={groups || []}
                      selectedId={form.values.id}
                      onGroupSelected={g => {
                        form.setValues(
                          {
                            id: g.id,
                            groupname: g.name,
                            name: form.values.name,
                          },
                          true
                        );
                      }}
                      t={t}
                    />
                  </Col>
                  <Col className="col-divider" xs="2">
                    <Trans i18nKey="or">Nebo</Trans>
                  </Col>
                  <Col xs="5">
                    <h3>
                      <Trans i18nKey="chooseOrCreateGroup.create">
                        Nové třídě
                      </Trans>
                    </h3>
                    <FormGroup>
                      <Label className="label-color" for="name">
                        {t("tinyClassForm.name.label")}
                      </Label>
                      <Field
                        label={t("tinyClassForm.name.label")}
                        name="name"
                        id="name"
                        className={classnames({
                          "form-control": true,
                          "is-invalid": !!form.errors.name,
                        })}
                        placeholder={t("tinyClassForm.name.placeholder")}
                        onFocus={e => {
                          if (form.values.id !== -1) {
                            form.setFieldValue("id", -1);
                            form.handleChange(e);
                          }
                        }}
                        onChange={e => {
                          form.setFieldValue("id", -1);
                          form.handleChange(e);
                        }}
                      />
                      {form.errors && form.errors.name && (
                        <div className="invalid-feedback">
                          {t(form.errors.name)}
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
              </Container>
            </ModalBody>
            <ModalFooter>
              <Button
                data-cy="chose-or-create-group-modal-submit-bt"
                color="primary"
                type="submit"
                disabled={form.values.id === -1 && !!form.errors.name}
              >
                {form.values.id >= 0 && (
                  <Trans
                    i18nKey={`chooseOrCreateGroupModal.choose.${type}.label`}
                    values={{
                      group: form.values.groupname.length
                        ? `„<i>${form.values.groupname}</i>“`
                        : "",
                    }}
                  />
                )}
                {form.values.id === -1 && (
                  <Trans
                    i18nKey={`chooseOrCreateGroupModal.create.${type}.label`}
                    values={{
                      group: form.values.name.length
                        ? `„<i>${form.values.name}</i>“`
                        : "",
                    }}
                  />
                )}
              </Button>
              <Button color="secondary" onClick={toggle}>
                <Trans i18nKey="cancel" />
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
export default withTranslation()(ChooseOrCreateGroupModal);
