import React from "react";
import { Link } from "react-router-dom";
import { withTranslation, Trans } from "react-i18next";
import { Row, Col } from "reactstrap";
import { ApplicationStateConsumer } from "./ApplicationStateProvider";
import Layout from "./Layout";
// import "./css/App.css";
// import "./scss/homepage.scss";

class Home extends React.Component {
  render() {
    return (
      <Layout
        title={this.props.t("welcome", "Vítejte")}
        isTitleCentered
        isFullWidth={false}
      >
        <Layout.Content>
          <ApplicationStateConsumer>
            {({ authenticated }) => (
              <Row className="text-center">
                <Col>
                  {!authenticated && (
                    <h3 className="mb-2">
                      <Link to="/prihlasit">
                        <Trans i18nKey="signIn">Přihlásit se</Trans>
                      </Link>{" "}
                      <Trans i18nKey="or">nebo</Trans>{" "}
                      <Link to="/registrace">
                        <Trans i18nKey="signUp.signUp">Registrovat se</Trans>
                      </Link>
                    </h3>
                  )}

                  {authenticated && (
                    <Link to="/tridy">
                      <h3 className="mb-2">
                        <Trans i18nKey="mainHeader.myClasses" />
                      </h3>
                    </Link>
                  )}

                  <hr />

                  <Link to="/katalog">
                    <h3 className="mb-2">
                      <Trans i18nKey="catalog.catalog">Katalog</Trans>
                    </h3>
                  </Link>
                  <Link to="/timeline">
                    <h3 className="mb-2">
                      <Trans i18nKey="timeline.navbar">Časová osa</Trans>
                    </h3>
                  </Link>
                </Col>
              </Row>
            )}
          </ApplicationStateConsumer>
        </Layout.Content>
      </Layout>
    );
  }
}

export default withTranslation()(Home);
