import React from "react";
import { Field, Form, Formik } from "formik";
import { ReactstrapInput, ReactstrapSelect } from "reactstrap-formik";
import { Container, Button, FormGroup, Col } from "reactstrap";
import DataService from "./../DataService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatePicker from "reactstrap-date-picker";
import "./style.css";

class DataFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      error: null,
      katalog: [],
    };
  }

  refresh = () => {
    this.setState({ isLoading: true });

    new DataService().fetchPeepingFilterData().then(
      result => {
        let data = result.data || [];
        this.setState({
          error: null,
          katalog: data,
          isLoading: false,
        });
      },
      error =>
        this.setState({
          error,
          isLoading: false,
        })
    );
  };

  componentDidMount() {
    this.refresh();
  }

  render() {
    const { katalog } = this.state;
    const { isLoading, isExporting } = this.props;
    const exerciseMode = [
      { id: 0, name: "individuální cvičení" },
      { id: 1, name: "skupinové cvičení" },
      { id: 2, name: "cvičení s celou třídou" },
    ];
    const that = this;
    return (
      <div>
        <Container>
          <Formik
            initialValues={{ dateFrom: "", dateTo: "" }}
            onSubmit={(values, { setSubmitting }) => {
              values = values || {};
              let exerciseMode = values.exerciseMode;
              if (!exerciseMode || exerciseMode === "") {
                exerciseMode = -1;
              }
              exerciseMode *= 1;
              let done = values.done;
              if (!done || done === "") {
                done = -1;
              }
              done *= 1;
              let katalog = values.katalog;
              if (!katalog || katalog === "") {
                katalog = -1;
              }
              katalog *= 1;
              let lmsHash = values.lmsHash;
              if (!lmsHash || lmsHash === "") {
                lmsHash = -1;
              }
              lmsHash *= 1;
              if (that.state.type === "export") {
                that.setState({ type: null }, () =>
                  that.props.onExport(
                    {
                      ...values,
                      exerciseMode,
                      done,
                      katalog,
                    },
                    { includeNotes: this.state.includeNotes }
                  )
                );
              } else {
                that.props.onSubmit({
                  ...values,
                  exerciseMode,
                  done,
                  katalog,
                  lmsHash,
                });
                setSubmitting(false);
              }
            }}
          >
            {props => {
              return (
                <Form>
                  <FormGroup row>
                    <Col>
                      <label htmlFor="dateFrom" className="label-color">
                        Od
                      </label>
                      <DatePicker
                        dateFormat="DD.MM.YYYY"
                        value={props.values.dateFrom}
                        onChange={v => props.setFieldValue("dateFrom", v)}
                      />
                    </Col>
                    <Col>
                      <label htmlFor="dateTo" className="label-color">
                        Do
                      </label>
                      <DatePicker
                        dateFormat="DD.MM.YYYY"
                        value={props.values.dateTo}
                        onChange={v => props.setFieldValue("dateTo", v)}
                      />
                    </Col>
                    <Col></Col>
                    <Col></Col>
                    <Col></Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col>
                      <Field
                        name="katalog"
                        inputprops={{
                          name: "katalog",
                          id: "katalog",
                          options: katalog,
                        }}
                        label="Katalogové cvičení"
                        component={ReactstrapSelect}
                      />
                    </Col>
                    <Col>
                      <Field
                        name="exerciseMode"
                        inputprops={{
                          name: "exerciseMode",
                          id: "exerciseMode",
                          options: exerciseMode,
                        }}
                        label="Způsob práce"
                        component={ReactstrapSelect}
                      />
                    </Col>
                    <Col>
                      <Field
                        name="done"
                        inputprops={{
                          name: "done",
                          id: "done",
                          options: [
                            { id: 1, name: "dokončeno" },
                            { id: 0, name: "nedokončeno" },
                          ],
                        }}
                        label="Stav"
                        component={ReactstrapSelect}
                      />
                    </Col>
                    <Col>
                      <Field
                        name="lmsHash"
                        inputprops={{
                          name: "lmsHash",
                          id: "lmsHash",
                          options: [
                            { id: 1, name: "má" },
                            { id: 0, name: "nemá" },
                          ],
                        }}
                        label="LMS hash kód"
                        component={ReactstrapSelect}
                      />
                    </Col>
                    <Col>
                      <Field
                        label="From"
                        name="from"
                        id="from"
                        component={ReactstrapInput}
                      />
                    </Col>
                  </FormGroup>
                  <Button
                    color="success"
                    size="sm"
                    type="submit"
                    disabled={isLoading}
                  >
                    <FontAwesomeIcon icon="filter" /> Filtrovat
                  </Button>{" "}
                  <Button
                    target="_blank"
                    rel="noopener noreferrer"
                    color="secondary"
                    size="sm"
                    name="export"
                    disabled={isExporting}
                    onClick={e => {
                      that.setState(
                        { type: "export", includeNotes: false },
                        () => props.submitForm()
                      );
                    }}
                  >
                    Exportovat
                  </Button>{" "}
                  <Button
                    target="_blank"
                    rel="noopener noreferrer"
                    color="secondary"
                    size="sm"
                    name="export"
                    disabled={isExporting}
                    onClick={e => {
                      that.setState(
                        { type: "export", includeNotes: true },
                        () => props.submitForm()
                      );
                    }}
                  >
                    Exportovat s poznámkami
                  </Button>
                </Form>
              );
            }}
          </Formik>
        </Container>
      </div>
    );
  }
}

export default DataFilter;
