import React from "react";
import { Trans } from "react-i18next";
import {
  TabContent,
  TabPane,
  Nav,
  Row,
  Col,
  Button,
  ButtonGroup,
  NavItem,
  NavLink,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import withEverything from "./../../../withEverything";
import Submissions from "./../components/Submissions";
import { If } from "./../../../If";

class ResearchDetail extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoading: false,
      emailAddresses: "",
      error: null,
      pending: false,
      modal: false,
      exercise: {},
      activeTab: "1",
      deleteExercise: {
        isOpen: false,
      },
      addEmailAddresses: {
        isOpen: false,
      },
    };
  }

  toggleTab(tab) {
    this.setState({
      activeTab: tab.toString(),
    });
  }

  render() {
    const { enrolments, unlock, lock } = this.props;

    const hasEmailAddresses =
      enrolments
        .map(e => e.emailAddresses.length)
        .reduce((sum, c) => sum + c, 0) > 0;

    const isLocked = enrolments
      .map(e => e.isLocked)
      .reduce((acc, c) => acc & c, true);

    const isEnabled = this.props.researchId * 1 !== 1;

    return (
      <Row>
        <Col xs="auto" className="menu--vertical">
          <Nav pills vertical>
            <NavItem>
              <NavLink
                className={classnames({
                  active: this.state.activeTab === "1",
                })}
                onClick={() => {
                  this.toggleTab(1);
                }}
              >
                <Trans i18nKey="exerciseDetail.submitted">
                  Odevzdaná cvičení
                </Trans>

                {this.state.activeTab === "1" && (
                  <FontAwesomeIcon className="ml-2" icon="angle-right" />
                )}
              </NavLink>
            </NavItem>
          </Nav>

          <hr />

          <ButtonGroup vertical>
            {isEnabled && isLocked === 1 && (
              <Button onClick={unlock} color="link" data-tut="unlockEnrolment">
                <FontAwesomeIcon className="mr-1" icon="lock-open" />
                <Trans i18nKey="exerciseDetail.unlockActivity">
                  Odemknout cvičení
                </Trans>
              </Button>
            )}
            {isEnabled && isLocked === 0 && (
              <Button onClick={lock} color="link" data-tut="lockEnrolment">
                <FontAwesomeIcon className="mr-1" icon="lock" />
                <Trans i18nKey="exerciseDetail.lockActivity">
                  Odemknout cvičení
                </Trans>
              </Button>
            )}
            <If condition={hasEmailAddresses && isEnabled}>
              <Button
                onClick={this.props.sendExerciseToReceipients}
                color="link"
                data-tut="sendExerciseToReceipients"
              >
                <FontAwesomeIcon icon="envelope" className="mr-1" />
                <Trans i18nKey="exerciseDetail.sendEmail">
                  Odeslat e-mail se zadáním
                </Trans>
              </Button>
            </If>
            {isLocked === 0 &&
              this.props.linkTeacherQuestionnaire !== "" &&
              isEnabled && (
                <Button
                  href={this.props.linkTeacherQuestionnaire}
                  color="link"
                  data-tut="linkTeacherQuestionnaire"
                >
                  <FontAwesomeIcon className="mr-1" icon="clipboard-list" />
                  <Trans i18nKey="exerciseDetail.linkTeacherQuestionnaire">
                    Učitelský dotazník
                  </Trans>
                </Button>
              )}
          </ButtonGroup>
        </Col>

        <Col>
          <TabContent activeTab="1">
            <TabPane tabId="1">
              <Submissions enrolment={enrolments[0]} />
              <Submissions enrolment={enrolments[1]} />
            </TabPane>
          </TabContent>
        </Col>
      </Row>
    );
  }
}

export default withEverything(ResearchDetail);
