import React from "react";
import { If, Else } from "./../If";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

class Pager extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sort: null
    };
  }

  onClick = () => {
    let sort = this.state.sort;
    if (sort === "desc" || sort === "" || sort === null) {
      sort = "asc";
    } else if (sort === "asc") {
      sort = "desc";
    }
    this.setState({ sort }, () => {
      this.props.onSortChanged({
        field: this.props.field,
        sort: this.state.sort
      });
    });
  };

  componentWillReceiveProps = nextProps => {
    if (nextProps.sortedBy && nextProps.sortedBy.field !== this.props.field) {
      this.setState({ sort: null });
    }
  };

  render() {
    const { paging, onPageChanged, page } = this.props;
    if (paging.pageCount === 1) {
      return <div>Celkem {paging.total}</div>;
    }
    const pageNumbers = [];
    let p = page - 5;
    if (p > 1) {
      pageNumbers.push(1);
    }
    for (var i = p; i < page + 5; i++) {
      if (i > 0 && i < paging.pageCount) {
        pageNumbers.push(i);
      }
    }

    for (var j = 0; j < 5; j++) {
      pageNumbers.push();
    }
    pageNumbers.push(paging.pageCount);

    return (
      <div>
        <div>Celkem {paging.total}</div>
        <Pagination aria-label="Page navigation example">
          {page > 1 && (
            <PaginationItem key="prev">
              <PaginationLink previous onClick={e => onPageChanged(page - 1)} />
            </PaginationItem>
          )}
          {pageNumbers.map(n => (
            <PaginationItem key={`page${n}`}>
              <PaginationLink onClick={e => onPageChanged(n)}>
                <If condition={n === page}>
                  <b>{n}</b>
                  <Else>{n}</Else>
                </If>
              </PaginationLink>
            </PaginationItem>
          ))}
          {page < paging.pageCount && (
            <PaginationItem key="next">
              <PaginationLink next onClick={e => onPageChanged(page + 1)} />
            </PaginationItem>
          )}
        </Pagination>
      </div>
    );
  }
}

export default Pager;
