import React from "react";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Trans } from "react-i18next";
import {
  TabContent,
  TabPane,
  Nav,
  Row,
  Col,
  Button,
  ButtonGroup,
  NavItem,
  NavLink,
} from "reactstrap";
import ClassForm from "../components/ClassForm";
import EmailAddressesDialog from "../../exercises/EmailAddressesDialog";
import EmailAddresses from "../../exercises/EmailAddresses";
import "./GroupDetail.scss";

const GroupDetail = ({
  group,
  activeTab,
  groupUpdate,
  toggleTab,
  isLoading,
  addEmailAddressToGroup,
  deleteEmailAddressFromGroup,
  toggleAddEmailAddresses,
  addEmailAddresses,
  addEmailAddressesIsOpen,
  toggleDeleteConfirmation,
  user
}) => {
  const isNew = !(group && group.id);
  return (
    <div className="GroupDetail">
      <Row>
        <Col xs="auto">
          <Nav pills vertical>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "1",
                })}
                onClick={() => toggleTab(1)}
              >
                <Trans i18nKey="classEdit.settings">Nastavení třídy</Trans>
                {activeTab === "1" && (
                  <FontAwesomeIcon className="ml-2" icon="angle-right" />
                )}
              </NavLink>
            </NavItem>
            {!isNew && (
              <NavItem>
                <NavLink
                  data-tut="classMembers"
                  className={classnames({
                    active: activeTab === "2",
                  })}
                  onClick={() => toggleTab(2)}
                >
                  <Trans i18nKey="exerciseDetail.classMembers">
                    Členové třídy
                  </Trans>
                  {activeTab === "2" && (
                    <FontAwesomeIcon className="ml-2" icon="angle-right" />
                  )}
                </NavLink>
              </NavItem>
            )}
          </Nav>

          <hr />

          <ButtonGroup vertical>
            {activeTab === "1" && (
              <Button
                color="link"
                className="text-danger"
                onClick={toggleDeleteConfirmation}
                data-tut="removeClass"
                data-cy={"group-remove-bt"}
              >
                <FontAwesomeIcon className="mr-1" icon={["fas", "trash-alt"]} />
                <Trans i18nKey="classEdit.removeClass">Smazat třídu</Trans>
              </Button>
            )}

            {activeTab === "2" && (
              <Button
                color="link"
                onClick={toggleAddEmailAddresses}
                id="btnAddEmailAddressToGroup"
              >
                <FontAwesomeIcon className="mr-1" icon="user-plus" />
                <Trans i18nKey="exerciseDetail.addEmailAddresses">
                  Přidat žáky
                </Trans>
                <EmailAddressesDialog
                  exercise={group}
                  onConfirmed={addEmailAddresses}
                  isOpen={addEmailAddressesIsOpen}
                  toggle={toggleAddEmailAddresses}
                />
              </Button>
            )}
          </ButtonGroup>
        </Col>
        <Col>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <ClassForm
                isLoading={isLoading}
                onSubmit={groupUpdate}
                group={group}
                user={user}
              />
            </TabPane>
            {group && group.emailAddresses && (
              <TabPane tabId="2">
                <EmailAddresses
                  emailAddresses={group.emailAddresses}
                  addEmailAddress={addEmailAddressToGroup}
                  deleteEmailAddress={deleteEmailAddressFromGroup}
                />
              </TabPane>
            )}
          </TabContent>
        </Col>
      </Row>
    </div>
  );
};

export default GroupDetail;
