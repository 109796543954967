import React from "react";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";
import { Badge, Breadcrumb, BreadcrumbItem, Row, Col } from "reactstrap";
import DataService from "./../services/DataService";
import Login from "./../../../Auth/Login";
import { ApplicationStateConsumer } from "./../../../ApplicationStateProvider";
import withEverything from "./../../../withEverything";
import Layout from "./../../../Layout";
import "./ResearchDetailPage.scss";
import ResearchDetail from "../components/ResearchDetail";

class ResearchDetailPage extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoading: false,
      error: null,
      pending: false,
      group: {},
      enrolments: [],
      activeTab: "1",
      linkTeacherQuestionnaire: "",
    };
  }

  refresh = () => {
    this.setState({ isLoading: true });
    const { classId, researchId } = this.props.match.params;
    new DataService()
      .fetchResearch(classId, researchId)
      .then(result => {
        this.setState({
          group: result.data.group,
          enrolments: result.data.enrolments,
          isLoading: false,
          linkTeacherQuestionnaire: result.data.linkTeacherQuestionnaire || "",
        });
        return result;
      })
      .then(this.props.processNotifications)
      .catch(this.onError);
  };

  onError = error => {
    this.setState({
      error,
      isLoading: false,
    });
    this.props.notify(error);
  };

  sendExerciseToReceipients = researchId => {
    const { group } = this.state;
    new DataService()
      .sendExerciseToReceipients(group.id, researchId)
      .then(data => {
        this.props.processNotifications({
          notifications: data.notifications.concat(data.data.notifications),
        });
      })
      .catch(error => {
        this.onError(error);
      });
  };

  lock = () => {
    const { group } = this.state;
    const { researchId } = this.props.match.params;

    new DataService()
      .lock(group.id, researchId)
      .then(data => {
        this.props.processNotifications({
          notifications: data.notifications.concat(data.data.notifications),
        });
        this.setState({
          enrolments: this.state.enrolments.map(e => {
            return { ...e, isLocked: true };
          }),
        });
      })
      .catch(error => {
        this.onError(error);
      });
  };

  unlock = () => {
    const { group } = this.state;
    const { researchId } = this.props.match.params;

    new DataService()
      .unlock(group.id, researchId)
      .then(data => {
        this.props.processNotifications({
          notifications: data.notifications.concat(data.data.notifications),
        });
        this.setState({
          enrolments: this.state.enrolments.map(e => {
            return { ...e, isLocked: false };
          }),
        });
      })
      .catch(error => {
        this.onError(error);
      });
  };

  componentDidMount() {
    this.refresh();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.authenticated && this.props.authenticated) {
      this.refresh();
    }
  }

  toggleTab(tab) {
    this.setState({
      activeTab: tab.toString(),
    });
  }

  render() {
    const { enrolments, isLoading, group } = this.state;
    const { t } = this.props;
    const title = isLoading ? "..." : "Průzkumné cvičení Dějepis+";

    const breadcrumb = (
      <>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/tridy">
              <Trans i18nKey="mainHeader.myClasses">Moje třídy</Trans>
            </Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to={"/tridy/" + (group ? group.id : "")}>
              {t("exerciseList.class", "Třída") +
                (group ? ": " + group.name : "")}
            </Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>
            <Trans i18nKey="exerciseDetail.assignedExercise">
              Zadané cvičení
            </Trans>
          </BreadcrumbItem>
        </Breadcrumb>
      </>
    );

    const subHeader = (authenticated, isLoading) => {
      if (authenticated && !isLoading && group && group.id) {
        return (
          <div className="ExerciseDetail__info">
            <Badge pill color="light">
              {group.name}
            </Badge>
            <Badge pill color="info">
              <Trans i18nKey={"catalogExerciseDetail.individual"}></Trans>
            </Badge>
          </div>
        );
      }
    };

    const { researchId } = this.props.match.params;

    return (
      <ApplicationStateConsumer>
        {({ authenticated, login }) => (
          <Layout
            className="ExerciseDetail"
            title={title}
            subHeader={subHeader(authenticated, isLoading, enrolments)}
            breadcrumb={breadcrumb}
          >
            {!authenticated && (
              <Layout.Content>
                <Row>
                  <Col>
                    <h1>
                      <Trans i18nKey="signIn">Přihlašte se</Trans>
                    </h1>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Login login={login} />
                  </Col>
                </Row>
              </Layout.Content>
            )}

            {authenticated && isLoading && (
              <Layout.Content>
                <Row>
                  <Col>
                    <h1>
                      <Trans i18nKey="exerciseDetail.pleaseWait">
                        Počkejte, cvičení se načítá ...
                      </Trans>
                    </h1>
                  </Col>
                </Row>
              </Layout.Content>
            )}

            {authenticated && !isLoading && (
              <Layout.Content>
                <ResearchDetail
                  enrolments={enrolments}
                  unlock={this.unlock}
                  lock={this.lock}
                  researchId={researchId}
                  sendExerciseToReceipients={() =>
                    this.sendExerciseToReceipients(researchId)
                  }
                  linkTeacherQuestionnaire={this.state.linkTeacherQuestionnaire}
                />
              </Layout.Content>
            )}
          </Layout>
        )}
      </ApplicationStateConsumer>
    );
  }
}

export default withEverything(ResearchDetailPage);
