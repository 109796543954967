import axios from "axios";

class AuthService {
  onGoogleAuthSuccess(data) {
    const options = {
      method: "POST",
      data,
      url: "/api/auth/google",
    };
    return axios(options).then(this.extractDataAndNotifications);
  }

  extractDataAndNotifications(result) {
    const { data } = result;
    if (result.headers["x-hl-flash"]) {
      const flash = JSON.parse(
        decodeURIComponent(window.atob(result.headers["x-hl-flash"]))
      );
      return { data, notifications: flash || [] };
    }
    return { data, notifications: [] };
  }
}

export default AuthService;
