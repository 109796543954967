import React from "react";
import { Table, Button } from "reactstrap";
import { If, Else } from "./../If";
import DeleteEmailDialog from "./../groups/DeleteEmailDialog";
import { withTranslation, Trans } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class EmailAddresses extends React.Component {
  constructor() {
    super();

    this.dismissCallback = null;

    this.state = {
      modal: false,
      emailAddress: {}
    };
  }

  toggle = () => {
    this.setState({
      modal: false
    });
  };

  confirmDeleteEmailAddress = (e, emailAddress) => {
    this.setState({
      modal: true,
      emailAddress: emailAddress
    });
  };

  deleteEmailAddress = () => {
    const { emailAddress } = this.state;
    const { deleteEmailAddress } = this.props;
    this.toggle();
    deleteEmailAddress(emailAddress);
  };

  render() {
    const { emailAddresses } = this.props;

    return (
      <Table striped borderless>
        <thead>
          <tr>
            <th>#</th>
            <th>
              <Trans i18nKey="emailAddresses.columnHeader">
                E-mailová adresa
              </Trans>
            </th>
            <th>
              <Trans i18nKey="emailAddresses.name">Jméno</Trans>
            </th>
            <th>…</th>
          </tr>
        </thead>
        <tbody>
          {emailAddresses.map((emailAddress, index) => {
            const belongsToGroup = emailAddress.group_id > 0;
            return (
              <tr key={index}>
                <th scope="row">{index + 1}</th>
                <td>{emailAddress.emailAddress}</td>
                <td>{emailAddress.name}</td>
                <td>
                  <If condition={!belongsToGroup}>
                    <Button
                      outline
                      color="primary"
                      size="sm"
                      onClick={_ => this.props.addEmailAddress(emailAddress)}
                    >
                      <FontAwesomeIcon className="mr-1" icon="user-plus" />
                      <Trans i18nKey="emailAddresses.addToClass">
                        Přidat do třídy
                      </Trans>
                    </Button>
                    <Else>
                      <Button
                        outline
                        color="danger"
                        size="sm"
                        onClick={e =>
                          this.confirmDeleteEmailAddress(e, emailAddress)
                        }
                        id={"warning-icon-" + index}
                      >
                        {/*!emailAddress.hasSubmissions && (
                          <span>
                            <FontAwesomeIcon
                              icon="exclamation-triangle"
                              className="mr-1"
                              // color="red"
                            />
                            <UncontrolledTooltip
                              placement="right"
                              target={"warning-icon-" + index}
                            >
                              Pro tuto emailovou adresu nejsou v systému
                              evidována žádná odevzdaná cvičení. Smazáním se
                              emailová adresa kompletně vymaže ze systému.
                            </UncontrolledTooltip>
                          </span>
                        )*/}
                        <Trans i18nKey="emailAddresses.removeFromClass">
                          Odebrat ze třídy
                        </Trans>
                      </Button>
                      {/*!emailAddress.hasSubmissions && (
                        <>
                          <FontAwesomeIcon
                            id={"warning-icon-" + index}
                            icon="exclamation-triangle"
                            color="red"
                          />
                          <UncontrolledTooltip
                            placement="right"
                            target={"warning-icon-" + index}
                          >
                            Pro tuto emailovou adresu nejsou v systému evidována
                            žádná odevzdaná cvičení. Smazáním se emailová adresa
                            kompletně vymaže ze systému.
                          </UncontrolledTooltip>
                        </>
                      )*/}
                    </Else>
                  </If>
                </td>
              </tr>
            );
          })}
        </tbody>
        <DeleteEmailDialog
          isOpen={this.state.modal}
          emailAddress={this.state.emailAddress}
          toggle={this.toggle}
          onConfirmed={this.deleteEmailAddress}
        />
      </Table>
    );
  }
}

export default withTranslation()(EmailAddresses);
