import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { withTranslation, Trans } from "react-i18next";

const DeleteExerciseDialog = ({ isOpen, toggle, onConfirmed }) => (
  <Modal isOpen={isOpen} toggle={toggle} backdrop>
    <ModalHeader toggle={toggle}>
      <Trans i18nKey="deleteExerciseDialog.header" />
    </ModalHeader>
    <ModalBody>
      <Trans i18nKey="deleteExerciseDialog.confirmation" />
    </ModalBody>
    <ModalFooter>
      <Button color="primary" onClick={onConfirmed}>
        <Trans i18nKey="deleteExerciseDialog.yes" />
      </Button>
      <Button color="secondary" onClick={toggle}>
        <Trans i18nKey="no" />
      </Button>
    </ModalFooter>
  </Modal>
);

export default withTranslation()(DeleteExerciseDialog);
