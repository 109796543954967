import React from "react";
import i18n from "i18next";
import { withTranslation } from "react-i18next";
import RegistrationForm from "./RegistrationForm";
import DataService from "./../DataService";
import withNotification from "./../withNotification";
import withTour from "../withTour";
import Layout from "../Layout";

class Registration extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isPending: false };
  }

  onSubmit = (registrationData, callback) => {
    this.setState({ error: null, isPending: true });
    registrationData.language = (i18n.language || "").toLowerCase();
    new DataService()
      .registerUser(registrationData)
      .then(result => {
        console.info("done", result);
        const data = result.data;
        this.setState({
          isPending: false,
        });

        if (data && data.error) {
          if (!callback(data.error)) {
            this.onError(data.error);
          }
          return result;
        }
        this.props.history.push("/moje");
        return result;
      })
      .then(this.props.processNotifications)
      .catch(this.onError);
  };

  onError = error => {
    this.setState({
      error,
      isPending: false,
    });

    this.props.notify(error);
  };

  render() {
    const { isPending } = this.state;
    return (
      <Layout
        title={this.props.t("signUp.header", "Registrace učitele")}
        isTitleCentered={true}
        isFullWidth={false}
        // className="Catalog"
      >
        <Layout.Content>
          <div className="text-left">
            <RegistrationForm onSubmit={this.onSubmit} isPending={isPending} />
          </div>
        </Layout.Content>
      </Layout>
    );
  }
}

export default withTranslation()(withNotification(withTour(Registration)));
