/**
 * Class which provide sorting exercises. Is used in component FilerBar.
 */
class SortExercises {
  /**
   * Helper function to decide which exercise have all the selected kw from selects.
   *
   * @param metadataKw - kw which are in exercise metadata (in klicovaSlova)
   * @param filterParameterKW - list of kw which are selected for sorting
   * @returns {boolean} - returns if exercise meets all the requirements
   */
  keywordsSort(metadataKw, filterParameterKW) {
    let result = true;
    Object.entries(metadataKw).forEach(entry => {
      const [nazev, polozky] = entry;
      if (!filterParameterKW[nazev]) {
        return;
      }
      result = result && polozky.some(fKw => fKw === filterParameterKW[nazev]);
    });
    return result;
  }

  /**
   * Main function to filter exercise by text, oreder and selected kw.
   *
   * @param exercises - list of all exercises
   * @param filterPar - object which contain parameters for sorting
   *
   * @returns {object} fileredResult - returns all exercise which meet all the requirements.
   */
  sortExercises(exercises, filterPar) {
    if (exercises === undefined || exercises.length === 0) return exercises;
    let filteredResult = exercises;

    // Filtering by text
    if (filterPar.text.length >= 3) {
      filteredResult = exercises.filter(exercise => {
        const nameLowerCase = exercise.metadata.nazev.toLowerCase();
        const description = exercise.description.toLowerCase();
        return (
          nameLowerCase.includes(filterPar.text.toLowerCase()) ||
          description.includes(filterPar.text.toLowerCase())
        );
      });
    }

    // Filtering by kw
    filteredResult = filteredResult.filter(exercise =>
      this.keywordsSort(exercise.metadata.klicovaSlova, filterPar.keywords)
    );

    // Filtering by order - ASC / DECS
    // if (filterPar.order === "ASC") {
    //   filteredResult.sort((exerciseA, exerciseB) =>
    //     exerciseA.metadata.nazev.localeCompare(exerciseB.metadata.nazev)
    //   );
    // } else {
    //   filteredResult.sort((exerciseA, exerciseB) =>
    //     exerciseB.metadata.nazev.localeCompare(exerciseA.metadata.nazev)
    //   );
    // }

    // Filter by sortButton
    switch (filterPar.sorting.type) {
      case "time":
        if (filterPar.sorting.direction) {
          filteredResult.sort(
            (exerciseA, exerciseB) =>
              exerciseB.metadata.trvani - exerciseA.metadata.trvani
          );
        } else {
          filteredResult.sort(
            (exerciseA, exerciseB) =>
              exerciseA.metadata.trvani - exerciseB.metadata.trvani
          );
        }
        break;
      case "difficulty":
        if (filterPar.sorting.direction) {
          filteredResult.sort((exerciseA, exerciseB) =>
            exerciseB.metadata.obtiznost.localeCompare(
              exerciseA.metadata.obtiznost
            )
          );
        } else {
          filteredResult.sort((exerciseA, exerciseB) =>
            exerciseA.metadata.obtiznost.localeCompare(
              exerciseB.metadata.obtiznost
            )
          );
        }
        break;
      default:
        filteredResult.sort(
          (exerciseA, exerciseB) =>
            exerciseA.metadata.trvani - exerciseB.metadata.trvani
        );
    }

    return filteredResult;
  }
}

export default SortExercises;
