import React from "react";
import classnames from "classnames";
import { BulletList } from "react-content-loader";
import { Trans } from "react-i18next";
import { ButtonToggle, ButtonGroup } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArchive } from "@fortawesome/free-solid-svg-icons";
import "./GroupSelector.scss";

const GroupSelector = ({ loading, groups, onGroupSelected, selectedId, t }) => {
  if (loading) {
    return (
      <div className="groupSelector">
        <BulletList />
      </div>
    );
  }

  if (groups.length === 0) {
    return (
      <div className="groupSelector">
        <p>
          <Trans i18nkey="groupSelector.noClasses">
            Nemáte žádné třídy k dispozici. Založte novou třídu pomocí formuláře
            vpravo.
          </Trans>
        </p>
      </div>
    );
  }

  return (
    <div
      className={`groupSelector ${
        groups.length > 5 ? "groupSelector--scroll" : ""
      }`}
    >
      {loading && <BulletList />}
      {!loading && (
        <ButtonGroup
          vertical
          className="groupSelector__list"
          data-cy="chose-group-modal-bt-toggle"
        >
          {groups.map((group, index) => (
            <ButtonToggle
              color="outline-secondary"
              className={classnames({
                groupSelector__item: true,
                "groupSelector__item--selected": group.id === selectedId,
              })}
              key={index}
              onClick={() => onGroupSelected(group)}
            >
              {group.color && (
                <FontAwesomeIcon icon="square" color={group.color} />
              )}
              {!group.color && <FontAwesomeIcon icon={["far", "square"]} />}
              <span className="groupSelector__name">{group.name}</span>
              <span className="groupSelector__year">
                {`${group.yearStart} – ${group.yearEnd}`}
              </span>
              {group.isArchived && (
                <FontAwesomeIcon
                  className="groupSelector__year"
                  icon={faArchive}
                />
              )}
              {!group.isArchived && group.isFavourite && (
                <FontAwesomeIcon
                  className="groupSelector__year"
                  icon={["far", "star"]}
                />
              )}
            </ButtonToggle>
          ))}
        </ButtonGroup>
      )}
    </div>
  );
};

export default GroupSelector;
