import * as Yup from "yup";
import i18n from "i18next";

function setYupLocale(formName) {
  function localize(key) {
    return i18n.t(key) || key;
  }

  Yup.setLocale({
    string: {
      matches: ({ path, type, value, originalValue }) =>
        localize(`${formName}.${path}.matches`),
      email: ({ path, type, value, originalValue }) =>
        localize(`${formName}.${path}.email`),
      min: params =>
        Yup.ValidationError.formatError(localize(`string.min`), params),
      max: params =>
        Yup.ValidationError.formatError(localize(`string.max`), params),
    },
    number: {
      moreThan: params =>
        Yup.ValidationError.formatError(
          localize(`${formName}.${params.path}.moreThan`),
          params
        ),
      lessThan: params =>
        Yup.ValidationError.formatError(
          localize(`${formName}.${params.path}.lessThan`),
          params
        ),
      test: ({ path, type, value, originalValue }) =>
        localize(`${formName}.${path}.lessThan`),
    },
    mixed: {
      required: ({ path, type, value, originalValue }) =>
        localize(`${formName}.${path}.required`),
      oneOf: params => i18n.t(`${formName}.${params.path}.oneOf`),
      default: ({ path, type, value, originalValue }) =>
        localize(`${formName}.${path}.lessThan`),
    },
  });
}

export default setYupLocale;
