import React from "react";
import * as Yup from "yup";
import { withTranslation } from "react-i18next";
import i18n from "i18next";
import withNotification from "../withNotification";
import Layout from "../Layout";
import { Field, Form, Formik } from "formik";
import {
  Col,
  Container,
  FormGroup,
  Label,
  Row,
  FormFeedback,
  Button,
} from "reactstrap";
import { ReactstrapInput } from "reactstrap-formik";
import DataService from "../DataService";
import Dropzone from "./Dropzone";
import ReCaptcha from "../Auth/Recaptcha";
import setYupLocale from "../yup";
import { detect } from "detect-browser";
import "./bugFrom.scss";

setYupLocale("helpdesk");

const BugSchema = Yup.object().shape({
  name: Yup.string().max(50),
  emailAddress: Yup.string().email().required(),
  type: Yup.string(),
  description: Yup.string().max(4096),
  files: Yup.array().of(Yup.mixed())
});

const TypeRadioInput = ({
  t,
  field,
  form: { touched, errors },
  disabled = false,
  ...props
}) => {
  const error = errors[field.name];
  const touch = touched[field.name];
  return (
    <FormGroup>
      <p className="bugType-radio__title">{props.label}</p>
      <div> 
        <Field id="bugType1" type="radio" name="type" value="administration" />
        <label htmlFor="bugType1">{t("helpdesk.bugForm.administrationType")}</label>
      </div>
      <div>
        <Field id="bugType2" type="radio" name="type" value="excercises" />
        <label htmlFor="bugType2">{t("helpdesk.bugForm.excerciseType")}</label>
      </div>
      <div>
        <Field id="bugType3" type="radio" name="type" value="unknow"/>
        <label htmlFor="bugType3">{t("helpdesk.bugForm.unkownType", "nevím")}</label>
      </div>
      {touch && error && <FormFeedback style={{display: 'block'}}>{error}</FormFeedback>}
    </FormGroup>
  );
};

class BugForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      pending: false,
      modal: false,
      error: null,
      currentBreakpoint: "lg",
      mounted: false,
    };
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };
  refresh = lng => {
    if (!lng) {
      lng = i18n.language;
    }
  };
  onError = error => {
    this.setState({
      error,
      isPending: false
    });

    this.props.notify(error);
  };

  componentDidMount() {
    this.refresh();
    this.setState({ mounted: true });
    const that = this;
    i18n.on("languageChanged", function (lng) {
      that.refresh(lng);
    });
  }

  convertImg = file => new Promise( resolve => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const image = new Image();
    image.onload = function() {

      canvas.width = image.width;
      canvas.height = image.height;

      ctx.drawImage(image, 0, 0);

      const mediumQuality = canvas.toDataURL('image/jpeg', 0.8);

      resolve(mediumQuality)
    };
    image.src = file.base64;
  });


  render() {
    const { t } = this.props;
    return (
      <Layout
        title={t("helpdesk.bugForm.title")}
        isTitleCentered={true}
        isFullWidth={false}
      >
        <Layout.Content>
        {!this.state.isLoading ? (
          <>
            <div className="m-2 mb-3">
              <p className="mb-1">{t("helpdesk.bugForm.p1")}</p>
              <p>{t("helpdesk.bugForm.p2")}</p>
            </div>  
            <Formik
              initialValues={{
                name: "",
                emailAddress: "",
                troubleName: "",
                description: "",
                type: "",
                files: "",
                recaptcha: "",
              }}
              validationSchema={BugSchema}
              onSubmit={async (values) => {
                // wait until all will be done
                this.setState({ isLoading: true });
                // add browser info
                const browser = detect();
                if (browser) {
                  values.os = browser.os;
                  values.browser = browser.name + ' ' + browser.version;
                } else {
                  values.os = 'undefinated';
                  values.browser = 'undefinated';
                }

                // add harcoded type
                values.server = window.location.origin;

                // console.log('form data', values)
                
                
                const files = values.files;
                values.files = [];
                for (let i = 0; i < files.length; i++) {
                  // convert to jpg
                  let base64String = await this.convertImg(files[i]);
                  // big trouble!!! it is important to remove data type definition
                  const regexRemoveTypeImgType = /([a-zA-Z0-9;/:]+),/g;
                  base64String = base64String.replace(regexRemoveTypeImgType, "");
                  // rename img to jpg
                  const regex = /\.[a-zA-Z]+/g;
                  const newFileName = files[i].name.replace(regex, ".jpg");

                  values.files.push({
                    name: newFileName,
                    content: base64String
                  });
                }

                new DataService()
                  .postHelpdeskBug(values)
                  .then(this.props.processNotifications)
                  .then(() => {
                    this.setState({ isLoading: false });
                    this.props.notify({type: 'success', text: t("helpdesk.bugForm.success")});
                    this.props.history.push("/podpora");
                  })
                  .catch(error => {
                    this.onError(error);
                  });
              }}
              render={aa => {
                const { errors, touched, values, setFieldValue } = aa;
                return (
                  <Form>
                    <Container style={{ paddingTop: "5px" }}>
                      <Row>
                        <Col>
                          <Field
                            label={t("helpdesk.bugForm.name")}
                            name="name"
                            id="name"
                            component={ReactstrapInput}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Field
                            type="email"
                            label={t("helpdesk.bugForm.emailAddress")}
                            name="emailAddress"
                            id="emailAddress"
                            component={ReactstrapInput}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Field
                            t={t}
                            label={t("helpdesk.bugForm.bugType")}
                            name="type"
                            id="type"
                            component={TypeRadioInput}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mb-3">
                          <Label for="description">{t("helpdesk.helpForm.description")}</Label>
                          <Field
                            className={`w-100 form-control ${errors.description && touched.description ? "is-invalid" : ""}`}
                            name="description"
                            id="description"
                            placeholder={t("helpdesk.helpForm.descriptionHelp")}
                            component="textarea" 
                            rows="4"
                          />
                          {errors.description && touched.description ? (
                            <div className="invalid-feedback">{errors.description}</div>
                          ) : null}
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Label for="files">{t("helpdesk.bugForm.additionalPictures")}</Label>
                          <Field
                            name="files"
                            id="files"
                            component={Dropzone}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col style={{ display: "flex", justifyContent: "center" }}>
                          <ReCaptcha
                            ref={ref => (this.recaptcha = ref)}
                            onVerificationCallback={key =>
                              setFieldValue("recaptcha", key)
                            }
                          />
                        </Col>
                      </Row>
                      <Row className="mt-2 text-center">
                        <Col style={{ display: "flex", justifyContent: "center" }}>
                          <Button
                            type="submit"
                            className="mt-1"
                            disabled={values.recaptcha === ""}
                          >
                            {t("helpdesk.bugForm.send")}
                          </Button>
                        </Col>
                      </Row>
                    </Container>
                  </Form>
                );
              }}
            />
          </>
          ) : <div className="text-center">{this.props.t("helpdesk.wait")}</div> }
        </Layout.Content>
      </Layout>
    );
  }
}

export default withTranslation()(withNotification(BugForm));