import React from "react";
import { Trans } from "react-i18next";
import LoginForm from "./LoginForm";
import Layout from "../Layout";

const Login = ({ login, onAuthSuccess, onAuthFailure, redirect }) => {
  const title = <Trans i18nKey="login.header">Přihlašte se</Trans>;

  return (
    <Layout title={title} isTitleCentered isFullWidth={false}>
      <Layout.Content>
        <LoginForm
          login={login}
          redirect={redirect}
          onAuthSuccess={onAuthSuccess}
          onAuthFailure={onAuthFailure}
        />
      </Layout.Content>
    </Layout>
  );
};

export default Login;
