import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as LogoCro } from "../../icons/logos/cro.svg";
import "./Logos.scss";

// TODO: make MySVG or Logo component with name prop
const Logos = ({ logos, heading }) => {
  if (logos.length > 0) {
    return (
      <div className="Logos">
        {heading && <h5 className="Logos__heading">{heading}</h5>}

        {logos.map(logo => (
          <div className="Logos__item">{logo === "cro" && <LogoCro />}</div>
        ))}
      </div>
    );
  }

  return;
};

Logos.propTypes = {
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  logos: PropTypes.array.isRequired
};

export default Logos;
