import React from "react";
import { withTranslation } from "react-i18next";
import slugify from "slugify";
import withNotification from "../withNotification";
import TimelineExerciseDetail from "./TimelineExerciseDetail";
import HLCard from "../components/Card/HLCard";
import "./scss/TimelineExercises.scss";

class Exercise extends React.Component {
  state = {
    exerciseDetail: {
      visibility: false,
      data: "",
      scroll: ""
    },
    error: null,
    timelineDetailData: this.props.timelineDetailData,
    exercise: {},
    mounted: false,
    allExercises: [],
    exercisesInEpochs: [],
    exerciseInDecades: []
  };

  constructor(props) {
    super(props);
    this.slugifyConfig = { lower: true, strict: true };
    
    this.handleScroll = this.handleScroll.bind(this);
    this.handleOnClickExercise = this.handleOnClickExercise.bind(this);
  }

  componentDidMount() {
    this.setState({ mounted: true });
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll() {
    if (!this.state.exerciseDetail.visibility) {
      return;
    }
    this.handleActivityDetailEvent();
  }

  componentDidUpdate() {
    if (
      JSON.stringify(this.state.allExercises) !==
      JSON.stringify(this.props.loadedExercises)
    ) {
      this.setState({
        allExercises: this.props.loadedExercises,
        exerciseInDecades: this.makeArrayOfExercisesInDecades(
          this.props.loadedExercises
        ),
        exercisesInEpochs: this.makeArrayOfExercisesInEpochs(
          this.props.loadedExercises
        )
      });
    }
  }

  // TODO:
  // - `data.description` => `anotace.verejna`
  // - does not work why? https://fontawesome.com/icons/angle-right?style=solid
  render() {
    const { t, history } = this.props;
    return (
      <div
        className={
          "timeline__item timeline__exercises " +
          this.state.exerciseDetail.scroll
        }
      >
        <div className={"exercises " + this.state.exerciseDetail.scroll}>
          {this.generateCurrentExercises(this.selectExerciseData())}
        </div>

        <TimelineExerciseDetail
          visibility={this.state.exerciseDetail.visibility}
          exercise={this.state.exerciseDetail.data}
          translation={t}
          handleActivityDetailEvent={this.handleActivityDetailEvent}
          history={history}
        />
      </div>
    );
  }

  /**
   * Sort every exercies into coresponding decades
   */
  makeArrayOfExercisesInDecades(allExercises) {
    const casovaOsaTimeline = this.props.timeSetup;
    let arrayOfExercisesInDecades = [];
    const timelineEnd = casovaOsaTimeline.end + casovaOsaTimeline.step;
    const timelineStart = casovaOsaTimeline.start - casovaOsaTimeline.step;
    const step = casovaOsaTimeline.step;
    const numberOfDecades =
      (timelineEnd - timelineStart) / casovaOsaTimeline.step;
    for (let index = 0; index < numberOfDecades; index++) {
      let arrayOfExercisesSelected = [];
      const decadeStart = timelineStart + step * index;
      const decadeEnd = timelineStart + step * (index + 1) - 1;
      for (let i = 0; i < allExercises.length; i++) {
        const findYear = year => {
          if (index === 0 && year < decadeStart) {
            return true;
          }
          if (index === numberOfDecades - 1 && year > timelineEnd) {
            return true;
          }
          return year >= decadeStart && year <= decadeEnd;
        };
        if (
          allExercises[i].metadata.casovaOsa.roky.find(year => findYear(year))
        ) {
          arrayOfExercisesSelected.push(allExercises[i]);
        }
      }
      arrayOfExercisesInDecades.push(arrayOfExercisesSelected);
    }
    return arrayOfExercisesInDecades;
  }

  handleOnClickExercise(exercise) {
    let exerciseDetail = { ...this.state.exerciseDetail };
    exerciseDetail.visibility = true;
    exerciseDetail.data = exercise;
    exerciseDetail.scroll = "unscroll";
    this.setState({ exerciseDetail });
  }

  handleActivityDetailEvent = () => {
    let exerciseDetail = { ...this.state.exerciseDetail };
    exerciseDetail.visibility = false;
    exerciseDetail.scroll = "";
    this.setState({ exerciseDetail });
  };

  /**
   * Make komponent exercise of all given exercises from server JSON data
   */
  generateCurrentExercises(exercises) {
    return exercises.map(exercise => (
      <HLCard
        key={"tle_" + exercise.metadata.id}
        exercise={exercise}
        handleOnClickExercise={this.handleOnClickExercise}
        image={exercise.thumb_image_url}
        color={`hsl(${exercise.metadata.color.hsv.h}, ${exercise.metadata.color.hsv.s}%, ${exercise.metadata.color.hsv.v - 20}%)`}
        name={exercise.name}
        difficulty={exercise.metadata.obtiznost}
        epochs={exercise.metadata.casovaOsa.epochy}
        years={exercise.metadata.casovaOsa.roky}
        variant="timeline"
      />
    ));
  }

  /**
   * Select specific data to render
   */
  selectExerciseData() {
    let selectedExercise = [];
    if (this.props.currentEpoch.active) {
      const generatedSlug = slugify(
        this.props.currentEpoch.name,
        this.slugifyConfig
      );
      this.state.exercisesInEpochs.forEach(epoch => {
        if (epoch.slug === generatedSlug) {
          selectedExercise = epoch.exercises;
        }
      });
      return selectedExercise.sort(
        (exerciseA, exerciseB) =>
          exerciseA.metadata.casovaOsa.roky[0] -
          exerciseB.metadata.casovaOsa.roky[0]
      );
    }
    this.props.currentDecadeExercises.forEach(decade => {
      if (this.state.exerciseInDecades[decade] === undefined) return; // ONLY FOR THE NOT WORKING ENGLISH THINGS TODO REMOVE
      if (this.state.exerciseInDecades[decade].length !== 0) {
        this.state.exerciseInDecades[decade].forEach(e => {
          if (!selectedExercise.includes(e)) selectedExercise.push(e);
        });
      }
    });
    return selectedExercise.sort(
      (exerciseA, exerciseB) =>
        exerciseA.metadata.casovaOsa.roky[0] -
        exerciseB.metadata.casovaOsa.roky[0]
    );
  }

  /**
   * Make array of epochs with exercises which belongs to coresponding epochs
   */
  makeArrayOfExercisesInEpochs(allExercises) {
    let allEpochs = [];
    if (this.state.timelineDetailData.timelineData === undefined) {
      return;
    }
    this.state.timelineDetailData.timelineData.forEach(position => {
      position.epochs.forEach(epoch => {
        const generatedSlugFromEpoch = slugify(epoch.name, this.slugifyConfig);
        const epochWithExercises = {
          slug: generatedSlugFromEpoch,
          exercises: this.findAllExerciseWithGivenEpoch(
            generatedSlugFromEpoch,
            allExercises
          )
        };
        allEpochs.push(epochWithExercises);
      });
    });
    return allEpochs;
  }

  /**
   *
   * Get array of epoch with exercises by slug of exercise
   *
   * @param {string} slug slug of epoch
   * @param {Array} allExercises all exercises
   */
  findAllExerciseWithGivenEpoch(slug, allExercises) {
    const arrayOfEpochBySlug = [];
    allExercises.forEach(entryExercise => {
      entryExercise.metadata.casovaOsa.epochy.forEach(epocha => {
        if (
          epocha.obdobi.find(
            epochInExercise =>
              slugify(epochInExercise, this.slugifyConfig) === slug
          )
        ) {
          arrayOfEpochBySlug.push(entryExercise);
        }
      });
    });
    return arrayOfEpochBySlug;
  }
}

export default withTranslation()(withNotification(Exercise));
