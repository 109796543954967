import React from "react";
import { ReCaptcha as GoogleReCaptcha } from "react-recaptcha-google";
import i18n from "i18next";
import env from "@beam-australia/react-env";

class ReCaptcha extends React.Component {
  componentDidMount() {
    if (this.captchaDemo) {
      this.captchaDemo.reset();
    }
  }

  reset = () => {
    if (this.captchaDemo) {
      this.captchaDemo.reset();
    }
  };

  onLoadRecaptcha = () => {
    if (this.captchaDemo) {
      this.captchaDemo.reset();
    }
  };

  verifyCallback = recaptchaToken => {
    this.props.onVerificationCallback(recaptchaToken);
  };

  render() {
    const language = i18n.language;
    return (
      <div>
        <GoogleReCaptcha
          ref={el => {
            this.captchaDemo = el;
          }}
          hl={language}
          render="explicit"
          sitekey={env("RECAPTCHA_SITEKEY")}
          onloadCallback={this.onLoadRecaptcha}
          verifyCallback={this.verifyCallback}
        />
      </div>
    );
  }
}

export default ReCaptcha;
