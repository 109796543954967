import React from "react";
import { FilterBarVisual } from "./FilterBarVisual";
import { withTranslation } from "react-i18next";
import SortExercise from "../../helpers/SortExercises";

/**
 * Component which provide filter of exercises (with logic).
 */
class FilterBar extends React.Component {
  constructor(props) {
    super(props);
    const { t } = this.props;
    this.state = {
      setup: {
        rvp: {
          placeholder: t("filter.rvp", "RVP"),
          options: [],
          hasValue: false,
          onChange: kw => this.keywordsChange("rvp", kw)
        },
        koncept: {
          placeholder: t("filter.koncept", "Konceptová"),
          options: [],
          hasValue: false,
          onChange: kw => this.keywordsChange("koncept", kw)
        },
        b4: {
          placeholder: t("filter.b4", "B4"),
          options: [],
          hasValue: false,
          onChange: kw => this.keywordsChange("b4", kw)
        },
        historylab: {
          placeholder: t("filter.historylab", "Historylabová"),
          options: [],
          hasValue: false,
          onChange: kw => this.keywordsChange("historylab", kw)
        },
        text: {
          placeholder: t("filter.text", "Vyhledejte..."),
          options: [],
          onChange: this.changeTextAction
        },
        sorting: {
          onChange: option => this.orderAction(option),
          options: [
            {
              value: "difficultyAsc",
              type: "difficulty",
              label: t("filter.difficultyList.easiest", "Od nejlehčích"),
              direction: false
            },
            {
              value: "difficultyDesc",
              type: "difficulty",
              label: t("filter.difficultyList.hardest", "Od nejtěžších"),
              direction: true
            },
            {
              value: "timeAsc",
              type: "time",
              label: t("filter.difficultyList.timeAsc", "Od nejkratších"),
              direction: false
            },
            {
              value: "timeDesc",
              type: "time",
              label: t("filter.difficultyList.timeDesc", "Od nejdelších"),
              direction: true
            }
          ]
        }
      },
      parametersForSorting: {
        text: "",
        keywords: {
          rvp: null,
          koncept: null,
          b4: null,
          historylab: null
        },
        sorting: {
          type: "time",
          direction: false
        }
      },
      initDone: false,
      showKeywords: false
    };
    this.SorterHelper = new SortExercise();
  }

  updateFilterByProps(props){
    if (this.state.initDone) return;
    if (props.allKeywords === null) return;

    let setup = { ...this.state.setup };

    let countKW = 0;
    Object.entries(props.allKeywords).forEach(entry => {
      const [key, value] = entry;
      setup[key].options = value.map(kw => {
        countKW++;
        return { value: kw, label: kw };
      });
    });

    this.setState({ setup, initDone: true, showKeywords: countKW > 0 }, this.updateActivities);
  }

  /**
   * After the kw is loaded from server, make the setup for filter bar.
   *
   * @param props - New props received from parent.
   */
  componentWillReceiveProps(props) {
    this.updateFilterByProps(props);
  }

  componentDidMount(){
    this.updateFilterByProps(this.props);
  }

  render() {
    const { t } = this.props;

    return (
      <FilterBarVisual
        labels={{
          keywords: t(
            "filter.keywords",
            "Filtrování dle klíčových slov"
          ),
          fulltext: t("filter.fulltext", "Vyhledávání v názvech a anotacích cvičení"),
          sorting: t("filter.sorting", "Řazení")
        }}
        rvpSetup={this.state.setup.rvp}
        konceptSetup={this.state.setup.koncept}
        b4Setup={this.state.setup.b4}
        historylabSetup={this.state.setup.historylab}
        sortTextSetup={this.state.setup.text}
        sortOrderSetup={this.state.setup.sorting}
        showKeyWords={this.state.showKeywords}
      />
    );
  }

  updateActivities() {
    const sortAndUpdateByFilter = () => {
      const filteredExercises = this.SorterHelper.sortExercises(
        this.props.allExercises,
        this.state.parametersForSorting
      );
      this.props.updateExerciseByFilter(filteredExercises);
    };
    // race condition between browser and js
    setTimeout(sortAndUpdateByFilter, 0);
  }

  /**
   * Action for input change (when the text in component FilterText changed, this function is called).
   *
   * @param {String} inputString
   */
  changeTextAction = inputString => {
    if(inputString.length > 2) this.props.setIsSorting(true);
    const parametersForSorting = { ...this.state.parametersForSorting };

    parametersForSorting.text = inputString;

    this.setState({ parametersForSorting }, this.updateActivities);
  };
  /**
   * Action for order by sorting Dropdown (when the button in component SortOrderButton is clicked, this function is called).
   *
   * @param {Object} option - TYPE: type of sorting (current - Time, Difficulty), DIRECTION: direction of sorting (false=low true=high)
   */
  orderAction = option => {
    const parametersForSorting = { ...this.state.parametersForSorting };

    parametersForSorting.sorting = {
      type: option.type,
      direction: option.direction
    };

    this.setState({ parametersForSorting }, this.updateActivities);
  };
  /**
   * Action for all selects, when they are changed (when the values in component FilterSelect changed, this function is called).
   *
   * @param {string} type - the type of the kw (rvp, koncept, b4, historylab)
   * @param {object} keywords - object which Select from react-select will send.
   */
  keywordsChange = (type, keywords) => {
    this.props.setIsSorting(true);
    const parametersForSorting = { ...this.state.parametersForSorting };
    parametersForSorting.keywords[type] = keywords && keywords.label;
    this.setState({ parametersForSorting }, this.updateActivities);
  };
}

export default withTranslation()(FilterBar);
