import React from "react";
import { Jumbotron } from "reactstrap";
import MainHeader from "../MainHeader";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { Col, Container, Row, Button } from "reactstrap";
import { ReactstrapInput } from "reactstrap-formik";
import withNotification from "./../withNotification";
import withTour from "../withTour";
import DataService from "./../DataService";
import { withTranslation, Trans } from "react-i18next";
import i18n from "i18next";
import ReCaptcha from "../Auth/Recaptcha";

Yup.setLocale({
  string: {
    email: ({ path, type, value, originalValue }) => {
      return i18n.t(`forgotPassword.${path}.email`);
    },
    required: ({ path, type, value, originalValue }) => {
      return i18n.t(`forgotPassword.${path}.required`);
    },
    min: params => i18n.t("string.min", params.min),
  },
});

const ForgottenPasswordSchema = Yup.object().shape({
  emailAddress: Yup.string().email().required(),
});

class ForgotPasswordForm extends React.Component {
  submit = (values, callback) => {
    this.setState({ isPending: true });
    new DataService()
      .forgottenPassword(values)
      .then(result => {
        this.setState({
          isPending: false,
        });

        if (result.data && result.data.error) {
          if (!callback(result.data.error)) {
            this.onError(result.data.error);
          }
          return result;
        }
        this.props.history.push("/prihlasit");
        return result;
      })
      .then(this.processNotifications)
      .catch(this.onError);
  };

  onError = error => {
    this.setState({
      error,
      isLoading: false,
    });
    this.props.notify(error);
  };

  processNotifications = data => {
    if (data) {
      (data.notifications || []).forEach(notification => {
        this.props.notify(notification);
      });
    }
  };

  extractDataAndNotifications(result) {
    const data = result.data;
    const flash = JSON.parse(
      decodeURIComponent(window.atob(result.headers["x-hl-flash"]))
    );
    return { data, notifications: flash || [] };
  }

  render() {
    const { isPending, t } = this.props;
    return (
      <div>
        <Formik
          initialValues={{
            emailAddress: "",
            recaptcha: "",
          }}
          validationSchema={ForgottenPasswordSchema}
          onSubmit={(values, actions) => {
            this.submit(values, error => {
              this.recaptcha.reset();
              if (error.form) {
                actions.setErrors(error.form);
                return true;
              }
              return false;
            });
          }}
          render={({ submitForm, isSubmitting, values, setFieldValue }) => (
            <Form>
              <Container style={{ paddingTop: "5px" }}>
                <Row>
                  <Col>
                    <Field
                      type="email"
                      label={t(
                        "forgotPassword.emailAddress.label",
                        "E-mailová adresa"
                      )}
                      name="emailAddress"
                      id="emailAddress"
                      component={ReactstrapInput}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col style={{ display: "flex", justifyContent: "center" }}>
                    <ReCaptcha
                      ref={ref => (this.recaptcha = ref)}
                      onVerificationCallback={key =>
                        setFieldValue("recaptcha", key)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col style={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      type="submit"
                      className="mt-1"
                      disabled={isPending || values.recaptcha === ""}
                    >
                      <Trans i18nKey="submit">Odeslat</Trans>
                    </Button>
                  </Col>
                </Row>
              </Container>
            </Form>
          )}
        />
      </div>
    );
  }
}

const ForgotPassword = props => (
  <div>
    <MainHeader />
    <Jumbotron className="text-center">
      <h1 className="display-3">
        <Trans i18nKey="forgotPassword.header">Obnova zapomenutého hesla</Trans>
      </h1>
      <div className="text-left">
        <ForgotPasswordForm {...props} />
      </div>
    </Jumbotron>
  </div>
);

export default withTranslation()(withNotification(withTour(ForgotPassword)));
