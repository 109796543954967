import React from "react";
import { Container } from "reactstrap";
import { withTranslation } from "react-i18next";
import i18n from "i18next";
import withNotification from "../withNotification";
import Layout from "../Layout";
import "./SupportTemplate.scss";

class SupportTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      pending: false,
      modal: false,
      error: null,
      currentBreakpoint: "lg",
      mounted: false,
    };
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };
  refresh = lng => {
    if (!lng) {
      lng = i18n.language;
    }
    this.setState({ isLoading: true });
  };

  componentDidMount() {
    this.refresh();
    this.setState({ mounted: true });
    const that = this;
    i18n.on("languageChanged", function (lng) {
      that.refresh(lng);
    });
  }

  render() {
    return (
      <Layout
        title={this.props.t("helpdesk.methodicalSupport", "Metodická podpora")}
        isTitleCentered={true}
        isFullWidth={true}
        className="Catalog"
      >
        <Layout.Content>
          <Container>
            {this.props.children}
          </Container>
        </Layout.Content>
      </Layout>
    );
  }
}

export default withTranslation()(withNotification(SupportTemplate));
