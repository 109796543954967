import React from "react";
import _ from "lodash";
import DataService from "../../DataService";
import ChooseOrCreateGroupModal from "../../groups/components/ChooseOrCreateGroupModal";

class ChooseGroupLoader extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      groups: [],
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    new DataService().fetchGroups().then(
      result => {
        let byYears = Object.entries(
          _.groupBy(
            result.data.groups.filter(g => !g.isArchived),
            g => `${g.yearStart} — ${g.yearEnd}`
          ) || {}
        );
        byYears = _.orderBy(byYears, g => g);
        byYears = byYears.reverse();

        const groups = _.flatten(
          byYears.map(y => _.orderBy(y[1], g => (g.isFavourite ? 0 : 1)))
        );

        this.setState({ groups, isLoading: false });
      },
      error =>
        this.setState({
          error,
          isLoading: false,
        })
    );
  }

  render() {
    const { isLoading, groups } = this.state;
    return (
      <ChooseOrCreateGroupModal
        loading={isLoading}
        groups={groups}
        onAssignActivity={this.props.onAssignActivity}
        {...this.props}
      />
    );
  }
}

export default ChooseGroupLoader;
