import React from "react";
import Header from "./../Header";
import MainHeader from "./../MainHeader";
import MainFooter from "./../MainFooter";
import { Button, Table } from "reactstrap";
import DataService from "./../DataService";
import withAuthentication from "./../withAuthentication";
import withNotification from "./../withNotification";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataFilter from "./DataFilter";
import Column from "./Column";
import Pager from "./Pager";

class Peeping extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isExporting: false,
      error: null,
      entries: [],
      paging: {},
      page: 0,
      criteria: {},
      sortedBy: { field: "dateCreated", sort: "desc" }
    };
  }

  refresh = () => {
    const { criteria, sortedBy, page } = this.state;
    this.setState({ error: null, isLoading: true });
    new DataService()
      .fetchPeepingData({
        criteria,
        sortedBy,
        page
      })
      .then(result => {
        this.setState({
          entries: result.data.data || [],
          paging: result.data.paging || {},
          error: null,
          hasData: true,
          isLoading: false
        });
      })
      .then(this.processNotifications)
      .catch(this.onError);
  };

  export = () => {
    const { criteria, options, sortedBy } = this.state;
    /*this.setState({ isExporting: true });
    new DataService()
      .exportPeepingData({
        criteria,
        sortedBy
      })
      .then(data => {
        this.setState({ isExporting: false });
      })
      .catch(this.onError);*/
    //this.setState({ criteria }, () => this.export());
    var strWindowName = "export"; // ifrmDownload
    const c = window.btoa(
      JSON.stringify({
        criteria,
        sortedBy,
        options
      })
    );
    window.open("/api/peeping/export?c=" + c, strWindowName);
  };

  processNotifications = data => {
    if (data) {
      (data.notifications || []).forEach(notification => {
        this.props.notify(notification);
      });
    }
  };

  onError = error => {
    this.setState({
      error,
      isLoading: false,
      isExporting: false
    });
    this.props.notify(error);
  };

  componentDidMount() {
    this.refresh();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.authenticated && this.props.authenticated) {
      this.refresh();
    }
  }

  onSortChanged = sortedBy => {
    this.setState({ sortedBy }, () => this.refresh());
  };

  onPageChanged = page => {
    this.setState({ page }, () => this.refresh());
  };

  onSubmit = criteria => {
    this.setState({ criteria, page: 1 }, () => this.refresh());
  };

  onDataExport = (criteria, options) => {
    this.setState({ criteria, options }, () => this.export());
  };

  render() {
    const {
      entries,
      isLoading,
      isExporting,
      paging,
      page,
      sortedBy
    } = this.state;
    return (
      <div>
        <MainHeader />
        <main>
          <section className="section">
            <div className="section-inner">
              <Header title="Peeping" />
              <main className="grid">
                <DataFilter
                  isLoading={isLoading}
                  isExporting={isExporting}
                  onSubmit={this.onSubmit}
                  onExport={this.onDataExport}
                />
                <Table>
                  <thead>
                    <tr>
                      <Column
                        name="#"
                        field="entryId"
                        sortedBy={sortedBy}
                        onSortChanged={this.onSortChanged}
                      />
                      <Column
                        name="Název cvičení"
                        field="name"
                        sortedBy={sortedBy}
                        onSortChanged={this.onSortChanged}
                      />
                      <Column
                        name="Způsob práce"
                        field="exerciseMode"
                        sortedBy={sortedBy}
                        onSortChanged={this.onSortChanged}
                      />
                      <Column
                        name="Čas odevzdání"
                        field="dateCreated"
                        sortedBy={sortedBy}
                        onSortChanged={this.onSortChanged}
                      />
                      <Column
                        name="Strávený čas"
                        field="timeSpent"
                        sortedBy={sortedBy}
                        onSortChanged={this.onSortChanged}
                      />
                      <th>Dokončeno</th>
                      <th>E-mail žáka</th>
                      <Column
                        name="Skupina"
                        field="groupName"
                        sortedBy={sortedBy}
                        onSortChanged={this.onSortChanged}
                      />
                      <Column
                        name="Učitel"
                        field="teacherEmail"
                        sortedBy={sortedBy}
                        onSortChanged={this.onSortChanged}
                      />
                      <th>Hash</th>
                      <th>From</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {entries.map((entry, index) => {
                      return (
                        <tr key={index}>
                          <td>{entry.entryId}</td>
                          <td>{entry.name}</td>
                          <td>{entry.exerciseDescription}</td>
                          <td>{entry.dateCreated}</td>
                          <td align="right">{entry.timeSpent}</td>
                          <td>{entry.done === 0 ? "ne" : "ano"}</td>
                          <td>{entry.pupilEmail}</td>
                          <td>{entry.groupName}</td>
                          <td>{entry.teacherEmail}</td>
                          <td>{entry.lmsHash}</td>
                          <td>{entry.from}</td>
                          <td>
                            <a
                              target="_blank"
                              href={entry.viewUrl.replace("?anon=0", "")}
                              rel="noopener noreferrer"
                            >
                              <Button outline color="primary" size="sm">
                                <FontAwesomeIcon icon="eye" /> Zobrazit
                              </Button>
                            </a>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                <Pager
                  paging={paging}
                  page={page}
                  onPageChanged={this.onPageChanged}
                />
              </main>
            </div>
          </section>
        </main>
        <MainFooter />
      </div>
    );
  }
}
export default withNotification(withAuthentication(Peeping));
