import React from "react";
import { withTranslation } from "react-i18next";
import i18n from "i18next";
import withNotification from "../../withNotification";
import DataService from "../../DataService";
import VCGridVisual from "./VCGridVisual";
import withTour from "../../withTour";
import MainHeader from "../../MainHeader";
import "./VCGrid.scss";

// Component which provide filter of activities (with logic).
class VCGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      activityDetail: {
        data: "",
        visibility: false,
        scroll: "",
        handleActivityDetailEvent: () => this.handleActivityDetailEvent(),
      },
      data: [],
      mounted: false,
      parameters: {
        nulls: 2, // ratio to number of activities
        offset: 20,
        rows: null, // auto
        columns: 15,
        gridBoxSize: 350, //in px
        typeOfSort: "s", // hsv
      },
    };
  }

  componentDidMount() {
    this.refresh();
    this.setState({ mounted: true });

    i18n.on("languageChanged", lng => {
      this.refresh(lng);
    });
  }

  getActivityPositionOffset(activity) {
    const { offset } = this.state.parameters;
    const getRandomizedOffset = () => Math.round(-offset + Math.random() * (2 * offset));

    activity.offset = {
      x: getRandomizedOffset(),
      y: getRandomizedOffset(),
    };
    return activity;
  }

  compareActivitiesByColor(activityA, activityB) {
    return (
      activityA.metadata.color.hsv[this.state.parameters.typeOfSort] -
      activityB.metadata.color.hsv[this.state.parameters.typeOfSort]
    );
  }

  setCoordsToActivities(activities, param) {
    param.cvWidth = param.gridBoxSize * param.columns;
    param.cvHeight = param.gridBoxSize * param.rows;

    // move center cloud to the center of screen
    const moveY = (param.cvWidth - window.innerWidth) / 2;
    const moveX = (param.cvHeight - window.innerHeight) / 2;

    for (let x = 0; x < param.columns; x++) {
      for (let y = 0; y < param.rows; y++) {
        const index = x * param.rows + y;

        if (activities[index]) {
          // they are percentage
          const offsetX = Math.round(param.gridBoxSize * activities[index].offset.x / 100);
          const offsetY = Math.round(param.gridBoxSize * activities[index].offset.y / 100);

          activities[index].pos = {
            x: x * param.gridBoxSize - moveX + offsetX,
            y: y * param.gridBoxSize - moveY + offsetY
          }
        }
      }
    }
  }


  refresh = lng => {
    if (!lng) {
      lng = i18n.language;
    }

    this.setState({ isLoading: true });
    new DataService().fetchCatalog("/katalog", lng).then(
      result => {
        let activityWithNulls = result.data.exercises
          .map(activity => this.getActivityPositionOffset(activity))
          .sort((a, b) => this.compareActivitiesByColor(a, b));

        if (this.state.parameters.nulls !== null) {
          for (
            let i = 0;
            i < result.data.exercises.length / this.state.parameters.nulls;
            i++
          ) {
            activityWithNulls.splice(
              Math.floor(
                Math.random() * (result.data.exercises.length - 0 + 1)
              ),
              0,
              null
            );
          }
        }

        let { parameters } = { ...this.state };

        parameters.rows = Math.ceil(
          activityWithNulls.length / parameters.columns
        );

        // add strict position for each item
        this.setCoordsToActivities(activityWithNulls, parameters);

        this.setState({
          data: activityWithNulls,
          isLoading: false,
          parameters,
        });
      },
      error =>
        this.setState({
          error,
          isLoading: false,
        })
    );
  };

  onClickActivity = activity => {
    let activityDetail = { ...this.state.activityDetail };
    activityDetail.visibility = true;
    activityDetail.data = activity;
    this.setState({ activityDetail });
  };

  handleActivityDetailEvent = () => {
    let activityDetail = { ...this.state.activityDetail };
    activityDetail.visibility = false;
    this.setState({ activityDetail });
  };

  render() {
    const { t, history } = this.props;
    const { data, isLoading, activityDetail, parameters } = this.state;

    return (
      <React.Fragment>
        <MainHeader
          isHeaderAbsolutePositioned={true}
          isHeaderInverted={true}
          color="dark"
          visibility={!activityDetail.visibility}
        />

        {isLoading && (
          <div
            className="loading"
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%,-50%)",
              width: "5vmin",
              height: "5vmin",
              borderRadius: "5vmin",
              backgroundColor: "black",
            }}
          />
        )}

        {!isLoading && (
          <VCGridVisual
            translation={t}
            activityDetail={activityDetail}
            activities={data}
            onClickActivity={this.onClickActivity}
            parameters={parameters}
            history={history}
          />
        )}
      </React.Fragment>
    );
  }
}

export default withTranslation()(withNotification(withTour(VCGrid)));
