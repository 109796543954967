import React from "react";
import classnames from "classnames";
import SortOrderButton from "../atoms/order/SortOrderButton";
import FilterText from "../atoms/input/FilterText";
import FilterSelect from "../atoms/select/FilterSelect";
import "./FilterBarVisual.scss";

/**
 * Component filter bar for sorting exercises, only visual, no logic.
 *
 * @param labels - labels for FilterBarAreas
 * @param rvpSetup - setup for rvp select
 * @param konceptSetup - setup for koncept select
 * @param b4Setup - setup for b4 select
 * @param historylabSetup - setup for historylab select
 * @param sortTextSetup - setup for text input
 * @param sortOrderButton - setup for filter order
 * @param showKeyWords - true / false - show keywords
 *
 */
export const FilterBarVisual = ({
  labels,
  rvpSetup,
  konceptSetup,
  b4Setup,
  historylabSetup,
  sortTextSetup,
  sortOrderSetup,
  showKeyWords = true,
}) => (
  <div className="FilterBar">
    {showKeyWords && (
      <div className="FilterBar__keywords">
        <div className="FilterBar__label">
          <h6 title={labels.keywords}>{labels.keywords}</h6>
        </div>
        <div className="FilterBar__filters">
          {rvpSetup.options.length > 0 && (
            <div className="FilterBar__filter" data-tut="rvp">
              <FilterSelect
                data-cy="filterSelectRVP"
                hasValue={rvpSetup.hasValue}
                options={rvpSetup.options}
                isMulti={false}
                placeholder={rvpSetup.placeholder}
                onChange={rvpSetup.onChange}
              />
            </div>
          )}
          {konceptSetup.options.length > 0 && (
            <div className="FilterBar__filter" data-tut="concepts">
              <FilterSelect
                data-cy="filterSelectKoncept"
                hasValue={konceptSetup.hasValue}
                options={konceptSetup.options}
                isMulti={false}
                placeholder={konceptSetup.placeholder}
                onChange={konceptSetup.onChange}
              />
            </div>
          )}
          {b4Setup.options.length > 0 && (
            <div className="FilterBar__filter" data-tut="b4">
              <FilterSelect
                data-cy="filterSelectB4"
                hasValue={b4Setup.hasValue}
                options={b4Setup.options}
                isMulti={false}
                placeholder={b4Setup.placeholder}
                onChange={b4Setup.onChange}
              />
            </div>
          )}
          {historylabSetup.options.length > 0 && (
            <div className="FilterBar__filter" data-tut="historylab">
              <FilterSelect
                data-cy="filterSelectHistorylab"
                hasValue={historylabSetup.hasValue}
                options={historylabSetup.options}
                isMulti={false}
                placeholder={historylabSetup.placeholder}
                onChange={historylabSetup.onChange}
              />
            </div>
          )}
        </div>
      </div>
    )}
    <div
      className={classnames("FilterBar__fulltext", {
        "FilterBar__fulltext--fullWidth": !showKeyWords,
      })}
      data-tut="fulltext"
    >
      <div className="FilterBar__label">
        <h6 title={labels.fulltext}>{labels.fulltext}</h6>
      </div>
      <FilterText
        placeholder={sortTextSetup.placeholder}
        onChange={sortTextSetup.onChange}
      />
    </div>
    <div className="FilterBar__sorting">
      <div className="FilterBar__label">
        <h6 title={labels.sorting}>{labels.sorting}</h6>
      </div>
      <SortOrderButton
        options={sortOrderSetup.options}
        onChange={sortOrderSetup.onChange}
        defaultValue={sortOrderSetup.options[2]}
      />
    </div>
  </div>
);

FilterBarVisual.defaultProps = {
  backgrounddivor: null,
};
