import React from "react";
import { Trans } from "react-i18next";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import withEverything from "./../../withEverything";
import withTour from "./../../withTour";
import DataService from "./../../DataService";
import Layout from "./../../Layout";
import GroupTile from "./../components/GroupTile";
import { Button } from "reactstrap";
import "./GroupList.scss";

class GroupList extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      pending: false,
      modal: false,
      error: null,
      dataService: null,
      data: {
        groups: [],
        warnings: []
      }
    };
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  };

  toggleFavouriteFunction = group => {
    new DataService()
      .updateGroup({ ...group, isFavourite: !group.isFavourite })
      .then(res => {
        this.props.processNotifications(res);
        const groups = this.state.data.groups.map(g => {
          if (g.id === group.id) {
            return { ...g, isFavourite: !g.isFavourite };
          }
          return g;
        });
        this.setState({ data: { groups } });
      })
      .catch(this.onError);
  };

  onError = error => {
    this.setState({
      error,
      isLoading: false
    });
    this.props.notify(error);
  };

  refresh = () => {
    this.setState({ isLoading: true });

    (this.props.dataService || new DataService()).fetchGroups().then(
      res => {
        this.props.processNotifications(res);
        this.setState({
          error: null,
          data: res.data,
          isLoading: false
        });
      },
      error =>
        this.setState({
          error,
          isLoading: false
        })
    );
  };

  createClass = () => {
    const { history } = this.props;
    history.push("/trida");
  };

  componentDidMount() {
    this.refresh();
  }

  render() {
    const { isLoading, data } = this.state;
    const { t } = this.props;
    const title = t("mainHeader.myClasses");

    const groups = data.groups || [];
    let byYears = Object.entries(
      _.groupBy(
        groups.filter(g => !g.isArchived && !g.isDeleted),
        g => `${g.yearStart} — ${g.yearEnd}`
      ) || {}
    );
    byYears = _.orderBy(byYears, g => g);
    byYears = byYears.reverse();

    const archivedClasses = groups.filter(g => g.isArchived && !g.isDeleted);

    function getClassYearLabel(year) {
      if (year === "0 — 0") {
        return (
          <span data-tut="GroupList_unsorted">
            <Trans i18nKey="groupList.unsorted">Neroztříděné třídy</Trans>
          </span>
        );
      }

      return (
        <span data-tut="GroupList_sorted">
          <Trans i18nKey="groupList.sorted">Školní rok</Trans>
          {": "}
          {year}
        </span>
      );
    }

    return (
      <Layout
        title={isLoading ? "..." : title}
        subHeader={
          !isLoading && (
            <Button
              data-tut="GroupList_newClass"
              color="primary"
              size="lg"
              onClick={this.createClass}
            >
              <FontAwesomeIcon icon="plus-circle" className="mr-1" />
              <Trans i18nKey="groupList.new">Nová třída</Trans>
            </Button>
          )
        }
      >
        <Layout.Content>
          {!isLoading && (
            <div className="hl-grouplist">
              {byYears.map((group, index) => {
                return (
                  <div key={index} className="hl-grouplist__year">
                    <div className="hl-grid">
                      <div className="hl-divider">
                        {getClassYearLabel(group[0])}
                      </div>

                      {_.sortBy(_.sortBy(group[1], "name"), [
                        g => {
                          return g.isFavourite ? 0 : 1;
                        }
                      ]).map((g, i) => {
                        return (
                          <GroupTile
                            key={g.id}
                            group={g}
                            onClick={this.onAdd}
                            location={this.props.location}
                            toggleFavourite={this.toggleFavouriteFunction}
                            dataTut='GroupTile_detail'
                          />
                        );
                      })}
                    </div>
                  </div>
                );
              })}
              {archivedClasses.length > 0 && (
                <div className="hl-grid">
                  <div className="hl-divider">
                    <Trans i18nKey="groupList.archived">Archivované</Trans>:
                  </div>
                  {archivedClasses
                    .filter(g => g.isArchived)
                    .map((g, i) => {
                      return (
                        <GroupTile
                          key={g.id}
                          group={g}
                          onClick={this.onAdd}
                          location={this.props.location}
                          className="is-archived"
                          toggleFavourite={this.toggleFavouriteFunction}
                        />
                      );
                    })}
                </div>
              )}
            </div>
          )}
        </Layout.Content>
      </Layout>
    );
  }
}

const tourSteps = t => {
  const step = num => t("groupList.tutorial.step" + num);
  const steps = [
    {
      selector: "[data-tut='GroupList_newClass']",
      content: step(1)
    },
    {
      selector: "[data-tut='GroupTile_detail']",
      position: "right",
      content: step(2)
    },
    { selector: "[data-tut='GroupTile_isFavourite']", content: step(3) },
    { selector: "[data-tut='GroupList_unsorted']", content: step(4) }
  ];
  return steps;
};

export default withEverything(withTour(GroupList, tourSteps));
