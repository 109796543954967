import React from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, CardTitle } from "reactstrap";
import classnames from "classnames";
import "./PageTile.scss";

const PageTile = ({ title, path, location, bgClass, target }) => {
  return (
    <Card
      className={`${classnames({
        "hl-card--class": true,
      })} ${bgClass}`}
    >
      <CardBody>
        <Link
          to={{
            pathname: `${path}`,
            state: {
              returnTo: location.pathname,
            },
          }}
          target={target}
        >
          <CardTitle tag="h5">{title}</CardTitle>
        </Link>
      </CardBody>
    </Card>
  );
};

export default PageTile;
