import React from "react";
import { withTranslation } from "react-i18next";
import i18n from "i18next";
import withNotification from "../withNotification";
import withTour from "../withTour";
import Layout from "../Layout";
import PageTile from "../components/helpdesk/PageTile";
import "./index.scss";

class Helpdesk extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      pending: false,
      modal: false,
      error: null,
      currentBreakpoint: "lg",
      mounted: false,
    };
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  refresh = lng => {
    if (!lng) {
      lng = i18n.language;
    }
    this.setState({ isLoading: true });
  };

  componentDidMount() {
    this.refresh();
    this.setState({ mounted: true });
    const that = this;
    i18n.on("languageChanged", function (lng) {
      that.refresh(lng);
    });
  }

  render() {
    return (
      <Layout
        title={this.props.t("helpdesk.home")}
        isTitleCentered={true}
        isFullWidth={true}
      >
        <Layout.Content>
          {this.state.isLoading && (
            <div>
              <div className="helpdesk-row">
                <h4>{this.props.t("helpdesk.subTitle")}</h4>
              </div>
              <div className="helpdesk-row">
                <PageTile
                  title={this.props.t("helpdesk.card.bugFrom")}
                  path="/podpora/formular/chyba"
                  location={this.props.location}
                  bgClass="significant"
                />
                <PageTile
                  title={this.props.t("helpdesk.card.metodicalSupport")}
                  path="/podpora/metodicka-podpora"
                  location={this.props.location}
                  bgClass="normal"
                />
                <PageTile
                  title={this.props.t("helpdesk.card.helpForm")}
                  path="/podpora/formular/helpdesk"
                  location={this.props.location}
                  bgClass="orange"
                />
                <PageTile
                  title={this.props.t("helpdesk.card.pdfSupport")}
                  path="/podpora/pdf-navod"
                  location={this.props.location}
                  bgClass="normal"
                  target="_blank"
                />
              </div>
            </div>
          )}
        </Layout.Content>
      </Layout>
    );
  }
}

export default withTranslation()(withNotification(withTour(Helpdesk)));
