import React from "react";
import {
  ModalHeader,
  ModalBody,
  Modal,
  Button,
  ModalFooter,
  FormFeedback,
  Form,
  UncontrolledAlert,
  FormGroup,
  Input,
  Col
} from "reactstrap";
import { Trans } from "react-i18next";
import i18n from "i18next";

class Evaluation extends React.Component {
  constructor(props) {
    super();
    this.state = {
      isLoading: false,
      error: null,
      pending: false,
      formErrors: { note: "", score: "" },
      scoreValid: false,
      noteValid: false,
      canSubmit: false,
      note: props.submission.note || "",
      entryId: props.submission.entryId,
      score: props.submission.score || "",
      emailAddresses: props.submission.emailAddresses,
      mounted: false
    };
  }

  setError = error => {
    this.setState({ error });
  };

  componentDidMount() {
    this.setState({ mounted: true });
  }

  setSumbission = (s, cb) => {
    const submission = {
      note: s.note || "",
      score: s.score || 0,
      emailAddresses: s.emailAddresses,
      entryId: s.entryId
    };
    this.setState({ ...submission }, () => {
      const { note, score } = this.state;
      this.validateField("note", note || "");
      this.validateField("score", score || "");
      cb();
    });
  };

  handleChange = event => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({ [name]: value }, () => {
      this.validateField(name, value);
    });
  };

  submit = () => {
    const { entryId, note, score, emailAddress } = this.state;
    this.setState({ pending: true });
    this.props
      .evaluate({ entryId, emailAddress, note, score: score * 1 })
      .then(_ => {
        this.setState({ pending: false, error: null });
      })
      .catch(error => this.setState({ pending: false, error }));
  };

  validateField = (fieldName, value) => {
    let fieldValidationErrors = this.state.formErrors;
    let scoreValid = this.state.scoreValid || false;
    let noteValid = this.state.noteValid || false;

    switch (fieldName) {
      case "note":
        noteValid = value.length > 0;
        fieldValidationErrors.note = noteValid
          ? ""
          : i18n.t("evaluation.note.required", "Slovní hodnocení není zadáno");
        break;
      case "score":
        const regex = /^\d+$/;
        const v = value.toString().trim();
        scoreValid =
          v.length === 0 || (v.match(regex) && v * 1 > -1 && v * 1 < 101);
        fieldValidationErrors.score = scoreValid
          ? ""
          : i18n.t(
              "evaluation.score.range",
              "Zadaná hodnota musí být číslo mezi 0 a 100"
            );
        break;
      default:
        break;
    }
    this.setState({
      formErrors: fieldValidationErrors,
      scoreValid,
      noteValid: fieldValidationErrors.note === "",
      canSubmit: scoreValid && fieldValidationErrors.note === ""
    });
  };

  render() {
    const { isOpen, toggle } = this.props;
    const { error, note, score } = this.state;

    return (
      <Modal isOpen={isOpen} toggle={toggle} backdrop centered>
        <ModalHeader toggle={toggle}>
          <Trans i18nKey="evaluation.header">
            Hodnocení odevzdaného cvičení
          </Trans>
        </ModalHeader>
        <ModalBody>
          {error && (
            <UncontrolledAlert color="danger">
              <Trans i18nKey="error">Chyba</Trans> {error.response.status}:{" "}
              {error.response.data.message}
            </UncontrolledAlert>
          )}
          <b>
            <Trans i18nKey="evaluation.emailAddress">
              Uvedené e-mailové adresy:
            </Trans>
          </b>
          <p>
            {(this.state.emailAddresses || [])
              .map(e => e.emailAddress)
              .join(", ")}
          </p>
          <Form>
            <FormGroup tag="fieldset" row>
              <legend className="col-form-label col-sm-12">
                <Trans i18nKey="evaluation.note.label">Slovní hodnocení</Trans>
              </legend>
              <Col sm={12}>
                <Input
                  name="note"
                  id="note"
                  maxLength={2000}
                  type="textarea"
                  value={note}
                  invalid={!this.state.noteValid}
                  valid={this.state.noteValid}
                  onChange={this.handleChange}
                  placeholder={i18n.t(
                    "evaluation.note.placeholder",
                    "Slovní hodnocení"
                  )}
                />
                <FormFeedback>{this.state.formErrors.note}</FormFeedback>
              </Col>
            </FormGroup>
            <FormGroup tag="fieldset" row>
              <legend className="col-form-label col-sm-12">
                <Trans i18nKey="evaluation.score.label">
                  Číselné hodnocení (0 - 100) (nepovinné)
                </Trans>
              </legend>
              <Col sm={12}>
                <Input
                  name="score"
                  id="score"
                  maxLength={10}
                  type="number"
                  value={score}
                  invalid={!this.state.scoreValid}
                  valid={!!this.state.scoreValid}
                  onChange={this.handleChange}
                  placeholder={i18n.t(
                    "evaluation.note.placeholder",
                    "Číselné hodnocení"
                  )}
                />
                <FormFeedback>{this.state.formErrors.score}</FormFeedback>
              </Col>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={this.submit}
            disabled={!this.state.canSubmit}
          >
            <Trans i18nKey="submit">Odeslat</Trans>
          </Button>
          <Button color="secondary" onClick={toggle}>
            <Trans i18nKey="close">Zavřít</Trans>
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default Evaluation;
