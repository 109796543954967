import axios from "axios";
import i18n from "i18next";
import env from "@beam-australia/react-env";

class DataService {
  constructor() {
    let header = "en-US,en;q=0.5";
    if (i18n.language === "cs") {
      header = "cs";
    }
    axios.defaults.baseURL = env("API_BASEURL") || "";
    axios.defaults.headers.common["Accept-Language"] = header;
  }

  fetchResearch(classId, researchId) {
    return axios
      .get(`/api/research/${classId}/${researchId}`)
      .then(this.extractDataAndNotifications);
  }

  sendExerciseToReceipients(classId, researchId) {
    const options = {
      method: "POST",
      data: { classId, researchId },
      url: "/api/research/send",
    };
    return axios(options).then(this.extractDataAndNotifications);
  }

  lock(classId, researchId) {
    const options = {
      method: "POST",
      data: { classId, researchId },
      url: "/api/research/lock",
    };
    return axios(options).then(this.extractDataAndNotifications);
  }

  unlock(classId, researchId) {
    const options = {
      method: "POST",
      data: { classId, researchId },
      url: "/api/research/unlock",
    };
    return axios(options).then(this.extractDataAndNotifications);
  }

  extractDataAndNotifications(result) {
    const { data } = result;
    if (result.headers["x-hl-flash"]) {
      const flash = JSON.parse(
        decodeURIComponent(window.atob(result.headers["x-hl-flash"]))
      );
      return { data, notifications: flash || [] };
    }
    return { data, notifications: [] };
  }
}

export default DataService;
