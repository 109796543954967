import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import {
  Alert,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  Container,
  Collapse,
  Button,
} from "reactstrap";
import { Trans, useTranslation } from "react-i18next";
import "./css/App.css";
import classnames from "classnames";
import env from "@beam-australia/react-env";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ApplicationStateConsumer } from "./ApplicationStateProvider";
import Logo from "./icons/logo";
import LanguageSwitcher from "./LanguageSwitcher";

import "./MainHeader.scss";
import DataService from "./DataService";
import headerSetting from "./catalog/catalogSettings.json";

const MainHeader = ({
  color = "light",
  isHeaderAbsolutePositioned,
  isHeaderInverted,
  visibility = "visible",
}) => {
  const isTestEnvironment = env("IS_TEST_SERVER") === "1";
  const { i18n } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);
  const [banner, setBanner] = useState([]);
  const [bannerPublic, setBannerPublic] = useState([]);
  const [bannerClosed, setBannerClosed] = useState([]);

  useEffect(() => {
    if (sessionStorage.getItem("user")) {
      new DataService().fetchBannerAlert(i18n.language).then(
        result => {
          setBanner(result.data.bannerData);
        },
        error => console.error("-> ERROR", error)
      );
    }

    new DataService().fetchPublicBannerAlert(i18n.language).then(
      result => {
        setBannerPublic(result.data.bannerData);
      },
      error => console.error("-> ERROR", error)
    );

    const banners = localStorage.getItem("banners");

    if (banners) {
      setBannerClosed(JSON.parse(banners).closed);
    }
  }, [i18n.language]);

  const onDismiss = b => {
    let banners;
    try {
      banners = JSON.parse(localStorage.getItem("banners"));
    } catch (e) {
      banners = {
        closed: [],
      };
    }
    if (!banners) {
      banners = {
        closed: [],
      };
    }

    banners.closed.push(b.id);
    localStorage.setItem("banners", JSON.stringify(banners));
    setBannerClosed([...bannerClosed, b.id]);
  };

  const renderBanner = b => {
    const alertProps = {
      key: `banner${b.id}`,
      color: b.type,
      fade: false,
      className: "Alert--top",
      icon: b.type === "danger" ? "exclamation-triangle" : "info-circle",
    };

    if (b.closable) {
      alertProps.isOpen = !bannerClosed.some(e => e === b.id);
      alertProps.toggle = () => onDismiss(b);
    }

    if (b.links && b.links.length !== 0) {
      const bannerWithLink = b.links.map((link, index) => {
        const splitByLink = b.text.split(link.place);
        return (
          <span className="Alert__text" key={`link${index}`}>
            {splitByLink[0]}{" "}
            {
              <a
                className="Alert__link"
                href={link.link}
                onClick={() => onDismiss(b.id)}
              >
                {link.text}
              </a>
            }
            {!!splitByLink[1] && ` ${splitByLink[1]}`}
          </span>
        );
      });

      return (
        <Alert {...alertProps}>
          <FontAwesomeIcon className={"mr-2"} icon={alertProps.icon} />
          {bannerWithLink}
        </Alert>
      );
    }

    return (
      <Alert {...alertProps}>
        <FontAwesomeIcon className={"mr-2"} icon={alertProps.icon} />
        {b.text}
      </Alert>
    );
  };

  const renderLinks = lang => {
    const headerLinksLang = headerSetting[lang]
      ? headerSetting[lang]
      : headerSetting.default;

    return headerLinksLang.map((c, index) => (
      <NavItem key={`nav-${index}`}>
        <NavLink to={c.link}>{c.name}</NavLink>
      </NavItem>
    ));
  };

  const banners = [...bannerPublic, ...banner];

  return (
    <ApplicationStateConsumer>
      {({ authenticated, logout }) => (
        <>
          <header
            id="header"
            className={classnames("header", `header--${color}`, {
              "header--test": isTestEnvironment,
              "is--hidden": !visibility,
              "is-absolutePositioned": isHeaderAbsolutePositioned,
              "is-inverted": isHeaderInverted,
              "has-alert": !!banners.length,
              [`has-alert--${banners.length}`]: !!banners.length,
            })}
          >
            {!!banners?.length && banners.map(b => renderBanner(b))}
            <Navbar
              light={isHeaderInverted && color !== "dark"}
              dark={!isHeaderInverted || color === "dark"}
              expand="md"
            >
              <Container fluid>
                <NavbarBrand
                  href="https://historylab.cz/"
                  className="logo mr-4"
                >
                  <Logo />
                  {isTestEnvironment && (
                    <span className="MainHeader__testServer">
                      <Trans i18nKey="mainHeader.testServer">
                        Testovací server
                      </Trans>
                    </span>
                  )}
                </NavbarBrand>

                <NavbarToggler onClick={toggle} />

                <Collapse isOpen={isOpen} navbar>
                  <div className="MainNavigation">
                    <div className="MainNavigation__item MainNavigation__secondary">
                      {!authenticated && (
                        <>
                          <NavItem>
                            <NavLink to="/prihlasit">
                              <Trans i18nKey="signIn">Přihlásit se</Trans>
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink to="/registrace">
                              <Trans i18nKey="signUp.signUp">
                                Registrovat se
                              </Trans>
                            </NavLink>
                          </NavItem>
                        </>
                      )}

                      {authenticated && (
                        <>
                          <NavItem>
                            <Button
                              color="link"
                              className="logout"
                              onClick={logout}
                            >
                              <Trans i18nKey="signOut">Odhlásit se</Trans>
                            </Button>
                          </NavItem>
                          <NavItem>
                            <NavLink to="/ucet">
                              <Trans i18nKey="accountMainHeader">Účet</Trans>
                            </NavLink>
                          </NavItem>
                        </>
                      )}

                      <NavItem>
                        <NavLink to="/podpora">
                          <Trans i18nKey="mainHeader.helpdesk">Podpora</Trans>
                        </NavLink>
                      </NavItem>

                      <NavItem>
                        <a
                          href="https://historylab.cz/blog/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Trans i18nKey="blog.blog">Blog</Trans>
                        </a>
                      </NavItem>
                      <NavItem>
                        <div>|</div>
                      </NavItem>
                      <NavItem>
                        <LanguageSwitcher />
                      </NavItem>
                    </div>

                    <div className="MainNavigation__item MainNavigation__primary">
                      {authenticated && (
                        <NavItem>
                          <NavLink to="/tridy">
                            <Trans i18nKey="mainHeader.myClasses" />
                          </NavLink>
                        </NavItem>
                      )}

                      {renderLinks(i18n.language)}
                    </div>
                  </div>
                </Collapse>
              </Container>
            </Navbar>
          </header>
        </>
      )}
    </ApplicationStateConsumer>
  );
};

export default MainHeader;
