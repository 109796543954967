import React, { useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import classnames from "classnames";
import { theme } from "./React-select-theme";
import "./FilterSelect.scss";

/**
 * Component for sort by select kw.
 */
const FilterSelect = ({
  defaultValue,
  options,
  placeholder,
  isMulti,
  onChange,
}) => {
  const [hasValue, setHasValue] = useState(defaultValue);

  return (
    <div className="FilterSelect" data-cy="filterSelect">
      <label
        className={classnames("FilterSelect__placeholderIfHasValue", {
          "FilterSelect__placeholderIfHasValue--has-value": hasValue,
        })}
      >
        {placeholder}
      </label>
      <Select
        // className="FilterSelect"
        classNamePrefix="FilterSelect"
        closeMenuOnSelect={!isMulti}
        isClearable
        isMulti={isMulti}
        isSearchable={isMulti}
        options={options}
        placeholder={placeholder}
        theme={theme}
        onChange={event => {
          onChange(event);
          setHasValue(event);
        }}
        // menuIsOpen
      />
    </div>
  );
};
FilterSelect.propTypes = {
  defaultValue: PropTypes.object,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  isMulti: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

FilterSelect.defaultProps = {
  defaultValue: null,
  isMulti: false,
};

export default FilterSelect;
