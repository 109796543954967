import React from "react";
import { Link } from "react-router-dom";
import { Trans } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Breadcrumb, BreadcrumbItem, Button } from "reactstrap";
import DataService from "./DataService";
import withEverything from "./withEverything";
import Layout from "./Layout";
import HLCard from "./components/Card/HLCard";
import "./css/App.css";
import "./ExerciseList.scss";
import { color } from "./helpers/color";

var groupBy = function (xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

class ExerciseList extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoading: false,
      modal: false,
      error: null,
      data: {
        exercises: [],
        warnings: [],
      },
    };

    this.onDetail = this.onDetail.bind(this);
  }

  refresh = () => {
    this.setState({ error: null, isLoading: true });
    new DataService()
      .fetchExerciseList(this.props.match.params.id)
      .then(result => {
        this.setState({
          data: result.data || [],
          error: null,
          hasData: true,
          isLoading: false,
        });
      })
      .then(this.processNotifications)
      .catch(this.onError);
  };

  processNotifications = data => {
    if (data) {
      (data.notifications || []).forEach(notification => {
        this.props.notify(notification);
      });
    }
  };

  onError = error => {
    const notFound = error.response && error.response.status === 404;
    this.setState({
      error,
      notFound,
      isLoading: false,
    });
    this.props.notify(error);
  };

  componentDidMount() {
    this.refresh();
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  onDetail(exercise) {
    this.toggle();
    this.setState({ exercise });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.authenticated && this.props.authenticated) {
      this.refresh();
    }
  }

  goToSetttings = group_id => {
    const { history } = this.props;
    history.push("/tridy/" + group_id + "/detail/");
  };

  render() {
    const { data, isLoading } = this.state;
    const { t } = this.props;
    const exercises = data.exercises || [];
    // TODO: Pokud ještě nemá třída žádná cvičení, tak nelze získat název ani jiná data o dané třídě
    const groupped = groupBy(exercises, "group_name");
    const noData = !isLoading && exercises.length === 0;
    const keys = Object.keys(groupped);
    keys.sort();

    const subHeader = !isLoading && (
      <Button
        color="primary"
        size="lg"
        onClick={() => this.goToSetttings(data.group.id)}
        data-cy={"group-setup-bt"}
      >
        <FontAwesomeIcon icon="cog" className="mr-1" />
        <Trans i18nKey="classEdit.settings">Nastavení třídy</Trans>
      </Button>
    );

    const breadcrumb = (
      <Breadcrumb>
        <BreadcrumbItem>
          <Link to="/tridy">
            <Trans i18nKey="mainHeader.myClasses">Moje třídy</Trans>
          </Link>
        </BreadcrumbItem>
        <BreadcrumbItem active>
          {t("exerciseList.class", "Třída")}
        </BreadcrumbItem>
      </Breadcrumb>
    );

    return (
      <Layout
        className="ExerciseList"
        title={isLoading ? "..." : ` ${data.group && data.group.name}`}
        subHeader={subHeader}
        breadcrumb={breadcrumb}
      >
        <Layout.Content>
          {noData && (
            <section>
              <h1>
                <Trans i18nKey="welcome"></Trans>
              </h1>
              <p>
                <Trans i18nKey="exerciseList.noExercises">
                  Zatím nemáte žádné zadané cvičení.
                </Trans>
              </p>
              <hr />
              <p>
                <Link to="/katalog">
                  <Button color="primary">
                    <Trans i18nKey="exerciseList.gotoCatalogue">
                      Přejít do katalogu
                    </Trans>
                  </Button>
                </Link>
              </p>
            </section>
          )}
          {!noData && (
            <div className="hl-grid">
              {keys.map(g =>
                groupped[g].map(exercise => (
                  <Link key={exercise.id} to={{ pathname: exercise.detailUrl }}>
                    <HLCard
                      image={exercise.image_url}
                      name={exercise.name}
                      workType={{
                        type: exercise.type,
                        name: exercise.typeDescription,
                      }}
                      isPackage={exercise.isPackage}
                      submitted={exercise.submitted}
                      total={exercise.total}
                      isLocked={exercise.isLocked}
                      variant="exerciseList"
                      color={color(exercise.metadata.color)}
                    />
                  </Link>
                ))
              )}
            </div>
          )}
        </Layout.Content>
      </Layout>
      //<MainFooter />
    );
  }
}

export default withEverything(ExerciseList);
