import React from "react";
import { Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import GroupDetail from "./GroupDetail";
import DataService from "./../../DataService";
import { Trans } from "react-i18next";
import withEverything from "./../../withEverything";
import withTour from "./../../withTour";
import DeleteGroupConfirmationDialog from "./../components/DeleteGroupConfirmationDialog";
import Layout from "./../../Layout";

class GroupEditPage extends React.Component {
  constructor(props) {
    super(props);
    const defaultColors = [
      "#f44336",
      "#e91e63",
      "#9c27b0",
      "#673ab7",
      "#3f51b5",
      "#2196f3",
      "#03a9f4",
      "#00bcd4",
      "#009688",
      "#4caf50",
      "#8bc34a",
      "#cddc39",
      "#ffeb3b",
      "#ffc107",
      "#ff9800",
      "#ff5722",
      "#795548",
      "#607d8b"
    ];

    const index = Math.floor(
      (Math.random() * defaultColors.length) % defaultColors.length
    );

    this.state = {
      activeTab: "1",
      isLoading: true,
      title: "",
      pending: false,
      modal: false,
      error: null,
      group: {
        name: "",
        note: "",
        yearStart: new Date().getFullYear(),
        yearEnd: new Date().getFullYear() + 1,
        isArchived: false,
        isFavourite: false,
        color: defaultColors[index],
        projects: {
          "d-plus": false,
        },
        typeOfClass: "",
      },
      addEmailAddresses: { isOpen: false },
      deleteConfirmation: { isOpen: false },
      user: this.props.user
    };
  }

  deleteEmailAddressFromGroup = emailAddress => {
    const { group } = this.state;
    this.setState({ pending: true, error: null });
    new DataService()
      .deleteEmailAddressFromGroup(group.id, emailAddress)
      .then(result => {
        this.setState({
          group: result.data || result,
          modal: false,
          emailAddress: {}
        });
        this.emailAddressesFormRef && this.emailAddressesFormRef.toggle();
      })
      .then(this.props.processNotifications)
      .catch(this.onError);
  };

  toggleDeleteConfirmation = () => {
    const { deleteConfirmation } = this.state;
    this.setState({
      deleteConfirmation: {
        ...deleteConfirmation,
        isOpen: !deleteConfirmation.isOpen
      }
    });
  };

  addEmailAddressToGroup = emailAddress => {
    const { group } = this.state;
    this.setState({ pending: true, error: null });
    new DataService()
      .addEmailAddressesToGroup(group.id, emailAddress.emailAddress)
      .then(result => {
        this.setState({ group: result.data || result });
        return result;
      })
      .then(this.props.processNotifications)
      .catch(this.onError);
  };

  addEmailAddresses = (group, emailAddresses) => {
    this.setState({ pending: true, error: null });
    new DataService()
      .addEmailAddressesToGroup(group.id, emailAddresses)
      .then(this.props.processNotifications)
      .then(result => {
        this.setState({ group: result.data || result });
        return result;
      })
      .catch(this.onError)
      .finally(_ => {
        this.toggleAddEmailAddresses();
      });
  };

  toggleTab = tab => {
    this.setState({
      activeTab: tab.toString()
    });
  };

  toggleAddEmailAddresses = () => {
    this.setState({
      addEmailAddresses: {
        isOpen: !this.state.addEmailAddresses.isOpen
      }
    });
  };

  updateGroup = group => {
    const { history } = this.props;
    this.setState({ isLoading: true });
    new DataService()
      .updateGroup(group)
      .then(this.props.processNotifications)
      .then(_ => {
        history.push("/tridy");
      })
      .catch(this.onError);
  };

  deleteGroup = () => {
    const { history } = this.props;
    this.setState({ isLoading: true });
    new DataService()
      .deleteGroup(this.state.group)
      .then(this.props.processNotifications)
      .then(_ => {
        history.push("/tridy");
      })
      .catch(this.onError);
  };

  refresh = () => {
    if (
      this.props.match.params.id === undefined ||
      this.props.match.params.id === "nova"
    ) {
      this.setState({
        isLoading: false
      });
      return;
    }
    this.setState({ isLoading: true });

    (this.props.dataService || new DataService())
      .fetchGroup(this.props.match.params.id)
      .then(
        result => {
          this.setState({
            error: null,
            group: result.data,
            isLoading: false
          });
        },
        error =>
          this.setState({
            error,
            isLoading: false
          })
      );
  };

  onError = error => {
    this.setState({
      error,
      isLoading: false
    });
    this.props.notify(error);
  };

  componentDidMount() {
    this.refresh();
  }

  render() {
    const { activeTab, isLoading, group } = this.state;
    return (
      <Layout
        title={
          <React.Fragment>
            {isLoading && "..."}
            {!isLoading && (
              <React.Fragment>
                <Trans i18nKey="classEdit.settings">Nastavení třídy</Trans>
                {" " + group.name}
              </React.Fragment>
            )}
          </React.Fragment>
        }
        breadcrumb={
          <React.Fragment>
            <Breadcrumb>
              <BreadcrumbItem>
                <Link to="/tridy">
                  <Trans i18nKey="mainHeader.myClasses">Moje třídy</Trans>
                </Link>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <Link to={"/tridy/" + group.id}>
                  <Trans i18nKey="exeraciseDetail.assignedExercise">
                    Třída
                  </Trans>
                </Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>
                <Trans i18nKey="classEdit.settings">Nastavení</Trans>
              </BreadcrumbItem>
            </Breadcrumb>
          </React.Fragment>
        }
      >
        <Layout.Content>
          {!isLoading && (
            <React.Fragment>
              <GroupDetail
                isLoading={isLoading}
                groupUpdate={this.updateGroup}
                group={this.state.group}
                activeTab={activeTab}
                toggleTab={this.toggleTab}
                deleteEmailAddressFromGroup={this.deleteEmailAddressFromGroup}
                addEmailAddressToGroup={this.addEmailAddressToGroup}
                toggleAddEmailAddresses={this.toggleAddEmailAddresses}
                addEmailAddressesIsOpen={this.state.addEmailAddresses.isOpen}
                addEmailAddresses={this.addEmailAddresses}
                toggleDeleteConfirmation={this.toggleDeleteConfirmation}
                user={this.state.user}
              />
              <DeleteGroupConfirmationDialog
                isOpen={this.state.deleteConfirmation.isOpen}
                toggle={this.toggleDeleteConfirmation}
                onConfirmed={this.deleteGroup}
                name={this.state.group.name}
              />
            </React.Fragment>
          )}
        </Layout.Content>
      </Layout>
    );
  }
}

const tourSteps = t => {
  const step = num => t("groupEdit.tutorial.step" + num);
  const steps = [
    { selector: "[data-tut='className']", content: step(1) },
    { selector: "[data-tut='schoolYear']", content: step(2) },
    {
      selector: "[data-tut='isFavourite']",
      content: step(3),
      position: "left"
    },
    { selector: "[data-tut='isArchived']", content: step(4), position: "left" },
    { selector: "[data-tut='classColor']", content: step(5) },
    { selector: "[data-tut='classMembers']", content: step(7) },
    { selector: "[data-tut='removeClass']", content: step(8) }
  ];
  return steps;
};

export default withEverything(withTour(GroupEditPage, tourSteps));
