import React from "react";
import * as Yup from "yup";
import { withTranslation } from "react-i18next";
import i18n from "i18next";
import withNotification from "../withNotification";
import Layout from "../Layout";
import { Field, Form, Formik } from "formik";
import {
  Col,
  Container,
  Label,
  Row,
  Button,
} from "reactstrap";
import { ReactstrapInput } from "reactstrap-formik";
import DataService from "../DataService";
import ReCaptcha from "../Auth/Recaptcha";
import Dropzone from "./Dropzone";
import setYupLocale from "../yup";
import { detect } from "detect-browser";

setYupLocale("helpdesk");

const BugSchema = Yup.object().shape({
  name: Yup.string().max(50),
  emailAddress: Yup.string().email().required(),
  description: Yup.string().max(4096),
  files: Yup.array().of(Yup.mixed())
});

class HelpdeskForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      pending: false,
      modal: false,
      error: null,
      currentBreakpoint: "lg",
      mounted: false,
    };
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };
  refresh = lng => {
    if (!lng) {
      lng = i18n.language;
    }
  };
  onError = error => {
    this.setState({
      error,
      isPending: false
    });

    this.props.notify(error);
  };
  componentDidMount() {
    this.refresh();
    this.setState({ mounted: true });
    const that = this;
    i18n.on("languageChanged", function (lng) {
      that.refresh(lng);
    });
  }

  convertImg = file => new Promise( resolve => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const image = new Image();
    image.onload = function() {
      canvas.width = image.width;
      canvas.height = image.height;
      
      ctx.drawImage(image, 0, 0);

      const mediumQuality = canvas.toDataURL('image/jpeg', 0.8);

      resolve(mediumQuality)
    };
    image.src = file.base64;
  });

  render() {
    const { t } = this.props;

    return (
      <Layout
        title={this.props.t("helpdesk.helpForm.title")}
        isTitleCentered={true}
        isFullWidth={false}
      >
        <Layout.Content>
          {!this.state.isLoading ? (
            <>
              <div className="m-2 mb-3">
                <p className="mb-1">{this.props.t("helpdesk.helpForm.p1")}</p>
                <p>{this.props.t("helpdesk.helpForm.p2")}</p>
              </div> 
              <Formik
                initialValues={{
                  name: "",
                  emailAddress: "",
                  troubleName: "",
                  description: "",
                  files: "",
                  recaptcha: "",
                }}
                validationSchema={BugSchema}
                onSubmit={async (values) => {
                  // wait until all will be done
                  this.setState({ isLoading: true });
                  // add browser info
                  const browser = detect();
                  if (browser) {
                    values.os = browser.os;
                    values.browser = browser.name + ' ' + browser.version;
                  } else {
                    values.os = 'undefinated';
                    values.browser = 'undefinated';
                  }

                  // add harcoded type
                  values.type = "question";
                  values.server = window.location.origin;

                  const files = values.files;
                  values.files = [];
                  for (let i = 0; i < files.length; i++) {
                    // convert to jpg
                    let base64String = await this.convertImg(files[i]);
                    // big trouble!!! it is important to remove data type definition
                    const regexRemoveTypeImgType = /([a-zA-Z0-9;/:]+),/g;
                    base64String = base64String.replace(regexRemoveTypeImgType, "");
                    // rename img to jpg
                    const regex = /\.[a-zA-Z]+/g;
                    const newFileName = files[i].name.replace(regex, ".jpg");

                    values.files.push({
                      name: newFileName,
                      content: base64String
                    });
                  }

                  // console.log('form data', values)
                  new DataService()
                    .postHelpdeskHelp(values)
                    .then(this.props.processNotifications)
                    .then(() => {
                      this.setState({ isLoading: false });
                      this.props.notify({type: 'success', text: t("helpdesk.helpForm.success")});
                      this.props.history.push("/podpora");
                    })
                    .catch(error => {
                      this.onError(error);
                    });
                }}
                render={aa => {
                  const { errors, touched, values, setFieldValue } = aa;
                  // console.log("formik", errors);
                  return (
                    <Form>
                      <Container style={{ paddingTop: "5px" }}>
                        <Row>
                          <Col>
                            <Field
                              label={t("helpdesk.helpForm.name")}
                              name="name"
                              id="name"
                              component={ReactstrapInput}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Field
                              type="email"
                              label={t("helpdesk.helpForm.emailAddress")}
                              name="emailAddress"
                              id="emailAddress"
                              component={ReactstrapInput}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col className="mb-3">
                            <Label for="description">{t("helpdesk.helpForm.description")}</Label>
                            <Field
                              className={`w-100 form-control ${errors.description && touched.description ? "is-invalid" : ""}`}
                              name="description"
                              id="description"
                              placeholder={t("helpdesk.helpForm.descriptionHelp")}
                              component="textarea" 
                              rows="4"
                            />
                            {errors.description && touched.description ? (
                              <div className="invalid-feedback">{errors.description}</div>
                            ) : null}
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Label for="files">{t("helpdesk.helpForm.additionalPictures")}</Label>
                            <Field
                              name="files"
                              id="files"
                              component={Dropzone}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col style={{ display: "flex", justifyContent: "center" }}>
                            <ReCaptcha
                              ref={ref => (this.recaptcha = ref)}
                              onVerificationCallback={key =>
                                setFieldValue("recaptcha", key)
                              }
                            />
                          </Col>
                        </Row>
                        <Row className="mt-2 text-center">
                          <Col style={{ display: "flex", justifyContent: "center" }}>
                            <Button
                              type="submit"
                              className="mt-1"
                              disabled={values.recaptcha === ""}
                            >
                              {t("helpdesk.helpForm.send")}
                            </Button>
                          </Col>
                        </Row>
                      </Container>
                    </Form>
                  );
                }}
              />
            </>
          ) : <div className="text-center">{this.props.t("helpdesk.wait")}</div> }
        </Layout.Content>
      </Layout>
    );
  }
}

export default withTranslation()(withNotification(HelpdeskForm));