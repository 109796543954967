import React from "react";
import { Row, Col } from "reactstrap";
import { Trans, withTranslation } from "react-i18next";
import i18n from "i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import parse from "url-parse";
import DataService from "./DataService";
import withNotification from "./withNotification";
import withTour from "./withTour";
import Layout from "./Layout";
import EnrolmentButtons from "./EnrolmentButtons";
import Logos from "./components/Logos/Logos";
import MobileFriendlyIcon from './components/MobileFriendlyIcon/MobileFriendlyIcon';
import "./CatalogExerciseDetail.scss";

class CatalogExerciseDetail extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      error: null,
      pending: false,
      data: {
        exercise: {},
        warnings: [],
      },
    };
  }

  componentDidMount() {
    this.refresh();
  }

  refresh() {
    this.setState({ isLoading: true });
    const { slug } = this.props.match.params;
    new DataService().fetchCatalogExercise(slug).then(
      result =>
        this.setState({
          data: result.data,
          isLoading: false,
        }),
      error =>
        this.setState({
          error,
          isLoading: false,
        })
    );
  }

  makeKeywords(keywords) {
    const mergedKeywords = [];

    for (const key in keywords) {
      if (Object.hasOwnProperty.call(keywords, key)) {
        const parsedKeywords = keywords[key].map(keyword => ({
          name: keyword,
          category: key,
        }));

        mergedKeywords.push(...parsedKeywords);
      }
    }

    return mergedKeywords.map((keyword, index) => (
      <abbr
        className="keyword"
        title={this.props.t(`filter.${keyword.category}`)}
        key={index}
      >
        {keyword.name}
      </abbr>
    ));
  }

  render() {
    // NOTE: Jakej je rozdíl mezi `pending` a `isLoading`?
    const { pending, data, isLoading } = this.state;
    const { exercise } = data;
    if (!isLoading) {
      let description = exercise.metadata.anotace.verejna || "";
      if (description) {
        description =
          description.length < 120
            ? description
            : `${description.substring(0, 120)}...`;
      }
    }

    const tryItUrl = parse(exercise.tryItUrl, true);
    const { query } = tryItUrl;
    query.lang = i18n.language;
    tryItUrl.set("query", query);

    // const style = isLoading
    //   ? {}
    //   : { backgroundImage: "url(" + exercise.image_url + ")" };

    // <div id="top" className="loading template-exercise-preview">
    // <MainHeader />
    // <main className="main">
    // <section className="section" style={style}>
    // <div className="section-inner-detail">
    // <div className="section-item ">
    //
    // </div>
    // </div>
    // </section>
    // </main>
    // </div>
    // { i18n.t("catalogExerciseDetail.exerciseBy", { count: authors.length }) }

    const getImage = (image, name = "", color) => (
      <div
        className="CatalogExerciseDetail__image"
        style={
          color && {
            background: `hsl(${color.hsv.h}, ${color.hsv.s}%, ${color.hsv.v - 20
              }%)`,
          }
        }
      >
        <img src={image} alt={name} />
      </div>
    );

    const getName = name => (
      <div className="CatalogExerciseDetail__name">
        <h1>{name}</h1>
      </div>
    );

    const getMeta = exercise => (
      <div className="CatalogExerciseDetail__meta">
        <div
          className="CatalogExerciseDetail__authors"
          data-caption={this.props.t("catalogExerciseDetail.exerciseBy", {
            count: exercise.metadata.autor.length,
          })}
        >
          {exercise.metadata.autor.join(", ")}
        </div>

        <div
          className="CatalogExerciseDetail__keywords"
          data-caption={this.props.t(
            "catalogExerciseDetail.keywords",
            "Klíčová slova"
          )}
        >
          <div className="items">
            {this.makeKeywords(exercise.metadata.klicovaSlova)}
          </div>
        </div>

        <div
          className="CatalogExerciseDetail__time"
          data-caption={this.props.t(
            "catalogExerciseDetail.duration",
            "Časová náročnost"
          )}
        >
          {exercise.estimatedTime}
          &thinsp;min
        </div>

        <div
          className="CatalogExerciseDetail__difficulty"
          data-caption={this.props.t(
            "catalogExerciseDetail.difficulty",
            "Obtížnost"
          )}
        >
          <div className="items">{exercise.metadata.obtiznost}</div>
        </div>

        <div
          className="CatalogExerciseDetail__mobileFriendly"
          data-caption={this.props.t(
            "catalogExerciseDetail.mobile",
            "Mobilní zařízení"
          )}
        >
          <div className="items">
            <MobileFriendlyIcon mobileData={exercise.metadata.mobileData} />
            {exercise.metadata.mobileData.mobileFriendly === 1 ? (
              <Trans i18nKey="catalogExerciseDetail.mobileData.withLimitations">
                s omezením
              </Trans>
            ) : exercise.metadata.mobileData.mobileFriendly === 2 ? (
              <Trans i18nKey="catalogExerciseDetail.mobileData.suitable">
                vhodné
              </Trans>
            ) : (
              <Trans i18nKey="catalogExerciseDetail.mobileData.unsuitable">
                nevhodné
              </Trans>
            )}
          </div>
        </div>

        <div
          className="CatalogExerciseDetail__guide"
          data-caption={this.props.t("catalogExerciseDetail.guide")}
          data-tut="guide"
        >
          <a href={exercise.instructionsUrl} download>
            <FontAwesomeIcon size="sm" icon={["far", "file-alt"]} />
            <span>{this.props.t("catalogExerciseDetail.download")}</span>
          </a>
        </div>
      </div>
    );

    const getDescription = description => (
      <div className="CatalogExerciseDetail__description">
        <p>{description}</p>
      </div>
    );

    return (
      <Layout
        title={false}
        // isTitleCentered={true}
        isFullWidth
        isHeaderAbsolutePositioned
        isHeaderInverted
        className="CatalogExerciseDetail"
      // style={style}
      >
        <Layout.Content>
          {pending && (
            <Row>
              <Col>
                <div className="text-center">
                  <Trans i18nKey="pleaseWait">Prosím, počkejte ...</Trans>
                </div>
              </Col>
            </Row>
          )}

          {!isLoading && (
            <>
              {exercise.image_url &&
                getImage(
                  exercise.image_url,
                  exercise.name,
                  exercise.metadata.color
                )}

              <div className="CatalogExerciseDetail__body">
                {exercise.name && getName(exercise.name)}

                {exercise && getMeta(exercise)}

                {exercise.metadata.anotace.ucitel &&
                  getDescription(exercise.metadata.anotace.ucitel)}

                {exercise.metadata.partners && (
                  <div className="CatalogExerciseDetail__logos">
                    <Logos
                      logos={exercise.metadata.partners}
                      heading={
                        <Trans i18nKey="catalogExerciseDetail.partners" />
                      }
                    />
                  </div>
                )}
              </div>

              {exercise && (
                <div className="CatalogExerciseDetail__buttons">
                  <EnrolmentButtons
                    url={tryItUrl.href}
                    data={this.state.data}
                    history={this.props.history}
                  />
                </div>
              )}
            </>
          )}
        </Layout.Content>
      </Layout>
    );
  }
}

const tourSteps = t => {
  const step = value => t(`catalogExerciseDetail.tutorial.${value}`);
  const steps = [
    {
      selector: "[data-tut='enrolment']",
      content: step("enrolment"),
      position: "top",
    },
    { selector: "[data-tut='try']", content: step("try"), position: "top" },
    {
      selector: "[data-tut='guide']",
      content: step("guide"),
      position: "bottom",
    },
  ];
  return steps;
};

export default withTranslation()(
  withNotification(withTour(CatalogExerciseDetail, tourSteps))
);
