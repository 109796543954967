import React, { Component } from "react";
// import PropTypes from "prop-types";
import { Trans, withTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import classnames from "classnames";
import ExerciseIcon from "../ExerciseIcon";
import EnrolmentButtons from "../EnrolmentButtons";
import "./scss/TimelineExerciseDetail.scss";

// Component for Detail of Exercise in Timeline (side bar Detail)
class TimelineExerciseDetail extends Component {
  getAuthorCaption() {
    const typeOfAutors =
      this.props.exercise.metadata.autor.length > 1
        ? "exerciseByMany"
        : "exerciseByOne";

    return this.props.translation(`timeline.exerciseDetail.${typeOfAutors}`);
  }

  makeDifficulty(difficulty) {
    if (difficulty === "nízká") {
      return (
        <span>
          <Trans i18nKey="timelineExercise.easy">
            Jednoduché
            <br />
            cvičení
          </Trans>
        </span>
      );
    }
  }

  makeKeywords(keywords) {
    const mergedKeywords = [];

    for (const key in keywords) {
      if (Object.hasOwnProperty.call(keywords, key)) {
        const parsedKeywords = keywords[key].map(keyword => ({
          name: keyword,
          category: key,
        }));

        mergedKeywords.push(...parsedKeywords);
      }
    }

    return mergedKeywords.map((keyword, index) => (
      <abbr
        className="keyword"
        title={this.props.t(`filter.${keyword.category}`)}
        key={index}
      >
        {keyword.name}
      </abbr>
    ));
  }

  render() {
    const { exercise, variant, visibility } = this.props;
    if (exercise) exercise.exerciseId = exercise.id; // Add exerciseId if exercise is loaded (must have because Enrolment need exerciseId, not id)
    return (
      <>
        <div
          className={classnames("TimelineExerciseDetail", "detail--activity", {
            "is--hidden": !visibility,
            [`TimelineExerciseDetail--${variant}`]: variant,
          })}
          cy-data="TimelineExerciseDetail"
        >
          <div className="detail__close">
            <button onClick={this.props.handleActivityDetailEvent}>
              <ExerciseIcon type="times" />
            </button>
          </div>

          <div className="detail__image">
            <img src={exercise.image_url} alt={exercise.name} />
          </div>

          <div className="detail__body">
            {exercise !== "" && (
              <div className="detail__header">
                <div
                  className="detail__time"
                  data-caption={this.props.t(
                    "catalogExerciseDetail.duration",
                    "Časová náročnost"
                  )}
                >
                  {exercise.estimatedTime}
                  &thinsp;min
                </div>

                <div
                  className="detail__difficulty"
                  data-caption={this.props.t(
                    "catalogExerciseDetail.difficulty",
                    "Obtížnost"
                  )}
                >
                  <div className="items">{exercise.metadata.obtiznost}</div>
                </div>

                <div
                  className="detail__epochs"
                  data-caption={this.props.t(
                    "timeline.exerciseDetail.epoch",
                    "Období"
                  )}
                >
                  <div className="items">
                    {exercise.metadata.casovaOsa.epochy
                      .map((history, index) => {
                        if (history.obdobi.length > 0) {
                          return (
                            <span
                              key={history.refId}
                              className="badge--history"
                              style={{
                                color: `var(--color-${history.refId})`,
                              }}
                            >
                              {history.obdobi.join(", ")}
                            </span>
                          );
                        }
                        return "";
                      })
                      .reverse()}
                  </div>
                </div>

                <div
                  className="detail__years"
                  data-caption={this.props.t(
                    "timeline.exerciseDetail.timeDetermination",
                    "Časové určení"
                  )}
                >
                  <div className="items">
                    <span className="badge--year">
                      {exercise.metadata.casovaOsa.roky.join(", ")}
                    </span>
                  </div>
                </div>

                <div
                  className="detail__keywords"
                  data-caption={this.props.t(
                    "catalogExerciseDetail.keywords",
                    "Klíčová slova"
                  )}
                >
                  <div className="items">
                    {this.makeKeywords(exercise.metadata.klicovaSlova)}
                  </div>
                </div>

                <div
                  className="CatalogExerciseDetail__guide"
                  data-caption={this.props.t("catalogExerciseDetail.guide")}
                >
                  <a href={exercise.instructionsUrl} download>
                    <FontAwesomeIcon size="sm" icon={["far", "file-alt"]} />
                    <span>
                      {this.props.t("catalogExerciseDetail.download")}
                    </span>
                  </a>
                </div>
              </div>
            )}

            <div className="detail__name">
              <h2 data-cy="exercise-detail">{exercise.name}</h2>
            </div>

            {exercise !== "" && (
              <div className="detail__description">
                <p>{exercise.metadata.anotace.ucitel}</p>
                <div className="detail__more">
                  <Link to={`/katalog/cviceni/${exercise.metadata.slug}`}>
                    <Trans i18nKey="timeline.exerciseDetail.moreAbout">
                      Více o cvičení
                    </Trans> <span>→</span>
                  </Link>
                </div>
              </div>
            )}

            {exercise && (
              <div className="detail__buttons">
                <EnrolmentButtons
                  size="md"
                  vertical
                  url={exercise.tryItUrl}
                  data={{ exercise }}
                  history={this.props.history}
                />
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

TimelineExerciseDetail.propTypes = {};

export default withTranslation()(TimelineExerciseDetail);
