import React from "react";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import {
  Col,
  Container,
  FormGroup,
  Label,
  Row,
  FormFeedback,
  Input,
  Button,
  CustomInput,
} from "reactstrap";
import { ReactstrapInput } from "reactstrap-formik";
import { withTranslation, Trans } from "react-i18next";
import setYupLocale from "../../yup";
import { faBook } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./accountForm.scss";
import env from "@beam-australia/react-env";

setYupLocale("accountChange");

const RegistrationSchema = Yup.object().shape({
  firstName: Yup.string().max(50),
  institution: Yup.string().max(255),
  lastName: Yup.string().max(50),
});

const ReactstrapSelectInput = ({
  field,
  form: { touched, errors },
  disabled = false,
  ...props
}) => {
  const error = errors[field.name];
  const touch = touched[field.name];
  return (
    <FormGroup>
      <Label for={props.inputprops.id} className="label-color">
        {props.label}
      </Label>
      <Input
        id={props.inputprops.id}
        {...field}
        {...props}
        type="select"
        invalid={Boolean(touched[field.name] && errors[field.name])}
        placeholder="Test"
      >
        {props.inputprops.options.map((option, index) => {
          if (option.name)
            return (
              <option value={option.id} key={index}>
                {option.name}
              </option>
            );
          return (
            <option value={option} key={index}>
              {option}
            </option>
          );
        })}
      </Input>
      {touch && error && <FormFeedback>{error}</FormFeedback>}
    </FormGroup>
  );
};

class AccountForm extends React.Component {
  render() {
    const { onSubmit, t, user } = this.props;
    const { isPending } = this.props;

    const getLabelWithIcon = (text, icon) => (
      <div>
        {icon && <FontAwesomeIcon icon={icon} className="mr-2" />}
        {t(text)}
      </div>
    );

    return (
      <div>
        <Formik
          initialValues={{
            firstName: user.firstName || "",
            lastName: user.lastName || "",
            institution: user.institution || "",
            teacher: user.isTeacher ? t("yes", "Ano") : t("no", "Ne"),
            projects: {
              "d-plus": user.projects.some(p => p === "d-plus"),
            },
          }}
          validationSchema={RegistrationSchema}
          onSubmit={(values, actions) => {
            onSubmit(values, error => {
              if (error.form) {
                actions.setErrors(error.form);
                return true;
              }
              return false;
            });
          }}
          render={aa => {
            const { values, setFieldValue } = aa;
            return (
              <Form>
                <Container style={{ paddingTop: "5px" }}>
                  <Row>
                    <Col>
                      <Field
                        label={t("firstName", "Jméno")}
                        name="firstName"
                        id="firstName"
                        component={ReactstrapInput}
                      />
                    </Col>
                    <Col>
                      <Field
                        label={t("lastName", "Přijmení")}
                        name="lastName"
                        id="lastName"
                        component={ReactstrapInput}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Field
                        label={t("institution", "Instituce, kde působím")}
                        name="institution"
                        id="institution"
                        component={ReactstrapInput}
                      />
                    </Col>
                    <Col sm={3}>
                      <Field
                        name="teacher"
                        label={t("teacher", "Jsem učitel")}
                        id="teacher"
                        inputprops={{
                          name: "teacher",
                          label: "",
                          id: "teacher",
                          options: [t("no", "Ne"), t("yes", "Ano")],
                          defaultOption: t("no", "Ne"),
                        }}
                        component={ReactstrapSelectInput}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="hl-divider">
                        <Trans i18nKey="account.projects.label">Projekty</Trans>
                      </div>
                    </Col>
                  </Row>
                  <Row className="text-center">
                    <Col style={{ display: "flex", justifyContent: "start" }}>
                      <CustomInput
                        type="switch"
                        id="d-plus"
                        className="form-control-lg"
                        name="projects.d-plus"
                        label={getLabelWithIcon(
                          "account.projects.DPlus",
                          faBook
                        )}
                        checked={values.projects["d-plus"]}
                        onChange={() => {
                          if (values.projects["d-plus"]) {
                            setFieldValue(
                              "projects.d-plus",
                              !values.projects["d-plus"]
                            );
                            return;
                          }
                          const isDPlus = prompt(
                            t("account.projects.pinModal"),
                            ""
                          );
                          if (isDPlus !== env("PROJECTS_DPLUS_PIN")) {
                            window.alert(t("account.projects.wrongPin"));
                          } else {
                            setFieldValue(
                              "projects.d-plus",
                              !values.projects["d-plus"]
                            );
                          }
                        }}
                        data-tut="DPlus"
                      />
                    </Col>
                  </Row>
                  <Row className="text-center">
                    <Col style={{ display: "flex", justifyContent: "center" }}>
                      <Button
                        type="submit"
                        color="primary"
                        disabled={isPending}
                      >
                        <Trans i18nKey="account.save">Uložit</Trans>
                      </Button>
                    </Col>
                  </Row>
                </Container>
              </Form>
            );
          }}
        />
      </div>
    );
  }
}

export default withTranslation()(AccountForm);
