import "./fonts/fonts.css";
import "./css/index.scss";
import "./css/App.css";
import "react-notifications/lib/notifications.css";
import React, { Component } from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import { loadReCaptcha } from "react-recaptcha-google";
import ReactGA from "react-ga";
import TagManager from "react-gtm-module";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faAngleRight,
  faInfo,
  faPlus,
  faUserPlus,
  faTrashAlt,
  faQuestion,
  faFilePdf,
  faPeopleArrows,
} from "@fortawesome/free-solid-svg-icons";
import env from "@beam-australia/react-env";
import Catalog from "./catalog/Catalog";
import Timeline from "./timeline/Timeline";
import ExerciseDetail from "./exercises/ExerciseDetail";
import ResearchDetailPage from "./exercises/research/pages/ResearchDetailPage";
import ExerciseList from "./ExerciseList";
import Home from "./Home";
import CatalogExerciseDetail from "./CatalogExerciseDetail";
import Peeping from "./peeping/Peeping";
import Registration from "./users/Registration";
import withTracker from "./With/withTracker";
import ForgotPasswordForm from "./users/ForgotPassword";
import ResetPassword from "./users/ResetPassword";
import CookieConsent from "./CookieConsent";
import GroupList from "./groups/pages/GroupList";
import GroupEditPage from "./groups/pages/GroupEditPage";
import Layout from "./Layout";
import VCGrid from "./visual-cloud/templates/VCGrid";
import HelpdeskPage from "./helpdesk";
import HelpdeskFormPage from "./helpdesk/HelpdeskFormPage";
import BugFormPage from "./helpdesk/BugFormPage";
import MethodicalSupportPage from "./helpdesk/MethodicalSupportPage";
import Account from "./components/account/Account";
import Administration from "./administration/Administration";

library.add(
  faPlus,
  faInfo,
  faUserPlus,
  faAngleRight,
  faTrashAlt,
  faQuestion,
  faFilePdf,
  faPeopleArrows
);

ReactGA.initialize(env("GOOGLE_ANALYTICS"), {
  debug: env("NODE_ENV") !== "production",
});

TagManager.initialize({
  gtmId: env("GOOGLE_TAG_MANAGER"),
});

const NoMatch = props => {
  const { location } = props;
  if (location.pathname.match(/^\/cviceni\//)) {
    const url = `${window.location.protocol}//app.${window.location.hostname}${window.location.pathname}${window.location.search}`;
    return (
      <Layout
        title="Stránka byla přesunuta"
        isTitleCentered
        className="Catalog"
      >
        <Layout.Content>
          <div>
            <a href={url}>{url}</a>
          </div>
        </Layout.Content>
      </Layout>
    );
  }
  return (
    <div>
      <h3>
        No match for <code>{location.pathname}</code>
      </h3>
    </div>
  );
};

class App extends Component {
  componentDidMount() {
    loadReCaptcha();
  }

  render() {
    const { location } = this.props;

    return (
      <>
        <Switch location={location}>
          <Route exact path="/" component={withTracker(Home)} />

          <Route exact path="/katalog" component={withTracker(Catalog)} />
          <Route
            exact
            path="/katalog-starsich-dejin"
            component={withTracker(Catalog)}
          />
          <Route
            path="/katalog/cviceni/:slug"
            component={withTracker(CatalogExerciseDetail)}
          />
          <Route exact path="/timeline" component={withTracker(Timeline)} />
          <Route exact path="/gallery" component={withTracker(VCGrid)} />
          <Route exact path="/casova-osa" component={withTracker(Timeline)} />
          <Route exact path="/galerie" component={withTracker(VCGrid)} />
          <Route exact path="/trida/:id?" component={GroupEditPage} />
          <Route exact path="/tridy" component={GroupList} />
          <Redirect from="/moje" to="/tridy" />
          <Route
            exact
            path="/tridy/:id"
            component={withTracker(ExerciseList)}
          />
          <Route exact path="/tridy/:id/detail/" component={GroupEditPage} />

          <Route
            path="/tridy/:tid/cviceni/:id"
            component={withTracker(ExerciseDetail)}
          />

          <Route
            path="/tridy/:classId/pruzkum/:researchId"
            component={withTracker(ResearchDetailPage)}
          />

          <Route exact path="/registrace" component={Registration} />
          <Route exact path="/prihlasit" component={withTracker(GroupList)} />
          <Route exact path="/reset-hesla" component={ResetPassword} />
          <Route
            exact
            path="/zapomenute-heslo"
            component={ForgotPasswordForm}
          />
          <Route exact path="/ucet" component={withTracker(Account)} />

          <Route exact path="/peeping" component={Peeping} />
          <Route exact path="/admin" component={Administration} />

          <Route
            exact
            path="/metodika"
            render={() =>
              (window.location = window.origin.replace("lms", "metodika"))
            }
          />

          <Route exact path="/podpora" component={withTracker(HelpdeskPage)} />
          <Route
            exact
            path="/podpora/metodicka-podpora"
            component={withTracker(MethodicalSupportPage)}
          />
          <Redirect from="/helpdesk" to="/podpora/formular/helpdesk" />
          <Route
            exact
            path="/podpora/formular/helpdesk"
            component={withTracker(HelpdeskFormPage)}
          />
          <Route
            exact
            path="/podpora/formular/chyba"
            component={withTracker(BugFormPage)}
          />
          <Route
            path="/podpora/pdf-navod"
            component={() => {
              window.location.href = `${env(
                "SERVER_URL"
              )}/pdf/podpora-napoveda.pdf`;
              return null;
            }}
          />

          <Route component={NoMatch} />
        </Switch>
        <CookieConsent />
      </>
    );
  }
}

export default App;
