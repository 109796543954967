import React from "react";
import * as Yup from "yup";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { withTranslation, Trans } from "react-i18next";

const DeleteGroupConfirmationDialog = ({
  isOpen,
  toggle,
  onConfirmed,
  name,
  t,
}) => (
  <Modal isOpen={isOpen} toggle={toggle} backdrop>
    <ModalHeader toggle={toggle}>
      <Trans i18nKey="deleteGroupConfirmationDialog.header" />
    </ModalHeader>
    <ModalBody>
      {Yup.ValidationError.formatError(
        t("deleteGroupConfirmationDialog.confirmation"),
        { name }
      )}
    </ModalBody>
    <ModalFooter>
      <Button color="secondary" onClick={onConfirmed} data-cy={"group-delete-yes"}>
        <Trans i18nKey="yes" />
      </Button>
      <Button color="primary" onClick={toggle}>
        <Trans i18nKey="no" />
      </Button>
    </ModalFooter>
  </Modal>
);

export default withTranslation()(DeleteGroupConfirmationDialog);
