import React from "react";
import "./css/Header.css";

const Catalog = ({ title, children }) => (
  <header>
    <h1>{title}</h1>
    {children}
  </header>
);

export default Catalog;
