import React from "react";
import { withTranslation } from "react-i18next";
import withNotification from "../withNotification";
// import ExerciseIcon from "../ExerciseIcon";
import "./scss/Time.scss";
// import { Button } from "reactstrap";
// import { Trans } from "react-i18next";
class Time extends React.Component {
  state = {
    epochDetail: {
      visibility: "hidden",
      data: "",
      scroll: ""
    },
    setup: {
      left: "world",
      right: "czech"
    },
    timelineSetup: {
      height: 0,
      start: 0,
      end: 0,
      step: 0
    },
    computedVariables: {
      numberOftimes: 0,
      timelineStart: 0,
      timelineEnd: 0,
      numberOfDecades: 0,
      oneYearVH: 0
    }
  };

  componentDidMount() {
    this.setState({ mounted: true });
  }

  componentDidUpdate() {
    if (
      JSON.stringify(this.state.timelineSetup) !==
      JSON.stringify(this.props.timelineSetup)
    ) {
      const { start, end, step, height } = this.props.timelineSetup;
      let timelineSetup = { ...this.state.timelineSetup };
      timelineSetup.end = end;
      timelineSetup.start = start;
      timelineSetup.step = step;
      timelineSetup.height = height;
      const computedVariables = {};
      computedVariables.numberOftimes = (end - start) / step + 1;
      computedVariables.timelineStart = start - step;
      computedVariables.timelineEnd = end + step;
      computedVariables.numberOfDecades =
        (computedVariables.timelineEnd - computedVariables.timelineStart) /
        step;
      computedVariables.oneYearVH = height / step;
      this.setState({ timelineSetup, computedVariables }, () => {
        this.addObserverIntoEpochs();
      });
    }
  }

  // TODO: `this.state.epochDetail.data` by měl obsahovat k jakým dějinám patří
  render() {
    // const { t } = this.props;
    
    return (
      <div
        className={
          "timeline__item timeline__time " + this.state.epochDetail.scroll
        }
        style={{
          height:
            this.state.computedVariables.numberOfDecades *
              this.state.timelineSetup.height +
            "vh"
        }}
      >
        <div className="timeline__itself">
          <div className="timeline__names">
            <div className="timeline__name">
              {this.getNameOfSideBar(this.state.setup.left)}
            </div>
            <div className="timeline__name">
              {this.getNameOfSideBar(this.state.setup.right)}
            </div>
          </div>
          {this.generateTimeLine()}
          {this.generateYears()}
          {this.generateSidebarTimeline("left", this.state.setup.left)}
          {this.generateSidebarTimeline("right", this.state.setup.right)}
        </div>

        {/*<div
          className={`detail--epoch ${this.state.epochDetail.visibility}`}
          style={{
            backgroundColor: `var(--color-${this.state.epochDetail.data.history}`
          }}
          data-cy="epoch-detail"
        >
          <div className="detail__inner">
            <div className="detail__close">
              <button onClick={e => this.handleEpochDetailEvent(e)}>
                <ExerciseIcon type="times" />
              </button>
            </div>

            <div className="detail__body">
              <div className="detail__header">
                <div
                  className="detail__history"
                  data-caption={t("timeline.epochDetail.category")}
                >
                  <div className="items">
                    {this.state.epochDetail.data.positionName}
                  </div>
                </div>
                <div className="detail__year" data-caption="">
                  <div className="items">
                    {this.state.epochDetail.data.years &&
                      this.state.epochDetail.data.years.start +
                        " – " +
                        this.state.epochDetail.data.years.end}
                  </div>
                </div>
              </div>

              <div className="detail__name">
                <h3>{this.state.epochDetail.data.name}</h3>
              </div>

              <div className="detail__text">
                <p>
                  Labore eiusmod officia eu magna proident mollit. Ut aliqua
                  nisi irure anim quis officia elit fugiat cillum occaecat
                  proident mollit. Nulla incididunt nostrud officia occaecat.
                  Non laboris sit duis proident incididunt do deserunt ea
                  cillum. Consectetur tempor excepteur proident excepteur
                  excepteur voluptate mollit qui ullamco minim magna Lorem
                  eiusmod. Est mollit do mollit nulla reprehenderit ut commodo
                  minim duis veniam ad cupidatat aliqua occaecat. Labore aute
                  commodo anim ex.
                </p>
              </div>
            </div>
          </div>
        </div>*/}
      </div>
    );
  }

  /**
   * Split axis (timeline) into decades (it is necessarry for observer)
   */
  generateTimeLine() {
    const namesList = function (numberOftimes) {
      let arrayList = [];
      for (let i = 0; i <= numberOftimes; i++) {
        arrayList.push(
          <li data-decade key={"timeline__" + i} data-decadeindex={i}>
            <div className="decade-line" />
            <div className="middle-name" />
          </li>
        );
      }
      return arrayList;
    };
    return (
      <ul className="timeline__list">
        {namesList(this.state.computedVariables.numberOftimes)}
      </ul>
    );
  }

  /**
   * Generate coresponding years for decade (white bubles with number which represents a year)
   */
  generateYears() {
    const { t } = this.props;
    const nameDejiny = t("timeline.history");
    let namesList = function () {
      let arrayList = [];
      for (let i = 0; i <= this.state.computedVariables.numberOftimes; i++) {
        arrayList.push(
          <li
            key={"time-" + i}
            className="year"
            data-decadeindex={i}
            style={{
              height: this.state.timelineSetup.height + "vh"
            }}
          >
            <div>
              {i === 0
                ? `${nameDejiny}`
                : this.state.timelineSetup.start -
                  this.state.timelineSetup.step +
                  this.state.timelineSetup.step * i}
            </div>
          </li>
        );
      }
      return arrayList;
    }.bind(this);
    return <ul className="timeline__years">{namesList()}</ul>;
  }

  /**
   * Generate epochs from timelineSetupData
   *
   * @param {string} position "left" or "right" that means where will the epochs show at axis
   * @param {string} type meantime "ceske" or "svetove" it is slug from timelineData of whole one Timeline
   */
  generateSidebarTimeline(position, type) {
    const timelineSidebar = this.props.timelineDetailData.timelineData.find(
      timeline => {
        return timeline.refId === type;
      }
    );

    if (timelineSidebar === undefined) {
      return (
        <div>
          Nepodařilo se načíst {type} na {position} straně.{" "}
        </div>
      );
    }

    // const historyName = timelineSidebar.title;
    const historyEpochs = timelineSidebar.epochs;

    const listOfEpochs = historyEpochs.map((epoch, index) => {
      let epochStart = epoch.years.start;
      let epochEnd = epoch.years.end;

      if (epochStart <= this.state.timelineSetup.start) {
        epochStart =
          this.state.timelineSetup.start - this.state.timelineSetup.step;
      }

      if (epochEnd === 0) {
        epochEnd = this.state.timelineSetup.end + this.state.timelineSetup.step;
      }

      const heightOfEpoch =
        this.state.computedVariables.oneYearVH * (epochEnd - epochStart);

      return (
        <li
          key={`${type}-epoch-${index}`}
          className="epoch"
          style={{ height: heightOfEpoch + "vh" }}
          data-cy="epoch"
        >
          <div
            className="epoch__inner"
            style={{ backgroundColor: `var(--color-${type})` }}
            // onClick={e =>
            //   this.handleEpochClickEvent(e, {
            //     ...epoch,
            //     positionName: historyName,
            //     history: type
            //   })
            // }
          >
            <div className="name">{epoch.name}</div>
          </div>
        </li>
      );
    });

    if (historyEpochs[0].years.start > this.state.timelineSetup.start) {
      const heightOfEpoch =
        this.state.computedVariables.oneYearVH *
        (historyEpochs[0].years.start -
          this.state.computedVariables.timelineStart);

      const blankEpoch = (
        <li
          className="epoch-hidden"
          key={"world-timeline__" + -1}
          style={{ height: heightOfEpoch + "vh" }}
        />
      );

      listOfEpochs.unshift(blankEpoch);
    }
    return (
      <ul className={`timeline__epochs timeline__${position}`}>
        {listOfEpochs}
      </ul>
    );
  }

  /**
   * Click event for open detail of epoch
   *
   * @param {event} e event
   * @param {*} epoch opened epoch data
   */
  handleEpochClickEvent(e, epoch) {
    this.props.clickEpochEvent(epoch.name);
    let epochDetail = { ...this.state.epochDetail };
    epochDetail.visibility = "visible";
    epochDetail.data = epoch;
    // epochDetail.scroll = 'unscroll'
    // TODO: no DOM manipulation
    document.body.style.overflowY = "hidden";
    document.body.classList.add("unscroll");
    this.setState({ epochDetail });
  }

  /**
   * Click event for close detail of epoch
   *
   */
  handleEpochDetailEvent(e) {
    this.props.clickDetailEpochEvent(e);
    let epochDetail = { ...this.state.epochDetail };
    epochDetail.visibility = "hidden";
    // epochDetail.data = "";
    epochDetail.scroll = "";
    // TODO: no DOM manipulation
    document.body.style.overflowY = "";
    document.body.classList.remove("unscroll");
    this.setState({ epochDetail });
  }

  /**
   * Observer of decades to show only active cviceni
   *
   */
  addObserverIntoEpochs() {
    const decades = document.querySelectorAll("[data-decade]");
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.intersectionRatio > 0) {
          this.props.handleObserverDecade(
            true,
            parseInt(entry.target.dataset.decadeindex)
          );
        } else {
          this.props.handleObserverDecade(
            false,
            parseInt(entry.target.dataset.decadeindex)
          );
        }
      });
    });
    decades.forEach(decade => {
      observer.observe(decade);
    });
  }

  /**
   * Function to return coresponding name of Timeline by language setup (the short slugs)
   *
   * @param {object} slug whole data of timelineSetupData
   */
  getNameOfSideBar(slug) {
    if (this.props.timelineDetailData.timelineData === undefined) {
      return "Nan";
    }
    const timelineSidebar = this.props.timelineDetailData.timelineData.find(
      timeline => {
        return timeline.refId === slug;
      }
    );
    return timelineSidebar.title;
  }
}

export default withTranslation()(withNotification(Time));
