import React from "react";
import {
  Button,
  FormGroup,
  Label,
  Col,
  FormFeedback,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import { Trans } from "react-i18next";
import i18n from "i18next";

class EmailAddressesDialog extends React.Component {
  constructor() {
    super();
    this.state = {
      emailAddresses: "",
      formErrors: { emailAddresses: "" },
      emailAddressesValid: false,
      formValid: false,
      error: null,
      pending: false
    };
  }

  onClick = () => {
    const { exercise, onConfirmed } = this.props;
    const { emailAddresses } = this.state;
    onConfirmed(exercise, emailAddresses);
  };

  handleChange = event => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({ [name]: value }, () => {
      this.validateField(name, value);
    });
  };

  validateField = (fieldName, value) => {
    let fieldValidationErrors = this.state.formErrors;
    let emailAddressesValid = this.state.emailAddressesValid;

    switch (fieldName) {
      case "emailAddresses":
        const regex = /^([\w.-]+@([\w-]+)\.+\w{2,})(\s*[|;|,]\s*([\w.-]+@([\w-]+)\.+\w{2,}))*/;
        emailAddressesValid = value.trim().match(regex);
        fieldValidationErrors.emailAddresses = emailAddressesValid
          ? ""
          : i18n.t(
              "emailAddressesDialog.emailAddresses.format",
              "Některá ze zadaných e-mailových adres nemá odpovídající formát."
            );
        break;
      default:
        break;
    }
    this.setState(
      {
        formErrors: fieldValidationErrors,
        emailAddressesValid
      },
      this.validateForm
    );
  };

  validateForm = () => {
    this.setState({
      formValid: this.state.emailAddressesValid
    });
  };

  onClosed = () => {
    this.setState({ emailAddresses: "" });
  };

  render() {
    const { isOpen, toggle } = this.props;
    return (
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        backdrop={true}
        onClosed={this.onClosed}
      >
        <ModalHeader toggle={toggle}>
          <Trans i18nKey="emailAddressesDialog.addEmailAddresses">
            Přidat e-mailové adresy do třídy
          </Trans>
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Col sm={11}>
              <Label for="emailAddresses">
                <Trans i18nKey="emailAddressesDialog.emailAddresses.label">
                  E-mailové adresy žáků
                </Trans>
              </Label>
              <Input
                name="emailAddresses"
                id="emailAddresses"
                value={this.state.emailAddresses}
                invalid={!this.state.emailAddressesValid}
                valid={!!this.state.emailAddressesValid}
                onChange={this.handleChange}
                placeholder={i18n.t(
                  "emailAddressesDialog.emailAddresses.placeholder",
                  "e-mailové adresy žáků oddělené mezerou, čárkou nebo středníkem"
                )}
              />
              <FormFeedback>
                {this.state.formErrors.emailAddresses}
              </FormFeedback>
            </Col>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={this.onClick}
            disabled={!this.state.formValid}
          >
            <Trans i18nKey="emailAddressesDialog.add">
              Přidat e-mailové adresy
            </Trans>
          </Button>
          <Button color="secondary" onClick={toggle}>
            <Trans i18nKey="emailAddressesDialog.back">Zpět</Trans>
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default EmailAddressesDialog;
