import React from "react";
import { FormGroup, Label, FormFeedback, Input } from "reactstrap";

const ReactstrapSelectInput = ({
  field,
  form: { touched, errors },
  disabled = false,
  ...props
}) => {
  const error = errors[field.name];
  const touch = touched[field.name];
  return (
    <FormGroup>
      <Label for={props.inputprops.id} className="label-color">
        {props.label}
      </Label>
      <Input
        id={props.inputprops.id}
        {...field}
        {...props}
        type="select"
        invalid={Boolean(touched[field.name] && errors[field.name])}
        placeholder="Test"
      >
        {props.inputprops.options.map((option, index) => {
          if (option.name)
            return (
              <option
                value={option.id}
                key={index}
                defaultValue={props.inputprops.defaultOption === option.id}
              >
                {option.name}
              </option>
            );
          return (
            <option
              value={option}
              key={index}
              defaultValue={props.inputprops.defaultOption === option}
            >
              {option}
            </option>
          );
        })}
      </Input>
      {touch && error && <FormFeedback>{error}</FormFeedback>}
    </FormGroup>
  );
};

export default ReactstrapSelectInput;
