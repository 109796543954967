import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { ApplicationStateConsumer } from "./ApplicationStateProvider";
import "./css/languageSwitcher.scss";

const LanguageSwitcher = ({ languages }) => {
  const { i18n } = useTranslation();
  const location = useLocation();
  useEffect(() => {
    const langSearchParams = new URLSearchParams(location.search).get("lang");
    if (languages.find(l => l.value.some(code => code === langSearchParams))) {
      i18n.changeLanguage(langSearchParams, err => {
        if (err) return console.log("Change language by parameter error:", err);
        return 0;
      });
    }
  }, [i18n, languages, location.search]);

  const [isOpen, setIsOpen] = React.useState(false);
  const toggle = () => setIsOpen(prevState => !prevState);

  const selectedLanguage = languages.find(l =>
    l.value.some(code => code === i18n.language)
  );
  const selectedLanguageLabel = selectedLanguage
    ? selectedLanguage.label
    : "Čeština";

  return (
    <ApplicationStateConsumer>
      {({ changeLanguage }) => (
        <Dropdown
          isOpen={isOpen}
          toggle={toggle}
          className={"language-switcher"}
        >
          <DropdownToggle color={"none"} caret data-cy="language-switcher-dropdown">
            {selectedLanguageLabel}
          </DropdownToggle>
          <DropdownMenu className={"language-switcher-menu"} right data-cy="language-switcher-menu">
            {languages
              .filter(
                language => !language.value.some(code => code === i18n.language)
              )
              .map((language, index) => (
                <DropdownItem
                  className={"language-switcher-item"}
                  key={index}
                  onClick={() => changeLanguage(language.value[0])}
                  data-cy={`language-switcher-item-${language.value[0]}`}
                >
                  {language.label}
                </DropdownItem>
              ))}
          </DropdownMenu>
        </Dropdown>
      )}
    </ApplicationStateConsumer>
  );
};

LanguageSwitcher.propTypes = {
  languages: PropTypes.array,
};
/**
 * Default properties for language dropbox (uncomment to unlock Polish language).
 * */
LanguageSwitcher.defaultProps = {
  languages: [
    {
      value: ["cs"],
      label: "Čeština",
    },
    {
      value: ["en-US", "en"],
      label: "English",
    },
    {
      value: ["pl"],
      label: "Polska",
    },
    {
      value: ["sk"],
      label: "Slovenčina",
    },
  ],
};

export default LanguageSwitcher;
