import React, { Component } from "react";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

const NotificationContext = React.createContext();

export class NotificationProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: true,
      notifications: [],
    };

    this.dismiss = this.dismiss.bind(this);
  }

  dismiss(e) {
    this.setState(prevState => ({
      notifications: prevState.notifications.filter((n, i) => {
        return n.notification.id !== e.id;
      }),
    }));
  }

  notify_ = notification => {
    this.setState(prevState => ({
      notifications: [...prevState.notifications, notification],
    }));
  };

  isError = function (e) {
    return (
      (e &&
        e.stack &&
        e.message &&
        typeof e.stack === "string" &&
        typeof e.message === "string") ||
      (e &&
        e.error &&
        e.details &&
        typeof e.error === "string" &&
        typeof e.details === "string")
    );
  };

  notify = notification => {
    if (notification === null || notification === undefined) {
      return;
    }

    if (this.isError(notification)) {
      notification = this.errorToNotification(notification);
    }

    if (notification.text === undefined) {
      return;
    }

    switch (notification.type) {
      case "info":
        NotificationManager.info(notification.text);
        break;
      case "success":
        NotificationManager.success(notification.text);
        break;
      case "warning":
        NotificationManager.warning(notification.text);
        break;
      default:
        NotificationManager.error(notification.text);
        break;
    }
  };

  errorToNotification = error => {
    if (error.error && error.details) {
      return { type: "danger", text: error.error + " " + error.details };
    }

    let text = "N/A";
    let message = "N/A";
    if (error.response && error.response.data && error.response.data.message) {
      message = ": " + error.response.data.message;
    } else if (error.response && error.response.data) {
      message = ": " + error.response.data;
    }

    if (error.response) {
      text = `${error.response.statusText} (${error.response.status})${message}`;
    } else if (error.code) {
      text = `${error.message} (${error.code})${message}`;
    } else {
      text = JSON.stringify(error);
    }
    return {
      type: "danger",
      text,
    };
  };

  processNotifications = data => {
    if (data) {
      (data.notifications || []).forEach(notification => {
        this.notify(notification);
      });
    }
    return data;
  };

  render() {
    const { children } = this.props;

    return (
      <NotificationContext.Provider
        value={{
          notify: this.notify,
          dismiss: this.dismiss,
          processNotifications: this.processNotifications,
        }}
      >
        {children}
        <NotificationContainer />
      </NotificationContext.Provider>
    );
  }
}

export const NotificationConsumer = NotificationContext.Consumer;
