import React from "react";

const MobileFriendlyIcon = ({ mobileData }) => {
  if (mobileData.mobileFriendly !== 0) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 40 40"
        style={{
          fill:
            mobileData.mobileFriendly === 2
              ? "var(--success)"
              : "var(--warning)",
        }}
      >
        <path d="M25 7.06H15a2.83 2.83 0 0 0-2.83 2.83v20.22A2.83 2.83 0 0 0 15 32.94h10a2.83 2.83 0 0 0 2.83-2.83V9.89A2.83 2.83 0 0 0 25 7.06ZM20 31a1.88 1.88 0 1 1 1.88-1.87A1.88 1.88 0 0 1 20 31Z" />
      </svg>
    );
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 40"
      style={{ fill: "var(--danger)" }}
    >
      <path d="M27.86 10.73v-.84A2.83 2.83 0 0 0 25 7.06H15a2.83 2.83 0 0 0-2.83 2.83v.84L20 18.59ZM12.14 13.56v12.88L18.59 20l-6.45-6.44zM27.86 26.44V13.56L21.41 20l6.45 6.44zM12.14 29.27v.84A2.83 2.83 0 0 0 15 32.94h10a2.83 2.83 0 0 0 2.83-2.83v-.84L20 21.41Zm9.74-.17A1.88 1.88 0 1 1 20 27.22a1.88 1.88 0 0 1 1.88 1.88ZM9 29.58A1 1 0 0 0 9 31a1 1 0 0 0 1.41 0l1.72-1.72v-2.84ZM10.42 9A1 1 0 0 0 9 10.42l3.13 3.14v-2.83ZM27.86 26.44v2.83L29.58 31A1 1 0 0 0 31 31a1 1 0 0 0 0-1.41Z" />
      <path d="M31 10.42A1 1 0 0 0 29.58 9l-1.72 1.72v2.83Z" />
    </svg>
  );
};

export default MobileFriendlyIcon;
