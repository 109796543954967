import React from "react";
import { Link } from "react-router-dom";
import SupportTemplate from "./SupportTemplate";
import SupportOnlyVideo from "../components/helpdesk/SupportOnlyVideo";
import withTour from "../withTour";


function MethodicalSupportPage() {
  // smooth scroll for one page
  document.querySelector("html").style["scroll-behavior"] = "smooth";

  return (
    <SupportTemplate>{/* TODO: title to props*/}
            <div class="support__item">
        <ul>
          <li>
            <a href="#lze-s-historylabem-pracovat-na-tabletech-a-chytrych-telefonech">
              Lze s HistoryLabem pracovat na tabletech a chytrých telefonech?
            </a>
          </li>
          <li>
            <a href="#jak-se-zaregistrovat-prihlasit-do-aplikace">
              Jak se zaregistrovat/přihlásit do aplikace?
            </a>
          </li>
          <li>
            <a href="#zapomnel-jsem-heslo">Zapomněl/a jsem heslo.</a>
          </li>
          <li>
            <a href="#jak-v-historylabu-zalozim-tridu-a-pridam-do-ni-zaky">
              Jak v HistoryLabu založím třídu a přidám do ní žáky?
            </a>
          </li>
          <li>
            <a href="#jak-zadam-zakum-cviceni">Jak zadám žákům cvičení?</a>
          </li>
          <li>
            <a href="#jak-zkontroluji-odevzdana-cviceni">
              Jak zkontroluji odevzdaná cvičení?
            </a>
          </li>
        </ul>
      </div>
      <article
        class="support__item"
        id="lze-s-historylabem-pracovat-na-tabletech-a-chytrych-telefonech"
      >
        <h2 class="support__headline">
          Lze s HistoryLabem pracovat na tabletech a chytrých telefonech?
        </h2>
        <p class="support__text">
          Aplikace HistoryLab byla z počátku zamýšlena pouze pro stolní počítače. Domnívali jsme se, že náročnou analýzu historických pramenů (především dobových fotografií) bude pro dosažení nejlepšího výsledku nutné provádět na velkých obrazovkách a za použití myši a klávesnice. Uvědomujeme si však, že díky současnému technickému vybavení většiny škol roste poptávka po práci s tablety a chytrými telefony. Přizpůsobit aplikaci pro dotyková zařízení ale není po technické stránce jednoduché.
        </p>
        <p class="support__text">
          Prozatím jsme do katalogu cvičení přidali značky v podobě malého mobilního telefonu, které indikují vhodnost daného cvičení pro dotyková zařízení. Zelená cvičení jsou pro tato zařízení vhodná, s jejich vyplněním na tabletech a mobilech by neměly být žádné problémy. Žlutá cvičení by na dotykových zařízeních mělo být možné vyplnit, vyplňování však nebude pohodlné. Červená cvičení na dotykových zařízeních vyplnit nelze.
        </p>
        <p class="support__text">
          Na chytrých telefonech a tabletech lze v současnosti bez problémů prohlížet historické prameny ve všech formátech (fotografie, text, video i audio) a odpovídat na otázky. Navíc je možné provádět analýzu obrázků pomocí kreslení, přidávání popisků a přetahování tagů. Akce, které by se na stolním počítači prováděly pravým tlačítkem myši, se zde dělají delším podržením prstu na fotografii. Tyto nástroje fungují na zařízeních s operačními systémy Android i iOS. Ověřte si jen, že máte aktualizovaný systém a prohlížeč.
        </p>
        <p class="support__text">
          Práce s ostatními nástroji je zatím na dotykových zařízeních problematická. Seřazování pramenů na těchto zařízeních nefunguje vůbec.
        </p>
        <p class="support__text">
          Vývoj aplikace HistoryLab stále probíhá. V budoucnu budeme dotykovým zařízením přizpůsobovat další nástroje používané ve cvičeních. S tím budou cvičení vhodná pro tablety a chytré telefony postupně přibývat.
        </p>
      </article>
      <SupportOnlyVideo 
        id="jak-se-zaregistrovat-prihlasit-do-aplikace"
        title="Jak se zaregistrovat/přihlásit do aplikace?"
        path="https://www.youtube.com/embed/iikRR6Xdm9Q"
      />
      <article className="support__item" id="zapomnel-jsem-heslo">
        <h2 className="support__headline">Zapomněl/a jsem heslo.</h2>
        <p className="support__text">
          Ve{" "}
          <Link to="/reset-hesla">formuláři pro resetování hesla</Link>{" "}
          zadejte e-mailovou adresu, se kterou jste se do HistoryLabu
          registroval/a. Do e-mailové schránky Vám přijde zpráva s odkazem
          na obnovení hesla.
        </p>
      </article>
      <SupportOnlyVideo 
        id="jak-v-historylabu-zalozim-tridu-a-pridam-do-ni-zaky"
        title="Jak v HistoryLabu založím třídu a přidám do ní žáky?"
        path="https://www.youtube.com/embed/K6PsdsApOV4"
      />
      <SupportOnlyVideo 
        id="jak-zadam-zakum-cviceni"
        title="Jak zadám žákům cvičení?"
        path="https://www.youtube.com/embed/LQm4oxbqTBQ"
      />
      <SupportOnlyVideo 
        id="jak-zkontroluji-odevzdana-cviceni"
        title="Jak zkontroluji odevzdaná cvičení?"
        path="https://www.youtube.com/embed/ulP8jRSvjso"
      />
    </SupportTemplate>
  );
}

export default withTour(MethodicalSupportPage);
