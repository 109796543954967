import React from "react";

const SupportOnlyVideo = ({
  id,
  title,
  path
}) => {

  return (
    <article
      className="support__item"
      id={id}
    >
      <h2 className="support__headline">
        {title}
      </h2>
      <div className="support__media support__media--video">
        <iframe
          src={path}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </article>
  );
};

export default SupportOnlyVideo;
