import React from "react";
import { NotificationConsumer } from "./NotificationProvider";

function withNotification(Component) {
  return function Inner(props) {
    return (
      <NotificationConsumer>
        {notifications => <Component {...props} {...notifications} />}
      </NotificationConsumer>
    );
  };
}

export default withNotification;
