import React from "react";
import withAuthentication from "./../withAuthentication";
import withNotification from "./../withNotification";
import Layout from "./../Layout";
import { Button, FormGroup, Col } from "reactstrap";
import { Field, Form, Formik } from "formik";
import { ReactstrapInput } from "reactstrap-formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataService from "../DataService";

class Administration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      error: null,
      katalog: [],
    };
  }

  processNotifications = data => {
    if (data) {
      (data.notifications || []).forEach(notification => {
        this.props.notify(notification);
      });
    }
  };

  onError = error => {
    console.error(error);
    this.setState({
      error,
      isLoading: false,
    });
    this.props.notify(error);
  };

  impersonate = emailAddress => {
    this.setState({ isLoading: true });
    new DataService()
      .impersonate(emailAddress)
      .then(this.props.processNotifications)
      .then(result => {
        this.setState({
          error: null,
          isLoading: false,
        });
      })
      .catch(this.onError);
  };

  render() {
    const { isLoading } = this.state;
    return (
      <Layout
        title="Administrace"
        isTitleCentered={true}
        isFullWidth={true}
        className="Catalog"
      >
        <Layout.Content>
          <Formik
            initialValues={{ emailAddress: "" }}
            onSubmit={(values, { setSubmitting }) => {
              this.impersonate(values.emailAddress);
            }}
          >
            {props => {
              return (
                <Form>
                  <FormGroup row>
                    <Col>
                      <Field
                        label="Přihlásit se jako uživatel s danou e-mailovou adresou"
                        name="emailAddress"
                        id="emailAddress"
                        component={ReactstrapInput}
                      />
                    </Col>
                  </FormGroup>
                  <Button
                    color="warning"
                    size="sm"
                    type="submit"
                    disabled={isLoading}
                  >
                    <FontAwesomeIcon icon="people-arrows" /> Provést
                  </Button>
                </Form>
              );
            }}
          </Formik>
        </Layout.Content>
      </Layout>
    );
  }
}
export default withNotification(withAuthentication(Administration));
